import Consulta from "views/TableList/ConsultaAcabado.jsx";
import {
  DirectNewAcabadosRecord,
  EditAcabadosRecord,
  UpdateAcabadosRecord,
  UpdateLiqAcabadosRecord,
} from "views/UserPage/acabados";

var dashRoutes = [
  {
    path: "/Acabado/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  {
    path: "/Acabado/RegistroDirecto",
    name: "Registro Directo",
    component: DirectNewAcabadosRecord,
    invisible: true,
  },
  {
    path: "/Acabado/RegistroLiquidacion",
    name: "Registro Liquidacion",
    component: UpdateLiqAcabadosRecord,
    invisible: true,
  },
  {
    path: "/Acabado/RegistroActDirecto",
    name: "Registro Directo",
    component: UpdateAcabadosRecord,
    invisible: true,
  },
  {
    path: "/Acabado/EditAcabado",
    name: "Edit Acabado",
    component: EditAcabadosRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Acabado/Procesos",
    name: "Consulta",
  },
];

export default dashRoutes;
