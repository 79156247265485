import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import {
  Upload,
  Icon,
  notification,
  message,
  Button,
  Select,
  Input as In,
} from "antd";
import "antd/dist/antd.css";
import firebase from "../../../Firebase.js";
const { Option } = Select;

class DirectNewPcpRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("ing");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      op: "",
      isOpen: false,
      color: [""],
      cantidad: [""],
      img: null,
      prof: [],
      selectedOption: null,
      ing: [],
      imgFT: null,
      prueba: null,
      value: null,
      descripcion: null,
      colores: [],
      lastIndexTelas: null,
      lastIndexDdp: null,
      comercialValue: "",
      isActive: false,
      selectedFile: null,
      selectedFileList: [],
      loading: false,
      pdfUrl: "",
      filelist: null,
      ordenProd: [],
    };
    this.changeImage = this.changeImage.bind(this);
  }

  componentDidMount() {
    const { prof } = this.props.location.state;
    console.log(prof);
    this.setState({ prueba: prof });

    this.ref
      .orderBy("order", "asc")
      .get()
      .then((querySnap) => {
        const op = [];
        querySnap.forEach((doc) => {
          op.push(doc.data().op);
        });
        this.setState({ ordenProd: op });
      });

    firebase
      .firestore()
      .collection("pcpclientes")
      .get()
      .then((querySnapshot) => {
        var clientes = [];
        querySnapshot.forEach(function(doc) {
          clientes.push({
            label: doc.data().name,
            value: doc.id,
          });
        });
        this.setState({ colores: clientes });
      });

    this.ref
      .where("prof", "==", prof)
      .get()
      .then((querySnapshot) => {
        let value = null;
        querySnapshot.forEach(function(doc) {
          value = doc.data().index;
        });
        this.setState({ value });
      });

    firebase
      .firestore()
      .collection("telas")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let lastIndexTelas = null;
        querySnapshot.forEach((doc) => {
          lastIndexTelas = doc.data().index;
        });
        this.setState({ lastIndexTelas });
      });

    firebase
      .firestore()
      .collection("ddp")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let lastIndexDdp = null;
        querySnapshot.forEach((doc) => {
          lastIndexDdp = doc.data().index;
        });
        this.setState({ lastIndexDdp });
      });

    firebase
      .firestore()
      .collection("comercial")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let comercialValue = null;
        querySnapshot.forEach((doc) => {
          comercialValue = doc.data().index;
        });
        this.setState({ comercialValue });
      });
  }

  addColor() {
    this.setState({ color: [...this.state.color, ""] });
  }

  handleChangeColor(e, index) {
    const color = this.state.color;
    color[index] = e;
    this.setState({ color });
  }

  addCantidad() {
    this.setState({ cantidad: [...this.state.cantidad, ""] });
  }

  delete() {
    let color = this.state.color;
    let cantidad = this.state.cantidad;
    if (color.length > 1) {
      color.pop();
    }
    if (cantidad.length > 1) {
      cantidad.pop();
    }

    this.setState({ color: color, cantidad: cantidad });
  }

  handleChangeCantidad(e, index) {
    const cantidad = this.state.cantidad;
    cantidad[index] = e.target.value;
    this.setState({ cantidad });
  }

  add() {
    this.addCantidad();
    this.addColor();
  }

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChangePDF = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelist: info.fileList, fileList });
    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    this.setState(() => ({ file, op: file.name.slice(0, -4) }));

    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`ordenPedido/${file.name}`);
    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("ordenPedido")
        .child(file.name)
        .getDownloadURL();
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  changeImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      this.setState(() => ({ img, op: img.name.split(".")[0] }));
    }
  };

  selectHandleChange2 = (selectedOption2) => {
    this.setState({ selectedOption2 });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaIngreso,
      op,
      cantidad,
      descripcion,
      color,
      value,
      lastIndexTelas,
      lastIndexDdp,
      comercialValue,
      ordenProd,
    } = this.state;

    this.setState({
      isActive: false,
    });

    if (ordenProd.includes(op)) {
      const msg = {
        message: `La Orden de Produccion "${op}" Ya existe.`,
        top: 80,
      };
      notification.error(msg);
    } else {
      fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");
      let opurl = this.state.filelist[0].xhr.url;

      message.loading("Registrando..", 60);

      try {
        this.ref
          .doc(String(value))
          .get()
          .then((res) => {
            const ing = res.data();
            ing.op = op;
            ing.descripcion = descripcion;
            ing.cx = [opurl];
            let otros = color.reduce((ac, value, i) => {
              ac.push({
                color: value,
                cantidad: cantidad[i],
                fecha: fechaIngreso,
                status: ["PCP"],
              });
              return ac;
            }, []);
            ing.otros = otros;
            ing.estado[0] = "Listo";
            ing.estadoProf = "Pendiente";
            ing.edit = [ing.index, ing.prof, fechaIngreso, op, descripcion];
            ing.color = color;
            ing.cantidad = cantidad;
            ing.isEditable = true;

            this.ref
              .doc(String(value))
              .update(ing)
              .then(function(error) {
                if (error) {
                  console.log("ING no se upgradeo");
                } else {
                  console.log("ING se upgradeo");
                }
              });

            let telasIndex = Number(lastIndexTelas);
            color.forEach((v, i) => {
              let value = telasIndex + (i + 1);
              let telas = {};

              telas.op = op;
              telas.cx = [opurl];
              telas.descripcion = descripcion;
              telas.fechaPcp = fechaIngreso;
              telas.color = v;
              telas.cantidad = Number(cantidad[i]);
              telas.fechaDespacho = ing.cFechaEntrega;
              telas.fechaIngreso = null;
              telas.fechaSalida = null;
              telas.hc = [];
              telas.b = [];
              telas.edit = [String(value), op, v, "Nuevo"];
              telas.index = String(value);
              telas.order = Number(value);
              telas.estado = ["Nuevo", op, v];
              telas.isEditable = true;

              firebase
                .firestore()
                .collection("telas")
                .doc(String(value))
                .set(telas)
                .then(function(error) {
                  if (error) {
                    console.log("TELAS no se agrego");
                  } else {
                    console.log("TELAS se agrego");
                  }
                });
            });

            firebase
              .firestore()
              .collection("comercial")
              .where("prof", "==", res.data().prof)
              .limit(1)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((res) => {
                  const comercialId = res.id;
                  const hasOp = res.data().op;
                  let comercial = res.data();
                  comercial.op = op;
                  comercial.isEditable = false;
                  comercial.b = [opurl];
                  comercial.status = "Pendiente";
                  comercial.otros = color.reduce((acc, v, i) => {
                    acc.push({
                      cant: cantidad[i],
                      color: v,
                      descripcion: descripcion,
                      estado: "PCP",
                    });
                    return acc;
                  }, []);

                  if (hasOp) {
                    firebase
                      .firestore()
                      .collection("comercial")
                      .doc(String(Number(comercialValue) + 1))
                      .set(comercial, { merge: true });
                  } else {
                    firebase
                      .firestore()
                      .collection("comercial")
                      .doc(comercialId)
                      .set(comercial, { merge: true });
                  }
                });
              });

            let ddpIndex = Number(lastIndexDdp) + 1;
            let ddp = {};
            ddp.op = op;
            ddp.a = [opurl];
            ddp.b = [];
            ddp.cFechaIngreso = fechaIngreso;
            ddp.cliente = ing.cliente;
            ddp.cx = [opurl];
            ddp.descripcion = descripcion;
            ddp.edit = [ddpIndex, ing.prof, fechaIngreso, op, descripcion];
            ddp.color = color;
            ddp.cantidad = cantidad;
            ddp.cFechaIngresoDdp = null;
            ddp.fichaTecnica = null;
            ddp.hojaConsumo = null;
            ddp.hc = [];
            ddp.order = Number(ddpIndex);
            ddp.index = String(ddpIndex);
            ddp.estado = ["Nuevo", op];
            ddp.isEditable = true;

            firebase
              .firestore()
              .collection("ddp")
              .doc(String(ddpIndex))
              .set(ddp)
              .then(function(error) {
                if (error) {
                  console.log("DDP no se creo");
                } else {
                  console.log("DDP si se creo");
                }
              })
              .then(() => {
                message.destroy();
                const args = {
                  message: "Registro agregado existosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  fechaIngreso: new Date(),
                  op: "",
                  descripcion: "",
                  color: [""],
                  cantidad: [""],
                  selectedOption: "",
                });
                this.props.history.push("/Ingenieria/Procesos");
              });
          });
      } catch (error) {
        alert("Ingrese Orden de Pedido: ", error);
      }
    }
    this.setState({
      isActive: false,
    });
  };

  render() {
    const {
      op,
      prueba,
      descripcion,
      color,
      cantidad,
      loading,
      pdfUrl,
    } = this.state;
    const isInvalid =
      op &&
      descripcion &&
      color[0] !== "" &&
      cantidad[0] !== "" &&
      this.state.filelist !== null &&
      this.state.filelist &&
      this.state.fileList.every((item) => item.status === "done");

    const uploadButton = (
      <div>
        <Button
          className="mt-2"
          style={{ display: "block", textAlign: "left" }}
        >
          <Icon type={loading ? "loading" : "plus"} />
          Seleccionar Archivo
        </Button>
      </div>
    );

    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ textAlign: "center" }}>
                  NUEVO REGISTRO
                </CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <Label className="font-weight-bold">FACTURA PROFORMA</Label>
                    <In
                      className="col-md-3 d-block"
                      name="prof"
                      value={prueba}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="font-weight-bold">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <In
                      name="op"
                      className="col-md-3 d-block"
                      value={op}
                      onChange={this.onChange}
                      autoComplete="off"
                      disabled={true}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">DESCRIPCIÓN</Label>
                    <In
                      name="descripcion"
                      className="col-md-3 d-block"
                      value={descripcion}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Row>
                    <Col md={6} xs={6}>
                      {this.state.color.map((color, index) => {
                        return (
                          <FormGroup key={index}>
                            <Label className="font-weight-bold">COLOR</Label>
                            <Select
                              showSearch={true}
                              className="col-md-6 d-block"
                              value={color}
                              onChange={(e) => this.handleChangeColor(e, index)}
                              placeholder="Eliga Color"
                              required
                            >
                              {this.state.colores.map((item, index) => (
                                <Option key={index} value={item.label}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </FormGroup>
                        );
                      })}
                      <Button
                        type="primary"
                        size="small"
                        onClick={(e) => this.add(e)}
                      >
                        Agregar
                      </Button>
                      <Button
                        type="danger"
                        size="small"
                        onClick={(e) => this.delete(e)}
                        className="ml-3"
                      >
                        Borrar
                      </Button>
                    </Col>
                    <Col md={6} xs={6}>
                      {this.state.cantidad.map((cantidad, index) => {
                        return (
                          <FormGroup key={index}>
                            <Label className="font-weight-bold">CANTIDAD</Label>
                            <In
                              name="cantidad"
                              className="col-md-6 d-block"
                              value={cantidad}
                              autoComplete="off"
                              onChange={(e) =>
                                this.handleChangeCantidad(e, index)
                              }
                              required
                            />
                          </FormGroup>
                        );
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Label className="font-weight-bold mt-4 ml-3">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() => this.setState({ filelist: null, op: "" })}
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangePDF}
                    customRequest={this.customUpload}
                    fileList={this.state.fileList}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        type="primary"
                        style={{ marginnTop: "20px" }}
                        disabled={
                          this.state.isActive || !isInvalid ? true : false
                        }
                        onClick={this.onSubmit}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DirectNewPcpRecord;
