import Dashboard from "views/Dashboard/Dashboard.jsx";
import ConsultaIngenieria from "views/TableList/ConsultaIngenieria.jsx";
import ConsultaCostura from "views/TableList/ConsultaCostura.jsx";
import ConsultaCorte from "views/TableList/ConsultaCorte.jsx";
import NewPcpRecord from "views/UserPage/pcp/NewPcpRecord";
import EditPcpRecord from "views/UserPage/pcp/EditPcpRecord";
import DirectNewPcpRecord from "views/UserPage/pcp/DirectNewPcpRecord";
import AddColor from "views/UserPage/pcp/AddColor";
import NewCorteRecord from "views/UserPage/pcp/NewCorteRecord";
import EditCorteRecord from "views/UserPage/pcp/EditCorteRecord";
import NewCosturaRecord from "views/UserPage/pcp/NewCosturaRecord";
import EditCosturaRecord from "views/UserPage/pcp/EditCosturaRecord";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Ingenieria/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: ConsultaIngenieria,
  },
  {
    path: "/Ingenieria/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: NewPcpRecord,
  },
  {
    path: "/Corte/RegistroCorte",
    component: NewCorteRecord,
    invisible: true,
  },
  {
    path: "/Corte/RegistroCorteAct",
    component: EditCorteRecord,
    invisible: true,
  },
  {
    path: "/Costura/RegistroCostura",
    component: NewCosturaRecord,
    invisible: true,
  },
  {
    path: "/Costura/RegistroCosturaAct",
    component: EditCosturaRecord,
    invisible: true,
  },
  {
    path: "/Ingenieria/AgregarColor",
    name: "Colores",
    icon: "nc-icon nc-tile-56",
    component: AddColor,
  },
  {
    path: "/Ingenieria/ConsultaCorte",
    name: "Corte",
    icon: "nc-icon nc-briefcase-24",
    component: ConsultaCorte,
  },
  {
    path: "/Ingenieria/ConsultaCostura",
    name: "Costura",
    icon: "nc-icon nc-briefcase-24",
    component: ConsultaCostura,
  },
  {
    path: "/Ingenieria/RegistroDirecto",
    name: "RegistroDirecto",
    icon: "nc-icon nc-zoom-split",
    component: DirectNewPcpRecord,
    invisible: true,
  },
  {
    path: "/Ingenieria/EditIngenieria",
    name: "Edit",
    component: EditPcpRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Ingenieria/Procesos",
    name: "Registro",
  },
];
export default dashRoutes;
