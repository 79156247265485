import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, message, Button, Input } from "antd";
import "antd/dist/antd.css";

class DirectNewAcabadosRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("acabados");
    this.state = {
      fechaIngreso: new Date(),
      isOpen: false,
      cantidadIngresada: "",
      value: null,
      op: null,
      color: null,
      comercialId: null,
      comercialIndex: null,
      isActive: false,
      cantidadCostura: null,
    };
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const { colorState } = this.props.location.state;
    const { cantidadCostura } = this.props.location.state;
    this.setState({ cantidadCostura: cantidadCostura });

    this.ref
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            value: doc.data().order,
            color: colorState,
            op: ordenPedido,
          });
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleChangeIngreso = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();
    let {
      fechaIngreso,
      cantidadIngresada,
      value,
      comercialId,
      comercialIndex,
      op,
      color,
      cantidadCostura,
    } = this.state;
    if (cantidadIngresada <= cantidadCostura) {
      this.setState({
        isActive: false,
      });
      message.loading("Registrando...", 60);
      fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

      this.ref
        .doc(String(value))
        .get()
        .then((res) => {
          const acabado = res.data();

          acabado.otros[0] = {
            ...acabado.otros[0],
            fechaEntrada: fechaIngreso,
            cantEntrada: Number(cantidadIngresada),
          };
          acabado.isEditable = true;
          acabado.estado[0] = "En Curso";

          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(comercialId)
              .get()
              .then((doc) => {
                let otros = doc.data().otros;
                otros[comercialIndex] = {
                  ...otros[comercialIndex],
                  acabado: {
                    fechaIngreso,
                    cantidadIngresada,
                  },
                };

                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .set({ otros }, { merge: true });
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            firebase
              .firestore()
              .collection("ing")
              .where("op", "==", acabado.op)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let otros = doc.data().otros;
                  otros.forEach((item) => {
                    if (item.color === acabado.color) {
                      item.status = ["Acabado"];
                    }
                  });
                  firebase
                    .firestore()
                    .collection("ing")
                    .doc(doc.id)
                    .set({ otros }, { merge: true });
                });
              });
          } catch (error) {
            console.error(error);
          }

          try {
            firebase
              .firestore()
              .collection("costura")
              .where("op", "==", op)
              .where("color", "==", color)
              .get()
              .then((qs) => {
                qs.forEach((doc) => {
                  firebase
                    .firestore()
                    .collection("costura")
                    .doc(doc.id)
                    .update({
                      isEditable: false,
                    });
                });
              });
          } catch (e) {
            console.error(e.message);
          }

          try {
            this.ref
              .doc(String(value))
              .update(acabado)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registro agregado exitosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  fechaIngreso: new Date(),
                  cantidadIngresada: "",
                });
                this.props.history.push("/Acabado/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La cantidad: ${cantidadIngresada}, no debe ser mayor a la cantidad principal: ${cantidadCostura}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const { cantidadIngresada } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">Nuevo Registro</CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label className="font-weight-bold mt-3">
                      ORDEN DE PRODUCCION
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">
                      CANTIDAD INGRESADA
                    </Label>
                    <Input
                      name="cantidadIngresada"
                      className="col-md-3 d-block"
                      value={cantidadIngresada}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto mt-3">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive || !cantidadIngresada
                            ? true
                            : false
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DirectNewAcabadosRecord;
