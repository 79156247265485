import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from "../../Firebase";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class RegularTables extends React.Component {
  state = {
    datos: [],
    page: null,
    prof: [],
    ft: [],
    op: [],
    loading: true,
    hc: [],
    modalIsOpen: false,
    password: "",
    doc: "",
    ordenPedido: "",
    fechaIngreso: "",
    fichaTecnica: "",
    hojaConsumo: "",
    proforma: "",
    telaData: [],
  };

  async loadData() {
    await firebase
      .firestore()
      .collection("ddp")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        const datos = [];
        querySnapshot.forEach((doc) => {
          const isEditable = doc.data().isEditable;

          datos.push({
            cliente: doc.data().cliente,
            cFechaIngreso: doc.data().cFechaIngreso,
            cFechaIngresoDdp: doc.data().fechaIngresoDdp,
            hojaConsumo: doc.data().hojaConsumo,
            hc:
              (doc.data().hc !== undefined) & (doc.data().hc !== [])
                ? doc.data().hc[0]
                : [],
            op: doc.data().op,
            cx: doc.data().cx,
            estado: [[doc.data().estado[0], doc.data().op, doc.id]],
            edit: [doc.data().edit],
            descripcion: doc.data().descripcion,
            fichaTecnica: doc.data().fichaTecnica,
            b:
              (doc.data().b !== undefined) & (doc.data().b !== [])
                ? doc.data().b[0]
                : [],
            index: [
              doc.data().estado[0],
              doc.data().op,
              !isEditable,
              doc.data().index,
            ].concat(doc.data().index),
          });
        });
        this.setState({ datos, loading: false });
      });
  }

  componentWillMount() {
    this.loadData();
  }

  borrar = (value) => {
    const op = value[1];
    const id = value[3];

    const estado = value
      .slice(0, 3)
      .map((item, idx) => (idx === 0 ? (item = "Nuevo") : item));

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("comercial")
            .doc(doc.id)
            .update({
              f: [],
              fichaTecnica: "",
            });
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("ing")
            .doc(doc.id)
            .update({
              fichaTecnica: "",
              b: [],
              hojaConsumo: "",
              hc: [],
            });
        });
      });

    firebase
      .firestore()
      .collection("ddp")
      .doc(id)
      .update({
        fichaTecnica: "",
        b: [],
        hojaConsumo: "",
        hc: [],
        estado,
      });

    firebase
      .firestore()
      .collection("telas")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("telas")
            .doc(doc.id)
            .update({
              ft: "",
              b: [],
              hojaConsumo: "",
              hc: [],
            });
        });
      });

    firebase
      .firestore()
      .collection("corte")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("corte")
            .doc(doc.id)
            .update({
              ft: "",
              b: [],
              hojaConsumo: "",
              hc: [],
            });
        });
      });

    firebase
      .firestore()
      .collection("costura")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("costura")
            .doc(doc.id)
            .update({
              ft: "",
              b: [],
            });
        });
      });

    firebase
      .firestore()
      .collection("acabados")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("acabados")
            .doc(doc.id)
            .update({
              ft: "",
              b: [],
            });
        });
      });

    firebase
      .firestore()
      .collection("almacen")
      .where("op", "==", op)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("almacen")
            .doc(doc.id)
            .update({
              ft: "",
              b: [],
            });
        });
      });

    const dataUpdated = this.state.datos.map((item) => {
      if (item.op === op) {
        item.estado = [estado];
        item.index = estado.concat(id);
        item.fichaTecnica = "";
        item.b = "";
        item.hojaConsumo = "";
        item.hc = "";
      }
      return item;
    });

    this.setState({ data: dataUpdated });
  };

  openModal(value) {
    this.setState({
      modalIsOpen: true,
      doc: value[0][0],
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: false });
  }

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Ddp/EditDdp",
          state: {
            doc: this.state.doc,
            fichaTecnica: this.state.fichaTecnica,
            hojaConsumo: this.state.hojaConsumo,
            fechaIngreso: this.state.fechaIngreso,
            ordenPedido: this.state.ordenPedido,
            proforma: this.state.proforma,
          },
        })
      : alert("Wrong password, try again");
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  render() {
    const { datos, loading } = this.state;

    const ingPrincipal = [
      {
        headerStyle: { backgroundColor: "#7D3C98", color: "white" },
        columns: [
          {
            Header: "F. Orden de Produccion",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "cFechaIngreso",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cFechaIngreso",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Fecha DDP",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "cFechaIngresoDdp",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cFechaIngresoDdp",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Orden Producción",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 145,
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "cx",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Cliente",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "cliente",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "cliente" },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <span style={{ fontSize: "12px " }}>{row.value}</span>
            ),
          },
          {
            Header: "Ficha Técnica",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "fichaTecnica",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "fichaTecnica",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "b",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Consumo",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "hojaConsumo",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "hojaConsumo",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "hc",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
        ],
      },
      {
        headerStyle: { backgroundColor: "#7D3C98  ", color: "white" },
        columns: [
          {
            Header: "Descripción",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "descripcion",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "descripcion",
                  },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <span style={{ fontSize: "12px " }}>{row.value}</span>
            ),
          },
          {
            Header: "",
            accessor: "estado",
            width: 80,
            headerStyle: { fontWeight: "bold" },
            style: { textAlign: "left", fontSize: "12px" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "estado" },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.value[0][0].toLowerCase() === "nuevo" ? (
                  <Link
                    to={{
                      pathname: "/Ddp/RegistroDirecto",
                      state: {
                        doc: row.value[0][2],
                        op: row.value[0][1],
                        update: false,
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#03A9F4",
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : row.value[0][0].toLowerCase() === "en curso" ? (
                  <Link
                    to={{
                      pathname: "/Ddp/RegistroDirecto",
                      state: {
                        doc: row.value[0][2],
                        op: row.value[0][1],
                        update: true,
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FF7e00",
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : (
                  <span
                    style={{
                      backgroundColor: "#008030",
                      width: "40%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    {row.value[0][0]}
                  </span>
                )}
              </div>
            ),
          },
          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold" },
            width: 100,
            accessor: "index",
            Cell: (row) => (
              <div className="text-center">
                {row.value ? (
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "80%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={
                      row.value[0] === "Nuevo" || row.value[2] ? true : false
                    }
                    onClick={(e) =>
                      window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                      this.borrar(row.value)
                    }
                    type="button"
                  >
                    Borrar
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ),
          },
        ],
      },
    ];
    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos DDP
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={datos}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={ingPrincipal}
                  noDataText="Sin Registros"
                  loading={loading}
                  loadingText="POR FAVOR, ESPERE UN MOMENTO"
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  rowsText="filas"
                  minRows={0}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{ border: "1px solid black" }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              autoFocus
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={(e) => this.closeModal(e)}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
