import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Alert,
  Input,
  FormGroup,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "Firebase.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class NewCorteRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("pcp");

    this.state = {
      fechaIngreso: new Date(),
      cantidad: "",
      isOpen: false,
      value: null,
      op: null,
      ft: null,
      estilo: null,
      color: null,
      cantpcp: null,
      label: null,
      servicio: "",
      numGuiaTex: "",
      descripcion: "",
    };
  }

  componentDidMount() {

    const { opState } = this.props.location.state;
    const { colorState } = this.props.location.state;

    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let label,
          value,
          descripcion,
          color,
          op,
          cantpcp,
          ft = null;
        querySnapshot.forEach(function(doc) {
          let telas = [];
          if (doc.data().telas) {
            for (let t = 0; t < doc.data().telas.length; t++) {
              if (doc.data().telas[t].seg === "corte")
                telas.push({
                  cantidad: doc.data().telas[t].cantidad,
                  color: doc.data().telas[t].color,
                  descripcion: doc.data().telas[t].descripcion,
                  op: doc.data().telas[t].op,
                  value: doc.id,
                  ft: doc.data().telas[t].ft,
                });
            }
          }

          for (let i = 0; i < telas.length; i++) {
            if (opState === telas[i].op && colorState === telas[i].color) {
              label = telas[i].op + " " + telas[i].color;
              value = doc.id;
              color = telas[i].color;
              op = telas[i].op;
              cantpcp = telas[i].cantidad;
              ft = telas[i].ft;
              descripcion = telas[i].descripcion;
            }
          }
        });
        this.setState({ label, value, descripcion, color, op, cantpcp, ft });
      });
  }

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaIngreso,
      cantidad,
      servicio,
      numGuiaTex,
      op,
      color,
      value,
      descripcion,
      cantpcp,
      ft,
    } = this.state;
    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

    this.ref
      .doc(value)
      .update({
        corte: firebase.firestore.FieldValue.arrayUnion({
          fechaIngreso,
          servicio,
          numGuiaTex,
          cantidad: Number(cantidad),
          op: op,
          color: color,
          descripcion,
          total: Number(cantidad),
          seg: "corte-update",
          cantpcp: Number(cantpcp),
          ft: ft,
        }),
      })
      .then((docRef) => {
        this.setState({
          fechaIngreso: new Date(),
          label: "",
          numGuiaTex: "",
          servicio: "",
          cantidad: "",
        });
        this.props.history.push("/Ingenieria/Procesos");
        this.handleClickShowAlert();
      })

      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  render() {
    const { cantidad, numGuiaTex, servicio } = this.state;
    const isInvalid = cantidad === "" || numGuiaTex === "" || servicio === "";

    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>
                  REGISTRO CORTE
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <Label
                      for="fechaIngreso"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      FECHA:
                    </Label>
                    <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                      <DatePicker
                        selected={this.state.fechaIngreso}
                        onChange={this.handleChange}
                        dateFormat="d MMMM, yyyy"
                        locale="es"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="fechaIngreso"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.op}
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.color}
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="servicio">SERVICIO</Label>
                    <Input
                      type="text"
                      name="servicio"
                      id="servicio"
                      className="col-md-3 pr-1"
                      value={servicio}
                      onChange={this.onChange}
                      required
                      autoComplete="off"
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="numGuiaTex">N° GUIA TEXCROSS</Label>
                    <Input
                      type="text"
                      name="numGuiaTex"
                      id="numGuiaTex"
                      className="col-md-3 pr-1"
                      value={numGuiaTex}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="cantidad"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      CANTIDAD
                    </Label>
                    <Input
                      type="text"
                      name="cantidad"
                      id="cantidad"
                      className="col-md-4 pr-1"
                      style={{ marginLeft: "15px" }}
                      value={cantidad}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        type="submit"
                        disabled={isInvalid}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewCorteRecord;
