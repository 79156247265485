import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from "../../Firebase";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class RegularTables extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("almacen");
    this.state = {
      datos: [],
      ft: [],
      op: [],
      loading: true,
      modalIsOpen: false,
      password: "",
      ordenPedido: "",
      color: "",
      doc: "",
    };
  }
  componentDidMount() {
    this.ref
      .orderBy("order", "desc")
      .get()
      .then((querySnapShot) => {
        const data = querySnapShot.docs.reduce((acc, data) => {
          const item = data.data();
          const itemIndex = acc.findIndex((accItem) => accItem.op === item.op);
          const { almacenFecha, saldo, cantAlmacen, caja, url } =
            item.otros && item.otros.length
              ? item.otros[0]
              : {
                  almacenFecha: "",
                  saldo: "",
                  cantAlmacen: "",
                  caja: "",
                  url: "",
                };
          const almacenFechaOtros = almacenFecha;
          const saldoOtros = saldo;
          const cantAlmacenOtros = cantAlmacen;
          const cajaOtros = caja;
          const urlOtros = url;
          let newItem = [{}];
          if (itemIndex >= 0) {
            newItem = {
              ...acc[itemIndex],
              otros: acc[itemIndex].otros.concat({
                color: item.color,
                estado: [item.estado],
                edit: [
                  item.index,
                  item.op,
                  item.color,
                  item.estado && item.estado.length ? item.estado[0] : "",
                  item.isEditable,
                ],
                index: item.estado.concat(item.index, item.isEditable),
                almacenFecha: almacenFechaOtros,
                saldo: saldoOtros,
                cantAlmacen: cantAlmacenOtros,
                caja: cajaOtros,
                url: urlOtros,
              }),
            };
            acc.splice(itemIndex, 1, newItem);
          } else {
            newItem = {
              acabadoFecha: item.acabadoFecha,
              op: item.op,
              cx: item.cx,
              ft: item.ft,
              b: item.b,
              cliente: item.cliente,
              descripcion: item.descripcion,
              otros: [
                {
                  color: item.color,
                  estado: [item.estado],
                  edit: [
                    item.index,
                    item.op,
                    item.color,
                    item.estado && item.estado.length ? item.estado[0] : "",
                    item.isEditable,
                  ],
                  index: item.estado.concat(item.index, item.isEditable),
                  almacenFecha: almacenFechaOtros,
                  saldo: saldoOtros,
                  cantAlmacen: cantAlmacenOtros,
                  caja: cajaOtros,
                  url: urlOtros,
                },
              ],
            };
            acc.push(newItem);
          }
          return acc;
        }, []);

        this.setState({ data, loading: false });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  }

  openModal(value) {
    this.setState({
      modalIsOpen: true,
      doc: value[0],
      ordenPedido: value[1],
      color: value[2],
      estado: value[3],
      isEditable: value[4],
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: false });
  }

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Almacen/EditAlmacen",
          state: {
            doc: this.state.doc,
            ordenPedido: this.state.ordenPedido,
            color: this.state.color,
            estado: this.state.estado,
            isEditable: this.state.isEditable,
          },
        })
      : alert("Wrong password, try again");
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  borrar = (value) => {
    const op = value[1];
    const col = value[2];
    const isEditable = value[4];
    const estado = value
      .slice(0, 4)
      .map((item, idx) => (idx === 0 ? (item = "Nuevo") : item));

    firebase
      .firestore()
      .collection("almacen")
      .doc(value[3])
      .update({
        isEditable: true,
        estado,
        otros: [],
      });

    firebase
      .firestore()
      .collection("acabados")
      .where("op", "==", op)
      .where("color", "==", col)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("acabados")
            .doc(doc.id)
            .update({
              isEditable: true,
            });
        });
      });
    const dataUpdated = this.state.data.map((item) => {
      if (item.op === op) {
        const otros = item.otros;
        const index = otros.findIndex((item) => item.color === col);
        otros.splice(index, 1, {
          almacenFecha: "",
          saldo: "",
          cantAlmacen: "",
          caja: "",
          url: "",
          estado: [estado],
          index: estado.concat(value[3], isEditable),
          otros: [],
          edit: [value[3], op, col, estado, true],
        });
      }
      return item;
    });

    this.setState({ data: dataUpdated });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", op)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach((doc) => {
          firebase
            .firestore()
            .collection("comercial")
            .doc(doc.id)
            .update({
              otros: doc.data().otros.map((v) => {
                if (v.color === col) {
                  delete v.almacen;
                  return v;
                }
                return v;
              }),
            });
        });
      });
  };

  render() {
    const { data, loading } = this.state;

    const mainAlmacen = [
      {
        headerStyle: { backgroundColor: "#008030", color: "white" },
        columns: [
          {
            Header: "Fecha",
            headerStyle: { fontWeight: "bold" },
            accessor: "acabadoFecha",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "acabadoFecha",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Orden de Pedido",
            headerStyle: { fontWeight: "bold" },
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "cx",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Ficha Tecnica",
            headerStyle: { fontWeight: "bold" },
            accessor: "ft",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "ft" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "b",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Cliente",
            headerStyle: { fontWeight: "bold" },
            accessor: "cliente",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "cliente" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Descripcion",
            headerStyle: { fontWeight: "bold" },
            accessor: "descripcion",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "descripcion",
                  },
                ],
              }),
            filterAll: true,
          },
        ],
      },
    ];

    const subAlmacen = [
      {
        Header: "Almacen",
        headerStyle: {
          backgroundColor: "#008030",
          color: "white",
          fontWeight: "bold",
        },
        columns: [
          {
            Header: "Color",
            headerStyle: { fontWeight: "bold" },
            accessor: "color",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "color" },
                ],
              }),
            filterAll: true,
          },

          {
            Header: "Fecha",
            headerStyle: { fontWeight: "bold" },
            accessor: "almacenFecha",
          },

          {
            Header: "Saldo",
            headerStyle: { fontWeight: "bold" },
            accessor: "saldo",
          },
          {
            Header: "Recibido",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantAlmacen",
          },
          {
            Header: "Caja",
            headerStyle: { fontWeight: "bold" },
            accessor: "caja",
          },
          {
            Header: "",
            accessor: "url",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/textiles-cross.appspot.com/o/img%2Fcamera.png?alt=media&token=1e7a32d9-fa38-4d22-9031-b91a99b18a00"
                        style={{ width: "45px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : (
                  ""
                )}
              </div>
            ),
          },

          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold" },
            accessor: "estado",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.STARTS_WITH,
                    key: "estado",
                  },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.value[0][0] === "Nuevo" ? (
                  <Link
                    to={{
                      pathname: "/Almacen/RegistroDirecto",
                      state: {
                        ordenPedido: row.value[0][1],
                        colorState: row.value[0][2],
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#F16035",
                        width: "50%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : (
                  <span
                    style={{
                      backgroundColor: "green",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "6px",
                      color: "white",
                    }}
                  >
                    {row.value[0][0]}
                  </span>
                )}
              </div>
            ),
          },

          {
            Header: "Editar",
            accessor: "edit",
            width: 80,
            headerStyle: { fontWeight: "bold" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["edit"] }),
            filterAll: true,
            Cell: (row) => (
              <Button
                style={{
                  backgroundColor: "orange",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "10px",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0px",
                  padding: "5px",
                }}
                disabled={row.value[4]}
                onClick={() => this.openModal(row.value)}
              >
                Editar
              </Button>
            ),
          },

          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold" },
            width: 80,
            accessor: "index",
            Cell: (row) => (
              <div className="text-center">
                {
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={Boolean(row.value[4])}
                    onClick={(e) =>
                      window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                      this.borrar(row.value)
                    }
                    type="button"
                  >
                    Borrar
                  </Button>
                }
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos Almacen
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={mainAlmacen}
                  className="-highlight -striped"
                  noDataText="Sin Registros"
                  loading={loading}
                  loadingText="POR FAVOR, ESPERE UN MOMENTO"
                  defaultPageSize={10}
                  minRows={0}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  rowsText="filas"
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.otros}
                          columns={subAlmacen}
                          noDataText="Sin Registros"
                          pageSize={row.original.otros.length}
                          showPagination={false}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0",
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{ border: "1px solid black" }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              autoFocus
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={(e) => this.closeModal(e)}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
