import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, message, Button, DatePicker, Input } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "antd/dist/antd.css";

class UpdateCosturaRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("costura");

    this.state = {
      color: "",
      fechaSalida: new Date(),
      cantSalida: "",
      label: null,
      value: null,
      op: null,
      lastCosturaId: null,
      fechaDespacho: "",
      comercialId: null,
      comercialIndex: null,
      isActive: false,
      entrad1: null,
    };
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const { colorState } = this.props.location.state;
    const { entrada } = this.props.location.state;
    this.setState({ entrad1: entrada });

    this.ref
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            value: doc.data().index,
            color: colorState,
            op: ordenPedido,
          });
        });
      });

    firebase
      .firestore()
      .collection("acabados")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            lastCosturaId: doc.data().index,
          });
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().op === ordenPedido) {
            this.setState({
              fechaDespacho: doc.data().cFechaEntrega,
            });
          }
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaSalida,
      cantSalida,
      value,
      lastCosturaId,
      fechaDespacho,
      comercialId,
      comercialIndex,
      entrad1,
    } = this.state;

    const restanteResult = Number(entrad1);
    if (cantSalida <= restanteResult) {
      this.setState({
        isActive: false,
      });
      fechaSalida = moment(fechaSalida).format("DD/MM/YYYY");
      let id = Number(lastCosturaId) + 1;
      message.loading("Registrando...", 60);
      this.ref
        .doc(value)
        .get()
        .then((res) => {
          const costura = res.data();

          costura.otros[0] = {
            ...costura.otros[0],
            fechaSalida,
            cantSalida: Number(cantSalida),
          };

          if (Number(cantSalida) >= costura.cantCorte) {
            costura.entrega = "Completo";
          } else {
            costura.entrega = "Incompleto";
          }
          costura.estado[0] = "Listo";

          let acabados = {
            b: costura.b,
            cantCostura: Number(cantSalida),
            color: costura.color,
            costuraFechaIngreso: fechaSalida,
            cx: costura.cx,
            descripcion: costura.descripcion,
            despacho: "",
            edit: [String(id), costura.op, costura.color, "Nuevo"],
            estado: ["Nuevo", costura.op, costura.color],
            fechaDespacho: fechaDespacho,
            ft: costura.ft,
            index: String(id),
            op: costura.op,
            order: Number(id),
            otros: [],
            totalCorte: costura.cantCorte,
            isEditable: true,
          };

          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(comercialId)
              .get()
              .then((doc) => {
                let otros = doc.data().otros;
                otros[comercialIndex] = {
                  ...otros[comercialIndex],

                  costura: {
                    ...otros[comercialIndex].costura,
                    fechaSalida,
                    cantidadSalida: cantSalida,
                  },
                };

                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .set({ otros }, { merge: true });
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            firebase
              .firestore()
              .collection("acabados")
              .doc(String(id))
              .set(acabados);
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            this.ref
              .doc(value)
              .update(costura)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registro agregado exitosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  fechaSalida: new Date(),
                  cantidadSalida: "",
                });
                this.props.history.push("/Costura/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La cantidad de salida: ${cantSalida}, no debe ser mayor a la cantidad de Entrada: ${entrad1}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const { cantSalida } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">Ingresar Salida</CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <Label className="font-weight-bold">FECHA DE ENTREGA</Label>
                  <DatePicker
                    className="col-md-3 d-block"
                    defaultValue={moment(this.state.fechaSalida, "DD/MM/YYYY")}
                    onChange={this.handleChangeSalida}
                    format="DD/MM/YYYY"
                    locale={locale}
                  />
                  <FormGroup>
                    <Label className="font-weight-bold mt-3">
                      ORDEN DE PRODUCCION
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">CANTIDAD SALIDA</Label>
                    <Input
                      className="col-md-3 d-block"
                      name="cantSalida"
                      value={cantSalida}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto mt-3">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive || !cantSalida ? true : false
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateCosturaRecord;
