import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Alert,
  FormGroup,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "../../../Firebase.js";
import { notification, message, DatePicker, Input as In } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "react-datepicker/dist/react-datepicker.css";

class EditAcabadosRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("acabados");

    this.state = {
      isOpen2: false,
      fechaEntrada: new Date(),
      fechaSalida: new Date(),
      fechaSalidaLiq: new Date(),
      cantEntrada: "",
      cantSalida: "",
      cantSalidaLiq: "",
      ordenPedido: "",
      color: "",
      doc: "",
      comercialIndex: null,
      comercialId: null,
      isActive: false,
      cantidadCostura: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const {
      ordenPedido,
      colorState,
      cantidadCostura,
    } = this.props.location.state;
    this.setState({
      cantidadCostura: cantidadCostura,
    });
    const id = this.props.location.state.doc;

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    this.ref
      .doc(id)
      .get()
      .then((ref) => {
        const dataAcabado = ref.data();
        let fechaSalida,
          fechaSalidaLiq = null;

        const cantEntrada = dataAcabado.otros[0].cantEntrada;
        const cantSalida = dataAcabado.otros[0].cantSalida;
        const cantSalidaLiq = dataAcabado.otros[0].cantSalidaLiq;

        const newFI = dataAcabado.otros[0].fechaEntrada.split("/");
        arraymove(newFI, 0, 1);
        const fechaEntrada = new Date(newFI.join("/"));

        if (dataAcabado.otros[0].fechaSalida) {
          const newFS = dataAcabado.otros[0].fechaSalida.split("/");
          arraymove(newFS, 0, 1);
          fechaSalida = new Date(newFS.join("/"));
        }
        if (dataAcabado.otros[0].fechaSalidaLiq) {
          const newFL = dataAcabado.otros[0].fechaSalidaLiq.split("/");
          arraymove(newFL, 0, 1);
          fechaSalidaLiq = new Date(newFL.join("/"));
        }

        this.setState({
          doc: id,
          fechaEntrada,
          fechaSalida,
          fechaSalidaLiq,
          cantEntrada,
          cantSalida,
          cantSalidaLiq,
          color: colorState,
          op: ordenPedido,
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  handleChangeSalidaLiq = (date) => {
    this.setState({
      fechaSalidaLiq: date,
    });
  };

  handleChangeIngreso = (date) => {
    this.setState({
      fechaEntrada: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit2 = (e) => {
    e.preventDefault();

    const {
      fechaSalida,
      cantSalida,
      doc,
      fechaEntrada,
      fechaSalidaLiq,
      cantEntrada,
      cantSalidaLiq,
      comercialId,
      cantidadCostura,
    } = this.state;

    if (
      cantEntrada <= cantidadCostura &&
      cantSalida <= cantEntrada &&
      cantSalidaLiq <= cantSalida
    ) {
      this.setState({
        isActive: false,
      });

      const fechaEntradaFormat = moment(fechaEntrada).format("DD/MM/YYYY");
      const fechaSalidaFormat = moment(fechaSalida).format("DD/MM/YYYY");
      const fechaSalidaLiqFormat = moment(fechaSalidaLiq).format("DD/MM/YYYY");

      try {
        this.ref
          .doc(doc)
          .get()
          .then((res) => {
            const acabado = res.data();

            acabado.otros[0].cantEntrada = Number(cantEntrada) || "";
            acabado.otros[0].cantSalida = Number(cantSalida) || "";
            acabado.otros[0].cantSalidaLiq = Number(cantSalidaLiq) || "";
            acabado.otros[0].fechaEntrada = fechaEntradaFormat || "";
            acabado.otros[0].fechaSalida = fechaSalidaFormat || "";
            acabado.otros[0].fechaSalidaLiq = fechaSalidaLiqFormat || "";

            if (Number(cantSalida) >= acabado.totalCorte) {
              acabado.despacho = "Completo";
            } else if (Number(cantSalida) < acabado.totalCorte) {
              acabado.despacho = "Incompleto";
            }

            try {
              firebase
                .firestore()
                .collection("comercial")
                .doc(String(comercialId))
                .get()
                .then((doc) => {
                  let otros = doc.data();

                  otros.otros.map((ids) => {
                    if (ids.color === acabado.color) {
                      ids.acabado = {
                        cantidadIngresada: Number(cantEntrada) || "",
                        cantidadSalida: Number(cantSalida) || "",
                        cantidadSalidaLiq: Number(cantSalidaLiq) || "",
                        fechaIngreso: fechaEntradaFormat || "",
                        fechaSalida: fechaSalidaFormat || "",
                        fechaSalidaLiq: fechaSalidaLiqFormat || "",
                      };
                    }
                    return ids;
                  });
                  try {
                    firebase
                      .firestore()
                      .collection("comercial")
                      .doc(comercialId)
                      .update(otros);
                  } catch (error) {
                    console.error("Error adding document: ", error.message);
                  }
                });
            } catch (error) {
              console.error("Error adding document: ", error.message);
            }

            try {
              this.ref
                .doc(doc)
                .update(acabado)
                .then((docRef) => {
                  this.setState({
                    fechaSalida: new Date(),
                    selectedOption2: "",
                    numGuiaSer: "",
                    cantSalida: "",
                  });
                  message.destroy();
                  const args = {
                    message: "Registro agregado existosamente.",
                    top: 80,
                  };
                  notification.success(args);
                  this.props.history.push("/Acabado/Procesos");
                });
            } catch (error) {
              console.error("Error adding document: ", error);
            }
          });
      } catch (error) {
        console.error("Error adding document: ", error);
      }

      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La cantidad Ingresada es mayor que la cantidad Principal ${cantidadCostura}, 
      o la cantidad de salida es mayor a la cantidad Ingresada ${cantEntrada}
      o la cantidad liquidada es mayor a la cantidad de salida ${cantSalida}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const {
      op,
      color,
      cantEntrada,
      fechaSalida,
      cantSalida,
      cantSalidaLiq,
      fechaSalidaLiq,
    } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle>EDITAR REGISTRO</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit2}>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <In
                      className="col-md-5 d-block ml-3"
                      value={op}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <In
                      className="col-md-9 d-block ml-3"
                      value={color}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantEntrada">CANTIDAD INGRESADA</Label>
                    <In
                      type="text"
                      name="cantEntrada"
                      id="cantEntrada"
                      className="col-md-5 d-block"
                      value={cantEntrada}
                      onChange={this.onChange}
                      required
                    />
                  </FormGroup>

                  {this.state.fechaSalida ? (
                    <>
                      <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                        FECHA DE SALIDA:
                      </Label>
                      <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                        <DatePicker
                          className="col-md-7 d-block"
                          selected={fechaSalida}
                          value={
                            this.state.fechaSalida
                              ? moment(this.state.fechaSalida, "DD/MM/YYYY")
                              : null
                          }
                          onChange={this.handleChangeSalida}
                          format="DD/MM/YYYY"
                          locale={locale}
                          disabled={true}
                        />
                      </div>
                      <FormGroup
                        style={{ marginLeft: "15px", fontWeight: "bold" }}
                      >
                        <Label for="cantSalida">CANTIDAD SALIDA</Label>
                        <In
                          type="text"
                          name="cantSalida"
                          id="cantSalida"
                          className="col-md-5 d-block"
                          value={cantSalida}
                          onChange={this.onChange}
                          required
                        />
                      </FormGroup>
                    </>
                  ) : null}

                  {this.state.fechaSalidaLiq ? (
                    <>
                      <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                        FECHA DE SALIDA - LIQUIDACION:
                      </Label>
                      <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                        <DatePicker
                          className="col-md-7 d-block"
                          selected={fechaSalidaLiq}
                          value={
                            this.state.fechaSalidaLiq
                              ? moment(this.state.fechaSalidaLiq, "DD/MM/YYYY")
                              : null
                          }
                          onChange={this.handleChangeSalidaLiq}
                          format="DD/MM/YYYY"
                          locale={locale}
                          disabled={true}
                        />
                      </div>

                      <FormGroup
                        style={{ marginLeft: "15px", fontWeight: "bold" }}
                      >
                        <Label for="cantSalidaLiq">CANTIDAD LIQUIDADA</Label>
                        <In
                          type="text"
                          name="cantSalidaLiq"
                          id="cantSalidaLiq"
                          className="col-md-5 d-block"
                          value={cantSalidaLiq}
                          onChange={this.onChange}
                          required
                        />
                      </FormGroup>
                    </>
                  ) : null}

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginTop: "70px" }}
                        type="submit"
                        disabled={this.state.isActive}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditAcabadosRecord;
