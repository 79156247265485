import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Input,
  FormGroup,
  Alert,
  Col,
  Label,
} from "reactstrap";
import firebase from "Firebase.js";
import { notification, Button, message, Input as In } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { uploadFile } from "services";

class EditAlmacenRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("almacen");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      isOpen: false,
      caja: "",
      cantIngresada: "",
      color: null,
      op: null,
      img: null,
      doc: "",
      saldo: null,
      url: null,
      comercialIndex: null,
      comercialId: null,
      isActive: false,
    };
    this.changeImage = this.changeImage.bind(this);
  }

  componentDidMount() {
    const { ordenPedido, doc } = this.props.location.state;
    const colorState = this.props.location.state.color;

    this.setState({ doc: doc });

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    this.ref
      .doc(String(doc))
      .get()
      .then((ref) => {
        const dataAlmacen = ref.data();

        const cantIngresada = dataAlmacen.otros[0].cantAlmacen;
        const caja = dataAlmacen.otros[0].caja;
        const url = dataAlmacen.otros[0].url;

        const newFI = dataAlmacen.otros[0].almacenFecha.split("/");
        arraymove(newFI, 0, 1);
        const fechaIngreso = new Date(newFI.join("/"));

        this.setState({
          cantIngresada,
          caja,
          url,
          fechaIngreso,
          op: ordenPedido,
          color: colorState,
        });
      });

    firebase
      .firestore()
      .collection("corte")
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const corteTotal = doc.data().otros[0].total;
          firebase
            .firestore()
            .collection("acabados")
            .where("op", "==", ordenPedido)
            .where("color", "==", colorState)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const cantSalida = doc.data().otros[0].cantSalida;
                this.setState({
                  saldo: Number(corteTotal) - Number(cantSalida),
                });
              });
            });
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  changeImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      this.setState(() => ({ img }));
    }
  };

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    message.loading("Registrando...", 60);

    const {
      fechaIngreso,
      cantIngresada,
      caja,
      saldo,
      color,
      img,
      doc,
      comercialId,
    } = this.state;

    this.setState({
      isActive: false,
    });
    //fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

    const fechaIngresoFormat = moment(fechaIngreso).format("DD/MM/YYY");
    const almacenFile = await uploadFile(img, "imgprenda");

    try {
      this.ref
        .doc(doc)
        .get()
        .then((res) => {
          const almacen = res.data();

          almacen.otros[0].almacenFecha = fechaIngresoFormat || "";
          almacen.otros[0].caja = caja || "";
          almacen.otros[0].cantAlmacen = cantIngresada || "";
          almacen.otros[0].color = color || "";
          almacen.otros[0].saldo = saldo || "";
          almacen.otros[0].url = [almacenFile || ""];

          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(String(comercialId))
              .get()
              .then((doc) => {
                let otros = doc.data();

                otros.otros.forEach((ids) => {
                  if (ids.color === almacen.color) {
                    ids.almacen = {
                      almacenFecha: fechaIngresoFormat || "",
                      caja: caja || "",
                      cantAlmacen: cantIngresada || "",
                      saldo: saldo || "",
                    };
                  }
                  return ids;
                });
                try {
                  firebase
                    .firestore()
                    .collection("comercial")
                    .doc(comercialId)
                    .update(otros);
                } catch (error) {
                  console.error("Error adding document: ", error.message);
                }
              });
          } catch (error) {
            console.error("Error adding document: ", error.message);
          }

          try {
            this.ref
              .doc(String(doc))
              .update(almacen)
              .then(() => {
                message.destroy();
                const args = {
                  message: "Registro agregado existosamente.",
                  top: 80,
                };
                notification.success(args);
                this.props.history.push("/Almacen/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    this.setState({
      isActive: true,
    });
  };

  render() {
    const { cantIngresada, op, color, caja } = this.state;

    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>Registro</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <In
                      className="col-md-6 d-block ml-3"
                      value={op}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <In
                      className="col-md-9 d-block ml-3"
                      value={color}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantIngresada">CANTIDAD</Label>
                    <In
                      type="text"
                      name="cantIngresada"
                      id="cantIngresada"
                      className="col-md-6 d-block"
                      value={cantIngresada}
                      onChange={this.onChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label>NUMERO DE CAJA</Label>
                    <In
                      type="text"
                      name="caja"
                      id="caja"
                      className="col-md-6 d-block"
                      value={caja}
                      onChange={this.onChange}
                      required
                    />
                  </FormGroup>

                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    IMAGEN
                  </Label>
                  <Input
                    type="file"
                    onChange={this.changeImage}
                    className="col-md-6 pr-1 reset-file"
                    style={{ marginBottom: "20px" }}
                    required
                  />

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        disabled={this.state.isActive}
                        type="primary"
                        style={{ marginTop: "70px" }}
                        onClick={this.onSubmit}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditAlmacenRecord;
