import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from "../../Firebase";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import Modal from "react-modal";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class RegularTables extends React.Component {
  constructor() {
    super();
    this.state = {
      datos: [],
      ft: [],
      op: [],
      loading: true,
      modalIsOpen: false,
      password: "",
      ordenPedido: "",
      color: "",
      doc: "",
      almacenData: [],
    };
  }

  async loadData() {
    await firebase
      .firestore()
      .collection("acabados")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        //let datos = [];
        const data = querySnapshot.docs.reduce((acc, data) => {
          const item = data.data();
          const cantidadCostura = item.cantCostura;
          const itemIndex = acc.findIndex((accItem) => accItem.op === item.op);
          const {
            fechaEntrada,
            cantEntrada,
            fechaSalida,
            cantSalida,
            fechaSalidaLiq,
            cantSalidaLiq,
          } =
            item.otros && item.otros.length
              ? item.otros[0]
              : {
                  fechaEntrada: "",
                  cantEntrada: "",
                  fechaSalida: "",
                  cantSalida: "",
                  fechaSalidaLiq: "",
                  cantSalidaLiq: "",
                };
          const fechaEntradaAcabado = fechaEntrada;
          const cantEntradaAcabado = cantEntrada;
          const cantSalidaAcabado = cantSalida;
          const fechaSalidaLiqAcabado = fechaSalidaLiq;
          const cantSalidaLiqAcabado = cantSalidaLiq;
          const fechaSalidaAcabado = fechaSalida;

          let newItem = [{}];
          if (itemIndex >= 0) {
            newItem = {
              ...acc[itemIndex],
              otros: acc[itemIndex].otros.concat({
                color: item.color,
                totalCorte: item.totalCorte,
                cantCostura: item.cantCostura,
                estado: [
                  item.estado.concat(
                    cantidadCostura,
                    cantEntradaAcabado,
                    cantSalidaAcabado
                  ),
                ],
                despacho: item.despacho,
                edit: [
                  item.index,
                  item.op,
                  item.color,
                  item.estado && item.estado.length ? item.estado[0] : "",
                  !item.isEditable,
                  cantidadCostura,
                  cantEntradaAcabado,
                  cantSalidaAcabado,
                  cantSalidaLiqAcabado,
                ],
                index: item.estado.concat(item.index, !item.isEditable, [
                  item.estado.concat(
                    cantidadCostura,
                    cantEntradaAcabado,
                    cantSalidaAcabado
                  ),
                ]),
                fechaEntrada: fechaEntradaAcabado,
                cantEntrada: cantEntradaAcabado,
                fechaSalida: fechaSalidaAcabado,
                cantSalida: cantSalidaAcabado,
                fechaSalidaLiq: fechaSalidaLiqAcabado,
                cantSalidaLiq: cantSalidaLiqAcabado,
              }),
            };
            acc.splice(itemIndex, 1, newItem);
          } else {
            newItem = {
              costuraFechaIngreso: item.costuraFechaIngreso,
              op: item.op,
              cx: item.cx,
              ft: item.ft,
              b: item.b,
              descripcion: item.descripcion,
              fechaDespacho: item.fechaDespacho,
              otros: [
                {
                  color: item.color,
                  totalCorte: item.totalCorte,
                  cantCostura: item.cantCostura,
                  estado: [
                    item.estado.concat(
                      cantidadCostura,
                      cantEntradaAcabado,
                      cantSalidaAcabado
                    ),
                  ],
                  despacho: item.despacho,
                  edit: [
                    item.index,
                    item.op,
                    item.color,
                    item.estado && item.estado.length ? item.estado[0] : "",
                    !item.isEditable,
                    cantidadCostura,
                    cantEntradaAcabado,
                    cantSalidaAcabado,
                    cantSalidaLiqAcabado,
                  ],
                  index: item.estado.concat(item.index, !item.isEditable, [
                    item.estado.concat(
                      cantidadCostura,
                      cantEntradaAcabado,
                      cantSalidaAcabado
                    ),
                  ]),
                  fechaEntrada: fechaEntradaAcabado,
                  cantEntrada: cantEntradaAcabado,
                  fechaSalida: fechaSalidaAcabado,
                  cantSalida: cantSalidaAcabado,
                  fechaSalidaLiq: fechaSalidaLiqAcabado,
                  cantSalidaLiq: cantSalidaLiqAcabado,
                },
              ],
            };
            acc.push(newItem);
          }
          return acc;
        }, []);

        this.setState({ data, loading: false });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  }
  componentWillMount() {
    this.loadData();
  }

  openModal(value) {
    this.setState({
      modalIsOpen: true,
      doc: value[0],
      ordenPedido: value[1],
      colorState: value[2],
      estado: value[3],
      isEditable: value[4],
      cantidadCostura: value[5],
      cantEntradaAcabado: value[6],
      cantSalidaAcabado: value[7],
      cantSalidaLiqAcabado: value[8],
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: false });
  }

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Acabado/EditAcabado",
          state: {
            doc: this.state.doc,
            ordenPedido: this.state.ordenPedido,
            colorState: this.state.colorState,
            estado: this.state.estado,
            isEditable: this.state.isEditable,
            cantidadCostura: this.state.cantidadCostura,
            cantEntradaAcabado: this.state.cantEntradaAcabado,
            cantSalidaAcabado: this.state.cantSalidaAcabado,
            cantSalidaLiqAcabado: this.state.cantSalidaLiqAcabado,
          },
        })
      : alert("Wrong password, try again");
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  borrar = (value) => {
    const op = value[1];
    const col = value[2];
    const estadoAcabado = value[0];
    const newEstado = value[value.length - 1]
      .slice(0, value[value.length - 1].length - 1)
      .concat(0)
      .map((item, idx) => (idx === 0 ? (item = "Nuevo") : item));

    const estado = value
      .slice(0, 3)
      .map((item, idx) => (idx === 0 ? (item = "Nuevo") : item));
    firebase
      .firestore()
      .collection("acabados")
      .doc(value[3])
      .update({
        isEditable: true,
        estado,
        otros: [],
        despacho: "",
      });

    firebase
      .firestore()
      .collection("costura")
      .where("op", "==", op)
      .where("color", "==", col)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("costura")
            .doc(doc.id)
            .update({
              isEditable: true,
            });
        });
      });

    const dataUpdated = this.state.data.map((item) => {
      if (item.op === op) {
        const otros = item.otros;
        const index = otros.findIndex((item) => item.color === col);
        otros.splice(index, 1, {
          ...otros[index],
          fechaEntrada: "",
          cantEntrada: "",
          fechaSalida: "",
          cantSalida: "",
          fechaSalidaLiq: "",
          cantSalidaLiq: "",
          despacho: "",
          estado: [newEstado],
          index: estado.concat(value[3]),
          otros: [],
          edit: [value[3], op, col, estado, true],
        });
      }
      return item;
    });

    this.setState({ data: dataUpdated });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", op)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach((doc) => {
          firebase
            .firestore()
            .collection("comercial")
            .doc(doc.id)
            .update({
              otros: doc.data().otros.map((v) => {
                if (v.color === col) {
                  delete v.acabado;
                  return v;
                }
                return v;
              }),
            });
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const status = "Costura";
          firebase
            .firestore()
            .collection("ing")
            .doc(doc.id)
            .update({
              otros: doc.data().otros.map((v) => {
                if (v.color === col) {
                  return {
                    ...v,
                    status: [status],
                  };
                }
                return v;
              }),
            });
        });
      });

    if (estadoAcabado === "Listo" || "Liquidado") {
      try {
        firebase
          .firestore()
          .collection("almacen")
          .where("op", "==", op)
          .where("color", "==", col)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              firebase
                .firestore()
                .collection("almacen")
                .doc(doc.id)
                .delete();
            });
          });
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  render() {
    const { data, loading } = this.state;
    const mainAcabado = [
      {
        headerStyle: { backgroundColor: "#6DC31F", color: "white" },
        columns: [
          {
            Header: "F.Costura",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 120,
            accessor: "costuraFechaIngreso",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "costuraFechaIngreso",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Orden de Produccion",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 242,
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "cx",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Ficha Tecnica",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 220,
            accessor: "ft",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "ft" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "b",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Descripcion",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 260,
            accessor: "descripcion",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "descripcion",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "F.Despacho",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 120,
            accessor: "fechaDespacho",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "fechaDespacho",
                  },
                ],
              }),
            filterAll: true,
          },
        ],
      },
    ];

    const subAcabado = [
      {
        Header: "Acabado",
        headerStyle: {
          backgroundColor: "#6DC31F",
          color: "white",
          fontWeight: "bold",
        },
        columns: [
          {
            Header: "Color",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "color",
            width: 130,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "color" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Cantidad Corte",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 120,
            accessor: "totalCorte",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "totalCorte",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Cantidad Costura",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 140,
            accessor: "cantCostura",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cantCostura",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Fecha Ingreso",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaEntrada",
          },
          {
            Header: "Cantidad",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantEntrada",
          },

          {
            Header: "Fecha Despacho",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaSalida",
          },
          {
            Header: "Cantidad",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantSalida",
          },
          {
            Header: "Fecha Liquidacion",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaSalidaLiq",
          },
          {
            Header: "Cantidad",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantSalidaLiq",
          },
          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 90,
            style: { fontSize: "12px" },
            accessor: "estado",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.STARTS_WITH,
                    key: "estado",
                  },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.value[0][0] === "Nuevo" ? (
                  <Link
                    to={{
                      pathname: "/Acabado/RegistroDirecto",
                      state: {
                        ordenPedido: row.value[0][1],
                        colorState: row.value[0][2],
                        cantidadCostura: row.value[0][3],
                        cantEntradaAcabado: row.value[0][4],
                        cantSalidaAcabado: row.value[0][5],
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#F16035",
                        width: "80%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : row.value[0][0] === "En Curso" ? (
                  <Link
                    to={{
                      pathname: "/Acabado/RegistroActDirecto",
                      state: {
                        ordenPedido: row.value[0][1],
                        colorState: row.value[0][2],
                        cantidadCostura: row.value[0][3],
                        cantEntradaAcabado: row.value[0][4],
                        cantSalidaAcabado: row.value[0][5],
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FF7e00",
                        width: "90%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : row.value[0][0] === "Listo" ? (
                  <Link
                    to={{
                      pathname: "/Acabado/RegistroLiquidacion",
                      state: {
                        ordenPedido: row.value[0][1],
                        colorState: row.value[0][2],
                        cantidadCostura: row.value[0][3],
                        cantEntradaAcabado: row.value[0][4],
                        cantSalidaAcabado: row.value[0][5],
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#3b5998",
                        width: "90%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : (
                  <span
                    style={{
                      backgroundColor: "green",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "6px",
                      color: "white",
                    }}
                  >
                    {row.value[0][0]}
                  </span>
                )}
              </div>
            ),
          },

          {
            Header: "Despachado",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 120,
            accessor: "despacho",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.STARTS_WITH,
                    key: "despacho",
                  },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <span
                style={{
                  backgroundColor:
                    row.value === "Completo"
                      ? "#29a00b"
                      : row.value === "Incompleto"
                      ? "#a00c01"
                      : "white",
                  borderRadius: "10px",
                  padding: "6px",
                  color: "white",
                }}
              >
                {row.value}
              </span>
            ),
          },

          {
            Header: "Editar",
            accessor: "edit",
            width: 80,
            headerStyle: { fontWeight: "bold" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["edit"] }),
            filterAll: true,
            Cell: (row) => (
              <Button
                style={{
                  backgroundColor: "orange",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "10px",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0px",
                  padding: "5px",
                }}
                disabled={
                  row.value[3] === "Nuevo" || row.value[4] ? true : false
                }
                onClick={() => this.openModal(row.value)}
              >
                Editar
              </Button>
            ),
          },
          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 80,
            accessor: "index",
            Cell: (row) => (
              <div className="text-center">
                {
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={
                      row.value[0] === "Nuevo" || row.value[4] ? true : false
                    }
                    onClick={(e) =>
                      window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                      this.borrar(row.value)
                    }
                    type="button"
                  >
                    Borrar
                  </Button>
                }
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos Acabado
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  loading={loading}
                  loadingText="POR FAVOR, ESPERE UN MOMENTO"
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={mainAcabado}
                  noDataText="Sin Registros"
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  minRows={0}
                  rowsText="filas"
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.otros}
                          columns={subAcabado}
                          noDataText="Sin Registros"
                          pageSize={row.original.otros.length}
                          //defaultPageSize={1}
                          showPagination={false}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0",
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{
                border: "1px solid black",
                font: "small-caption",
                fontSize: "16px",
              }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              autoFocus
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={(e) => this.closeModal(e)}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
