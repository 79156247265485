import React from "react";
import moment from "moment";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Label,
  Alert,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButton/CustomButton.jsx";

registerLocale("es", es);

class NewCosturaRecord extends React.Component {
  constructor() {
    super();

    this.ref = firebase.firestore().collection("pcp");
    
    this.state = {
      fechaIngreso: new Date(),
      estilo: "",
      estilo2: "",
      isOpen: false,
      isOpen2: false,
      color: "",
      color2: "",
      servicio2: "",
      numGuia2: "",
      cantIngresada: "",
      fechaSalida: new Date(),
      cantSalida: "",
      descripcion: "",
      p: [],
      p2: [],
      selectedOption: null,
      selectedOption2: null,
    };

    this.selectHandleChange2 = this.selectHandleChange2.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let p = [];
        querySnapshot.forEach(function(doc) {
          let corte = [];
          if (doc.data().corte) {
            for (let t = 0; t < doc.data().corte.length; t++) {
              if (doc.data().corte[t].seg === "costura") {
                corte.push({
                  value: doc.id,
                  op: doc.data().corte[t].op,
                  color: doc.data().corte[t].color,
                  total: doc.data().corte[t].total,
                  cantpcp: doc.data().corte[t].cantpcp,
                  ft: doc.data().corte[t].ft,
                  descripcion: doc.data().corte[t].descripcion,
                });
              }
            }
          }
          let costura = [];
          if (doc.data().costura) costura = doc.data().costura;

          for (let i = 0; i < corte.length; i++) {
            if (doc.data().costura) {
              let x = 0;
              while (x < costura.length) {
                if (corte[i] !== undefined)
                  if (
                    corte[i].op === costura[x].op &&
                    corte[i].color === costura[x].color
                  ) {
                    corte.splice(i, 1);
                    i--;
                  }
                x++;
              }
            } else {
              p.push({
                label: corte[i].op + " " + corte[i].color,
                value: doc.id,
                descripcion: corte[i].descripcion,
                color: corte[i].color,
                op: corte[i].op,
                total: corte[i].total,
                cantpcp: corte[i].cantpcp,
                ft: corte[i].ft,
              });
            }
          }

          corte.forEach((value) => {
            p.push({
              label: value.op + " " + value.color,
              value: doc.id,
              color: value.color,
              descripcion: value.descripcion,
              op: value.op,
              total: value.total,
              cantpcp: value.cantpcp,
              ft: value.ft,
            });
          });
          function removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject = {};

            for (var i in originalArray) {
              lookupObject[originalArray[i][prop]] = originalArray[i];
            }

            for (i in lookupObject) {
              newArray.push(lookupObject[i]);
            }
            return newArray;
          }
          p = removeDuplicates(p, "label");
        });
        this.setState({ p: p });
      });

    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let p2 = [];
        querySnapshot.forEach(function(doc) {
          let costura = [];
          if (doc.data().costura) costura = doc.data().costura;

          for (let i = 0; i < costura.length; i++) {
            if (costura[i].seg === "costura-update") {
              p2.push({
                label: costura[i].op + " " + costura[i].color,
                value: doc.id,
                color: costura[i].color,
                op: costura[i].op,
              });
            }
          }
        });

        this.setState({ p2: p2 });
      });
  }

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChangeIngreso = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  selectHandleChange2 = (selectedOption2) => {
    this.setState({ selectedOption2 });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaIngreso,
      cantIngresada,
      selectedOption,
    } = this.state;

    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");
    this.ref
      .doc(selectedOption.value)
      .update({
        costura: firebase.firestore.FieldValue.arrayUnion({
          fechaIngreso,
          servicio: "TexCross",
          cantIngresada,
          color: selectedOption.color,
          op: selectedOption.op,
          descripcion: selectedOption.descripcion,
          totalCorte: selectedOption.total,
          seg: "costura-update",
          cantpcp: Number(selectedOption.cantpcp),
          ft: selectedOption.ft,
        }),
      })
      .then((docRef) => {
        this.setState({
          fechaIngreso: new Date(),
          cantIngresada: "",
          selectedOption: "",
        });
        this.componentDidMount();
        this.handleClickShowAlert();
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  onSubmit2 = (e) => {
    e.preventDefault();

    let { fechaSalida, cantSalida, selectedOption2 } = this.state;

    fechaSalida = moment(fechaSalida).format("DD/MM/YYYY");

    this.ref
      .doc(selectedOption2.value)
      .get()
      .then((res) => {
        let costura = res.data().costura;
        var nuevo = [];
        nuevo = costura.map((value) => {
          if (
            value.op === selectedOption2.op &&
            value.color === selectedOption2.color
          ) {
            value.cantSalida = cantSalida;
            value.fechaSalida = fechaSalida;
            value.seg = "acabado";
          }
          return value;
        });

        try {
          this.ref
            .doc(selectedOption2.value)
            .update({
              costura: nuevo,
            })
            .then((docRef) => {
              this.setState({
                fechaSalida: new Date(),
                selectedOption2: "",
                cantSalida: "",
              });
              this.componentDidMount();
              this.handleClickShowAlert2();
            });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });
  };

  render() {
    const {
      cantSalida,
      cantIngresada,
      selectedOption,
      selectedOption2,
    } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>INGRESO</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <Label
                    style={{ marginLeft: "15px", fontWeight: "bold" }}
                    for="fechaIngreso"
                  >
                    FECHA:
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaIngreso}
                      onChange={this.handleChangeIngreso}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Select
                      isSearchable={true}
                      options={this.state.p}
                      className={"col-md-5"}
                      placeholder="Seleccione"
                      value={selectedOption}
                      onChange={this.selectHandleChange}
                    />
                  </FormGroup>
              
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantIngresada">CANTIDAD INGRESADA</Label>
                    <Input
                      type="text"
                      name="cantIngresada"
                      id="cantIngresada"
                      className="col-md-3 pr-1"
                      value={cantIngresada}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginnTop: "20px" }}
                        type="submit"
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>

          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ fontWeight: "bold", margin: "0 auto" }}>
                <CardTitle>SALIDA</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit2}>
                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FECHA:
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaSalida}
                      onChange={this.handleChangeSalida}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Select
                      isSearchable={true}
                      options={this.state.p2}
                      placeholder="Seleccione"
                      className={"col-md-5"}
                      value={selectedOption2}
                      onChange={this.selectHandleChange2}
                    />
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantSalida">CANTIDAD SALIDA</Label>
                    <Input
                      type="text"
                      name="cantSalida"
                      id="cantSalida"
                      className="col-md-3 pr-1"
                      value={cantSalida}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </FormGroup>

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginTop: "10px" }}
                        type="submit"
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewCosturaRecord;
