import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/comercial"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        Comercial
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Link
                    to="/Ingenieria"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Col xs={12} md={12}>
                      <CardTitle tag="p" className="numbers text-center">
                        PCP
                      </CardTitle>
                    </Col>
                  </Link>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/Ddp"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        DDP
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/Telas"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        Telas
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/Corte"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        Corte
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/Costura"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        Costura
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/Acabado"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        Acabado
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row
                  style={{
                    height: "100px",
                    flex: "1",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Col xs={12} md={12}>
                    <Link
                      to="/Almacen"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardTitle tag="p" className="numbers text-center">
                        Almacen
                      </CardTitle>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
