import Dashboard from "views/Dashboard/Dashboard.jsx";
import Consulta from "views/TableList/ConsultaCorte.jsx";
//import NewCorteRecord from "views/UserPage/corte/NewCorteRecord";
import SecondaryNewCorteRecord from "views/UserPage/corte/SecondaryNewCorteRecord";
import EditCorteRecord from "views/UserPage/corte/EditCorteRecord";
import UpdateCorteRecord from "views/UserPage/corte/UpdateCorteRecord";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Corte/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  /*
  {
    path: "/Corte/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: NewCorteRecord,
  },
  */
  {
    path: "/Corte/RegistroDirecto",
    name: "Registro Directo",
    component: SecondaryNewCorteRecord,
    invisible: true,
  },
  {
    path: "/Corte/EditCorte",
    name: "EditPcp",
    component: EditCorteRecord,
    invisible: true,
  },
  {
    path: "/Corte/RegistroActDirecto",
    name: "Registro Actualizado",
    component: UpdateCorteRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Corte/Procesos",
    name: "Registro",
  },
];

export default dashRoutes;
