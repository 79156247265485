import React from "react";
import moment from "moment";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Alert,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "../../../Firebase.js";

registerLocale("es", es);

class NewAcabadosRecord extends React.Component {

  constructor() {
    super();
    this.ref = firebase.firestore().collection("pcp");

    this.state = {
      fechaIngreso: new Date(),
      isOpen: false,
      isOpen2: false,
      numGuia2: "",
      servicio2: "",
      cantidadIngresada: "",
      fechaSalida: new Date(),
      cantidadSalida: "",
      p: [],
      p2: [],
      selectedOption: "",
      selectedOption2: "",
    };
  }

  componentDidMount() {
    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let p = [];
        querySnapshot.forEach(function(doc) {
          let costura = [];
          if (doc.data().costura) {
            for (let t = 0; t < doc.data().costura.length; t++) {
              if (doc.data().costura[t].seg === "acabado") {
                costura.push({
                  value: doc.id,
                  op: doc.data().costura[t].op,
                  color: doc.data().costura[t].color,
                  descripcion: doc.data().costura[t].descripcion,
                  total: doc.data().costura[t].cantSalida,
                  cantpcp: doc.data().costura[t].cantpcp,
                  ft: doc.data().costura[t].ft,
                });
              }
            }
          }
          let acabado = [];
          if (doc.data().acabado) acabado = doc.data().acabado;

          for (let i = 0; i < costura.length; i++) {
            if (doc.data().acabado) {
              let x = 0;
              while (x < acabado.length) {
                if (costura[i] !== undefined)
                  if (
                    costura[i].op === acabado[x].op &&
                    costura[i].color === acabado[x].color
                  ) {
                    costura.splice(i, 1);
                    i--;
                  }
                x++;
              }
            } else {
              p.push({
                label: costura[i].op + " " + costura[i].color,
                value: doc.id,
                op: costura[i].op,
                color: costura[i].color,
                descripcion: costura[i].descripcion,
                total: costura[i].total,
                cantpcp: costura[i].cantpcp,
                ft: costura[i].ft,
              });
            }
          }

          costura.forEach((value) => {
            p.push({
              label: value.op + " " + value.color,
              value: doc.id,
              color: value.color,
              op: value.op,
              total: value.total,
              descripcion: value.descripcion,
              cantpcp: value.cantpcp,
              ft: value.ft,
            });
          });

          function removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject = {};

            for (var i in originalArray) {
              lookupObject[originalArray[i][prop]] = originalArray[i];
            }

            for (i in lookupObject) {
              newArray.push(lookupObject[i]);
            }
            return newArray;
          }
          p = removeDuplicates(p, "label");
        });
        this.setState({ p: p });
      });

    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let p2 = [];
        querySnapshot.forEach(function(doc) {
          let acabado = [];
          if (doc.data().acabado) acabado = doc.data().acabado;

          for (let i = 0; i < acabado.length; i++) {
            if (acabado[i].seg === "acabado-update") {
              p2.push({
                label: acabado[i].op + " " + acabado[i].color,
                value: doc.id,
                color: acabado[i].color,
                op: acabado[i].op,
              });
            }
          }
        });
        this.setState({ p2: p2 });
      });
  }

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChangeIngreso = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  selectHandleChange2 = (selectedOption2) => {
    this.setState({ selectedOption2 });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaIngreso,
      cantidadIngresada,
      selectedOption,
    } = this.state;
    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

    try {
      this.ref
        .doc(selectedOption.value)
        .update({
          acabado: firebase.firestore.FieldValue.arrayUnion({
            fechaIngreso,
            cantidadIngresada,
            op: selectedOption.op,
            color: selectedOption.color,
            cantCostura: selectedOption.total,
            descripcion: selectedOption.descripcion,
            ft: selectedOption.ft,
            cantpcp: selectedOption.cantpcp,
            seg: "acabado-update",
          }),
        })
        .then((docRef) => {
          this.setState({
            fechaIngreso: new Date(),
            selectedOption: "",
            cantidadIngresada: "",
          });
          this.componentDidMount();
          this.handleClickShowAlert();
        });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  onSubmit2 = (e) => {
    e.preventDefault();

    let { fechaSalida, cantidadSalida, selectedOption2 } = this.state;
    fechaSalida = moment(fechaSalida).format("DD/MM/YYYY");

    this.ref
      .doc(selectedOption2.value)
      .get()
      .then((res) => {
        let acabado = res.data().acabado;
        var nuevo = [];
        nuevo = acabado.map((value) => {
          if (
            value.op === selectedOption2.op &&
            value.color === selectedOption2.color
          ) {
            value.fechaSalida = fechaSalida;
            value.cantSalida = cantidadSalida;
            value.seg = "almacen";
          }
          return value;
        });

        this.ref
          .doc(selectedOption2.value)
          .update({
            acabado: nuevo,
          })
          .then((docRef) => {
            this.setState({
              fechaSalida: new Date(),
              selectedOption2: "",
              cantidadSalida: "",
            });
            this.componentDidMount();
            this.handleClickShowAlert2();
          })

          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      });
  };

  render() {
    const {
      selectedOption,
      cantidadIngresada,
      cantidadSalida,
      selectedOption2,
    } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>INGRESO</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FECHA
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaIngreso}
                      onChange={this.handleChangeIngreso}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <Select
                      isSearchable={true}
                      placeholder="Seleccione"
                      options={this.state.p}
                      className={"col-md-5"}
                      value={selectedOption}
                      onChange={this.selectHandleChange}
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantidadIngresada">CANTIDAD INGRESADA</Label>
                    <Input
                      type="text"
                      name="cantidadIngresada"
                      id="cantidadIngresada"
                      className="col-md-3 pr-1"
                      value={cantidadIngresada}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginnTop: "30px" }}
                        type="submit"
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>

          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>SALIDA</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit2}>
                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FECHA
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaSalida}
                      onChange={this.handleChangeSalida}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <Select
                      isSearchable={true}
                      options={this.state.p2}
                      className={"col-md-5"}
                      placeholder="Seleccione"
                      value={selectedOption2}
                      onChange={this.selectHandleChange2}
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantidadSalida">CANTIDAD DE SALIDA</Label>
                    <Input
                      type="text"
                      name="cantidadSalida"
                      id="cantidadSalida"
                      className="col-md-3 pr-1"
                      value={cantidadSalida}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginTop: "10px" }}
                        type="submit"
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewAcabadosRecord;
