import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, message, Button, DatePicker, Select, Input } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "antd/dist/antd.css";
import {
  updateTelaRecord,
  getTelaRecord,
  createCorteRecord,
  updatePcpRecordInTela,
  updateComercialRecordFromTelas,
} from "services";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
class NewTelaRecord extends React.Component {
  constructor() {
    super();
    this.state = {
      fechaIngreso: new Date(),
      fechaSalida: new Date(),
      op: null,
      color: null,
      estado: "",
      docId: null,
      telaRecord: {},
      isActive: false,
    };
  }

  componentDidMount() {
    const { ordenPedido, colorState, docId } = this.props.location.state;

    this.setState({
      docId,
      color: colorState,
      op: ordenPedido,
    });

    getTelaRecord(docId).then((telaRecord) => {
      this.setState({
        telaRecord,
      });
    });
  }

  formatCorteRecord = (telaRecord, fechaIngreso, fechaSalida) => {
    return {
      b: telaRecord.b ? telaRecord.b : null,
      cantidad: telaRecord.cantidad,
      color: telaRecord.color,
      cx: telaRecord.cx ? telaRecord.cx : null,
      descripcion: telaRecord.descripcion,
      estado: ["Nuevo", telaRecord.op, telaRecord.color],
      fechaIngreso: fechaIngreso,
      fechaSalida: fechaSalida,
      ft: telaRecord.ft ? telaRecord.ft : null,
      hojaConsumo: telaRecord.hojaConsumo ? telaRecord.hojaConsumo : null,
      hc: telaRecord.hc ? telaRecord.hc : null,
      op: telaRecord.op,
      otros: [],
      isEditable: true,
    };
  };

  handleChangeIngreso = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };
  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    message.loading("Registrando..", 60);
    const {
      fechaIngreso,
      fechaSalida,
      estado,
      docId,
      op,
      color,
      telaRecord,
    } = this.state;

    this.setState({
      isActive: true,
    });

    const fechaIngresoFormat = moment(fechaIngreso).format("DD/MM/YYYY");
    const fechaSalidaFormat = moment(fechaSalida).format("DD/MM/YYYY");

    try {
      await updateTelaRecord(
        {
          fechaIngreso: fechaIngresoFormat,
          fechaSalida: fechaSalidaFormat,
          estado: [estado, op, color],
        },
        docId
      );

      if (estado === "Rotulado") {
        await createCorteRecord(
          this.formatCorteRecord(
            telaRecord,
            fechaIngresoFormat,
            fechaSalidaFormat
          )
        );
      }

      try {
        firebase
          .firestore()
          .collection("ddp")
          .where("op", "==", op)
          .get()
          .then((qs) => {
            qs.forEach((doc) => {
              firebase
                .firestore()
                .collection("ddp")
                .doc(doc.id)
                .update({
                  isEditable: false,
                });
            });
          });
      } catch (error) {
        console.error(error.message);
      }

      await updatePcpRecordInTela(op, color, estado, false);
      await updateComercialRecordFromTelas(
        op,
        color,
        estado,
        fechaIngresoFormat
      );
      notification.success({
        message: "Registro agregado exitosamente",
        top: 80,
      });

      this.props.history.push("/Telas/Procesos");
    } catch (e) {
      notification.error({
        message: "Sucedio un error, vuelva a intentarlo.",
        top: 80,
      });
      console.error("Fallo actualizar Tela ", e.message);
    } finally {
      message.destroy();
      this.setState({
        fechaIngreso: new Date(),
        fechaSalida: new Date(),
        estado: "",
      });
    }
    this.setState({
      isActive: false,
    });
  };

  render() {
    const { fechaIngreso, fechaSalida } = this.state;
    const isInvalid = fechaIngreso === null || fechaSalida === null;
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ textAlign: "center" }}>
                  Registro Telas
                </CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label className="font-weight-bold">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="fechaSalida" className="font-weight-bold">
                      FECHA ENTREGA
                    </Label>
                    <DatePicker
                      className="col-md-3 d-block"
                      defaultValue={
                        this.state.fechaSalida
                          ? moment(this.state.fechaSalida, dateFormat)
                          : null
                      }
                      format={dateFormat}
                      locale={locale}
                      onChange={this.handleChangeSalida}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">ESTADO</Label>
                    <Select
                      className="col-md-3 d-block"
                      onChange={(estado) => this.setState({ estado })}
                      placeholder="Seleccione estado"
                      required
                      showSearch={true}
                    >
                      <Option value="Tejeduria">Tejeduria</Option>
                      <Option value="Tintoreria">Tintoreria</Option>
                      <Option value="Compras">Compras</Option>
                      <Option value="Rotulado">Rotulado</Option>
                    </Select>
                  </FormGroup>

                  <Row>
                    <div className="update ml-auto mr-auto mt-4">
                      <Button
                        disabled={
                          this.state.isActive || isInvalid ? true : false
                        }
                        type="primary"
                        onClick={this.onSubmit}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewTelaRecord;
