import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { Button } from "reactstrap";
import firebase from "../../Firebase";
import Modal from "react-modal";
import { Modal as Modals, Button as Buttons } from "antd";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class RegularTables extends React.Component {
  constructor() {
    super();
    this.state = {
      datos: [],
      loading: true,
      prof: "",
      op: [],
      ft: [],
      profsp: [],
      modalIsOpen: false,
      password: "",
      doc: "",
      cliente: "",
      op2: "",
      fechaIngreso: "",
      fechaSalida: "",
      pcpData: [],
      setFiles: [],
      allfiles: "",
      visible: false,
      propagation: false,
      colors: null,
      modalTitleProf: "",
      modalTitleOp: "",
    };
  }

  async loadData() {
    await firebase
      .firestore()
      .collection("comercial")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((acc, data) => {
          const colores = data.data().otros.map((v) => v.color);

          const getStatus = data
            .data()
            .otros.every((item) => item.acabado && item.acabado.fechaSalida);
          const item = data.data();
          const itemIndex = acc.findIndex(
            (accItem) => accItem.prof === item.prof
          );
          const colorList = item.otros && item.otros.length ? item.otros : [];
          const detalles = item.otros.reduce((ac, v) => {
            ac.push({
              color: v.color,
              estilo: v.estilo,
              cant: v.cant,
              estado:
                v.acabado && v.acabado.fechaSalidaLiq
                  ? "Liquidado"
                  : v.acabado && v.acabado.fechaSalida
                  ? "Despachado"
                  : v.acabado
                  ? "Acabado"
                  : v.costura
                  ? "Costura"
                  : v.corte
                  ? "Corte"
                  : v.telas
                  ? v.telas.tEstado
                  : "PCP",
            });
            return ac;
          }, []);

          let newItem = [{}];
          if (itemIndex >= 0) {
            newItem = {
              ...acc[itemIndex],
              otros: acc[itemIndex].otros.concat({
                prof: item.prof,
                op: item.op,
                b: item.b,
                descripcion:
                  colorList && colorList.length ? colorList[0].descripcion : [],
                estilo:
                  colorList && colorList.length ? colorList[0].estilo : [],
                fichaTecnica: item.fichaTecnica,
                f: item.f,
                states: item.otros.length
                  ? getStatus
                    ? "Completo"
                    : "Pendiente"
                  : "Pendiente",
                detalles,
              }),
            };
            acc.splice(itemIndex, 1, newItem);
          } else {
            newItem = {
              cFechaIngreso: item.cFechaIngreso,
              prof: item.prof,
              a: item.a[0] ? item.a[0] : [],
              profsp: item.profsp,
              swf30: item.sw30 || "",
              sw30:
                item.sw30Files && item.sw30Files.length
                  ? item.sw30Files[0]
                  : "",
              swf70: item.sw70 || "",
              sw70:
                item.sw70Files && item.sw70Files.length
                  ? item.sw70Files[0]
                  : "",
              cliente: item.cliente,
              cFechaEntrega: item.cFechaEntrega,
              states: item.otros.length
                ? getStatus
                  ? "Completo"
                  : "Pendiente"
                : "Pendiente",
              edit: [
                item.index,
                item.prof,
                item.cliente,
                item.cFechaIngreso,
                item.cFechaEntrega,
                !item.isEditable,
                item.a && item.a.length ? item.a[0] : "",
                item.profsp && item.profsp.length ? item.profsp[0] : "",
                item.sw30Files && item.sw30Files.length
                  ? item.sw30Files[0]
                  : "",
                item.sw70Files && item.sw70Files.length
                  ? item.sw70Files[0]
                  : "",
                item.sw30 || "",
                item.sw70 || "",
              ],
              value: [
                item.index,
                item.op,
                colores,
                item.prof,
                !item.isEditable,
              ],
              otros: [
                {
                  prof: item.prof,
                  op: item.op,
                  b: item.b,
                  descripcion:
                    colorList && colorList.length
                      ? colorList[0].descripcion
                      : [],
                  estilo:
                    colorList && colorList.length ? colorList[0].estilo : [],
                  fichaTecnica: item.fichaTecnica,
                  f: item.f,
                  states: item.otros.length
                    ? getStatus
                      ? "Completo"
                      : "Pendiente"
                    : "Pendiente",
                  detalles,
                },
              ],
            };
            acc.push(newItem);
          }
          return acc;
        }, []);
        this.setState({ data, loading: false });
      });
  }

  componentDidMount() {
    this.loadData();
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  borrar = (value) => {
    const id = value[0];
    const op = value[1];

    firebase
      .firestore()
      .collection("comercial")
      .doc(id)
      .delete();

    firebase
      .firestore()
      .collection("ing")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("ing")
            .doc(doc.id)
            .delete();
        });
      });

    var contador = 0;
    var lista = this.state.data;
    lista.map((registro) => {
      const idReg = registro.value[0];
      if (idReg === id) {
        lista.splice(contador, 1);
      }
      contador++;
      return registro;
    });
    this.setState({ data: lista });
  };

  openModal = (value) => {
    this.setState({
      modalIsOpen: true,
      doc: value[0],
      prof: value[1],
      cliente: value[2],
      fechaIngreso: value[3],
      fechaSalida: value[4],
      isEditable: value[5],
      a: value[6],
      profsp: value[7],
      sw30F: value[8],
      sw70F: value[9],
      swift30: value[10],
      swift70: value[11],
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Comercial/EditComercial",
          state: {
            doc: this.state.doc,
            prof: this.state.prof,
            cliente: this.state.cliente,
            fechaIngreso: this.state.fechaIngreso,
            fechaSalida: this.state.fechaSalida,
            isEditable: this.state.isEditable,
            a: this.state.a,
            profsp: this.state.profsp,
            sw30F: this.state.sw30F,
            sw70F: this.state.sw70F,
            swift30: this.state.swift30,
            swift70: this.state.swift70,
          },
        })
      : alert("Contraseña incorrecta, Vuelva intentarlo");
  };

  render() {
    const { data, loading, modalTitleOp, modalTitleProf } = this.state;
    const columns = [
      {
        columns: [
          {
            Header: "Fecha Inicio",
            headerStyle: { fontWeight: "bold" },
            width: 100,
            accessor: "cFechaIngreso",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cFechaIngreso",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Factura Proforma",
            headerStyle: { fontWeight: "bold" },
            accessor: "prof",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "prof" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "a",
            width: 30,
            Cell: (row) => (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ),
          },
          {
            Header: "",
            accessor: "profsp",
            width: 30,
            Cell: (row) => (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/textiles-cross.appspot.com/o/img%2F32x32.png?alt=media&token=02d1fb95-343c-4b7f-a98d-830e6d5b2506"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ),
          },

          {
            Header: "30%",
            headerStyle: { fontWeight: "bold" },
            accessor: "sw30",
            width: 40,
            Cell: (row) => (
              <div>
                {row.value ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },

          {
            Header: "70%",
            headerStyle: { fontWeight: "bold" },
            accessor: "sw70",
            width: 40,
            Cell: (row) => (
              <div>
                {row.value ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },

          {
            Header: "Cliente",
            headerStyle: { fontWeight: "bold" },
            accessor: "cliente",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cliente  ",
                  },
                ],
              }),
            filterAll: true,
          },

          {
            Header: "Fecha Despacho",
            width: 140,
            headerStyle: { fontWeight: "bold" },
            accessor: "cFechaEntrega",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cFechaEntrega",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold" },
            width: 115,
            accessor: "states",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["states"] }),
            filterAll: true,
            Cell: (row) => (
              <div>
                {row.value === "Completo" ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                      fontWeight: "bold",
                      color: "white",
                      borderRadius: "10px",
                      padding: "6px",
                      backgroundColor:
                        row.value === "Nuevo"
                          ? "#03A9F4"
                          : row.value === "Pendiente"
                          ? "#ee6f05"
                          : "#6DC31F",
                    }}
                  >
                    {row.value}
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Editar",
            headerStyle: { fontWeight: "bold" },
            width: 80,
            accessor: "edit",
            Cell: (row) => (
              <div>
                {
                  <Button
                    style={{
                      backgroundColor: "orange",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={row.value[5] ? true : false}
                    onClick={() => this.openModal(row.value)}
                  >
                    Editar
                  </Button>
                }
              </div>
            ),
          },
          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold" },
            width: 100,
            accessor: "value",
            Cell: (row) => (
              <div className="text-center">
                <Button
                  style={{
                    backgroundColor: "red",
                    width: "80%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                  disabled={row.value[4] ? true : false}
                  onClick={() =>
                    window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                    this.borrar(row.value)
                  }
                  type="button"
                >
                  Borrar
                </Button>
              </div>
            ),
          },
        ],
      },
    ];
    const subComercial1 = [
      {
        Header: "Orden de Produccion",
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: "#03A9F4",
          color: "white",
        },
        columns: [
          {
            Header: "Orden de Produccion",
            headerStyle: { fontWeight: "bold" },
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
              }),
            filterAll: true,
          },
          {
            Header: "OP",
            headerStyle: { fontWeight: "bold" },
            accessor: "b",
            width: 50,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Descripcion",
            headerStyle: { fontWeight: "bold" },
            accessor: "descripcion",
          },
          {
            Header: "Estilo",
            headerStyle: { fontWeight: "bold" },
            accessor: "estilo",
          },
          {
            Header: "Ficha Técnica",
            headerStyle: { fontWeight: "bold" },
            accessor: "fichaTecnica",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "fichaTecnica",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "FT",
            headerStyle: { fontWeight: "bold" },
            accessor: "f",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold" },
            width: 115,
            accessor: "states",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["states"] }),
            filterAll: true,
            Cell: (row) => (
              <div>
                {row.original.op ? (
                  <span
                    style={{
                      width: "80%",
                      textAlign: "center",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                      backgroundColor:
                        row.value === "Nuevo"
                          ? "#03A9F4"
                          : row.value === "Pendiente"
                          ? "#ee6f05"
                          : "#6DC31F",
                    }}
                  >
                    {row.value}
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Detalles",
            headerStyle: { fontWeight: "bold" },
            width: 115,
            accessor: "detalles",
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.original.op ? (
                  <Buttons
                    type="primary"
                    style={{
                      width: "80%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    onClick={() => {
                      this.setState({
                        visible: true,
                        colors: row.value,
                        modalTitleOp: row.original.op,
                        modalTitleProf: row.original.prof,
                      });
                    }}
                  >
                    Ver Detalles
                  </Buttons>
                ) : null}
              </div>
            ),
          },
        ],
      },
    ];

    const subComercial2 = [
      {
        Header: "COLORES",
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: "#03A9F4",
          color: "white",
          filterAll: true,
          Cell: (row) => (
            <span
              style={{
                width: "80%",
                textAlign: "rigth",
                borderRadius: "10px",
                fontWeight: "bold",
                color: "white",
                margin: "0px",
                padding: "5px",
              }}
            ></span>
          ),
        },
        columns: [
          {
            Header: "Color",
            headerStyle: { fontWeight: "bold" },
            accessor: "color",
          },
          {
            Header: "Cantidad",
            headerStyle: { fontWeight: "bold" },
            accessor: "cant",
          },
          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold" },
            accessor: "estado",
            Cell: (row) => (
              <span
                style={{
                  backgroundColor:
                    row.value === "PCP"
                      ? "#03A9F4"
                      : row.value === "Despachado"
                      ? `green`
                      : row.value === "Corte"
                      ? "#9C9A96"
                      : row.value === "Costura"
                      ? "#F16035"
                      : row.value === "Acabado"
                      ? "#6DC31F"
                      : row.value === "Almacen"
                      ? "#FF8C00"
                      : row.value === "Tejeduria"
                      ? "teal"
                      : row.value === "Tintoreria"
                      ? "purple"
                      : row.value === "Compras"
                      ? "blue"
                      : row.value === "Rotulado"
                      ? "#F5BC66"
                      : "#F5BC66",
                  fontWeight: "bold",
                  width: "100%",
                  borderRadius: "10px",
                  padding: "6px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {row.value}
              </span>
            ),
          },
        ],
      },
    ];

    return (
      <div className="content">
        <Modals
          data={data}
          title={`Pof: "${modalTitleProf}" | OP: "${modalTitleOp}"`}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={700}
          centered={true}
        >
          <ReactTable
            data={this.state.colors}
            columns={subComercial2}
            defaultPageSize={10}
          />
        </Modals>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos Comercial
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  noDataText="Sin Registros"
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={columns}
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  rowsText="filas"
                  minRows={0}
                  loading={loading}
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.otros}
                          columns={subComercial1}
                          noDataText="No hay informacion"
                          pageSize={row.original.otros.length}
                          showPagination={false}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0",
                          }}
                          minRows
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{ border: "1px solid black" }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={this.closeModal}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
