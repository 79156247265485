import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Alert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "../../../Firebase.js";
import Button from "components/CustomButton/CustomButton.jsx";

class EditDdpRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("ddp");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      isOpen: false,
      prof: "",
      selectedOption: null,
      ing: [],
      imgFT: null,
      imgHC: null,
      prueba: null,
      value: null,
      fichaTecnica: "",
      hojaConsumo: "",
      doc: "",
      ordenPedido: "",
      proforma: "",
    };
    this.changeHc = this.changeHc.bind(this);
    this.changeFt = this.changeFt.bind(this);
  }

  componentDidMount() {
    const { doc } = this.props.location.state;

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    this.ref
      .doc(doc)
      .get()
      .then((doc) => {
        let ordenPedido,
          fechaIngreso,
          fichaTecnica,
          hojaConsumo = null;
        ordenPedido = doc.data().op;
        fichaTecnica = doc.data().fichaTecnica ? doc.data().fichaTecnica : "";
        hojaConsumo = doc.data().hojaConsumo ? doc.data().hojaConsumo : "";
        if (doc.data().cFechaIngresoDdp) {
          let newFI = doc.data().cFechaIngresoDdp.split("/");
          arraymove(newFI, 0, 1);
          fechaIngreso = new Date(newFI.join("/"));
        } else {
          fechaIngreso = new Date();
        }
        this.setState({
          doc,
          ordenPedido,
          fechaIngreso,
          fichaTecnica,
          hojaConsumo,
        });
      });
  }

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };
  np;

  changeFt = (e) => {
    if (e.target.files[0]) {
      const imgFT = e.target.files[0];
      this.setState(() => ({ imgFT, fichaTecnica: imgFT.name.slice(0, -4) }));
    }
  };

  changeHc = (e) => {
    if (e.target.files[0]) {
      const imgHC = e.target.files[0];
      this.setState(() => ({ imgHC, hojaConsumo: imgHC.name.slice(0, -4) }));
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { imgFT } = this.state;
    let ft,
      hc = null;
    try {
      const uploadTask = this.storageRef
        .ref(`fichaTecnica/${imgFT.name}`)
        .put(imgFT);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          this.storageRef
            .ref("fichaTecnica")
            .child(imgFT.name)
            .getDownloadURL()
            .then((url) => {
              ft = url;
            });
        }
      );
    } catch (error) {}

    var { imgHC } = this.state;
    try {
      const uploadTask2 = this.storageRef
        .ref(`hojaConsumo/${imgHC.name}`)
        .put(imgHC);

      uploadTask2.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          this.storageRef
            .ref("hojaConsumo")
            .child(imgHC.name)
            .getDownloadURL()
            .then((url) => {
              hc = url;
            });
        }
      );
    } catch (error) {}

    let { fechaIngreso, fichaTecnica, hojaConsumo, doc } = this.state;
    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

    try {
      this.ref
        .doc(String(doc))
        .get()
        .then((res) => {
          let ddp = res.data();

          if (ft && hc) {
            ddp.fechaIngresoDdp = fechaIngreso;
            ddp.fichaTecnica = fichaTecnica;
            ddp.hojaConsumo = hojaConsumo;
            ddp.b = [ft];
            ddp.hc = [hc];
          } else if (ft) {
            ddp.fechaIngresoDdp = fechaIngreso;
            ddp.fichaTecnica = fichaTecnica;
            ddp.b = [ft];
          } else if (hc) {
            ddp.fechaIngresoDdp = fechaIngreso;
            ddp.hojaConsumo = hojaConsumo;
            ddp.hc = [hc];
          }

          try {
            this.ref
              .doc(String(doc))
              .update(ddp)
              .then(() => {
                this.setState({
                  ft: "",
                  hc: "",
                  selectedOption: "",
                });
                this.handleClickShowAlert();
                this.props.history.push("/Ddp/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
    } catch (error) {
      alert("Sucedio un error. Ingrese nuevamente el registro", error);
    }
    document.getElementsByClassName("reset-hc")[0].value = null;
    document.getElementsByClassName("reset-ft")[0].value = null;
  };

  render() {
    const hc = this.state.imgHC;
    const ft = this.state.imgFT;
    let disabled = true;
    hc || (ft && (disabled = false));
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ marginLeft: "15px", textAlign: "center" }}>
                  REGISTRO DDP
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FECHA
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaIngreso}
                      onChange={this.handleChange}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>

                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Label
                      className={"col-md-3"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.ordenPedido}
                    </Label>
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="ft">FICHA TECNICA</Label>
                    <Input
                      type="text"
                      name="ft"
                      id="ft"
                      className="col-md-3 pr-1"
                      value={this.state.fichaTecnica}
                      onChange={this.onChange}
                      autoComplete="off"
                      disabled
                    />
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="hc">HOJA DE CONSUMO</Label>
                    <Input
                      type="text"
                      name="hc"
                      id="hc"
                      className="col-md-3 pr-1"
                      value={this.state.hojaConsumo}
                      onChange={this.onChange}
                      autoComplete="off"
                      disabled
                    />
                  </FormGroup>

                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FICHA TECNICA
                  </Label>
                  <Input
                    type="file"
                    onChange={this.changeFt}
                    className="col-md-3 pr-1 reset-ft"
                    style={{ marginBottom: "20px" }}
                  />

                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    HOJA DE CONSUMO
                  </Label>
                  <Input
                    type="file"
                    onChange={this.changeHc}
                    className="col-md-3 pr-1 reset-hc"
                    style={{ marginBottom: "20px" }}
                  />

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESADO
                    </Alert>
                  </FormGroup>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        style={{ marginnTop: "20px" }}
                        type="submit"
                        round
                        disabled={disabled}
                      >
                        REGISTRAR
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditDdpRecord;
