import matchSorter from "match-sorter";
import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import firebase from "../Firebase";


const mainTelas = [
  {
    headerStyle: {
      backgroundColor: "#F5BC66",
      color: "white",
      fontWeight: "bold",
    },
    columns: [
      {
        Header: "Orden de Produccion",
        headerStyle: { fontWeight: "bold" },
        accessor: "op",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["op"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "cx",
        headerStyle: { fontWeight: "bold" },
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Estilo",
        accessor: "estilo",
        headerStyle: { fontWeight: "bold" },
        width: 120,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estilo"] }),
        filterAll: true,
      },
      {
        Header: "Descripcion",
        accessor: "descripcion",
        width: 120,
        headerStyle: { fontWeight: "bold" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["descripcion"] }),
        filterAll: true,
      },
      {
        Header: "Color",
        headerStyle: { fontWeight: "bold" },
        width: 150,
        accessor: "color",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["color"] }),
        filterAll: true,
      },
      {
        Header: "Cantidad",
        accessor: "cantidad",
        headerStyle: { fontWeight: "bold" },
        width: 80,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cantidad"] }),
        filterAll: true,
      },
      {
        Header: "Fecha Ingreso Telas",
        accessor: "fechaIngreso",
        width: 200,
        headerStyle: { fontWeight: "bold" },

        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["fechaIngreso"] }),
        filterAll: true,
      },
      {
        Header: "Estado",
        accessor: "estado",
        headerStyle: { fontWeight: "bold" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <div className="text-center">
            {row.value[0][0] === "Nuevo" ? (
              <Link
                to={{
                  pathname: "/Telas/RegistroDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#03A9F4",
                    width: "40%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : row.value[0][0] === "Rotulado" ? (
              <span
                style={{
                  backgroundColor: "#008000",
                  width: "50%",
                  textAlign: "center",
                  borderRadius: "10px",
                  margin: "0px",
                  padding: "5px",
                  color: "white",
                }}
              >
                {row.value[0][0]}
              </span>
            ) : (
              <Link
                to={{
                  pathname: "/Telas/RegistroActDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor:
                      row.value[0][0] === "Tejeduria"
                        ? "teal"
                        : row.value[0][0] === "Tintoreria"
                        ? "purple"
                        : row.value[0][0] === "Compras"
                        ? "blue"
                        : row.value[0][0] === "Rotulado"
                        ? "#F5BC66"
                        : "",
                    width: "50%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            )}
          </div>
        ),
      },
      {
        Header: "Editar",
        headerStyle: { fontWeight: "bold" },
        accessor: "estado",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <div className="text-center">
            {row.value[0][0] === "Nuevo" ? (
              <span />
            ) : (
              <Link
                to={{
                  pathname: "/Telas/RegistroEditar",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#D08E1F",
                    width: "40%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  Editar
                </Button>
              </Link>
            )}
          </div>
        ),
      },
    ],
  },
];

const mainCorte = [
  {
    headerStyle: { backgroundColor: "#9C9A96", color: "white" },
    columns: [
      {
        Header: "Fecha Telas",
        width: 110,
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaIngreso",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["fechaIngreso"] }),
        filterAll: true,
      },
      {
        Header: "Orden de Producción(OP)",
        width: 200,
        headerStyle: { fontWeight: "bold" },
        accessor: "op",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["op"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "cx",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Ficha Tecnica",
        headerStyle: { fontWeight: "bold" },
        width: 150,
        accessor: "fichaTecnica",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["fichaTecnica"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "b",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Estilo",
        headerStyle: { fontWeight: "bold" },
        width: 120,
        accessor: "estilo",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estilo"] }),
        filterAll: true,
      },
      {
        Header: "Color",
        headerStyle: { fontWeight: "bold" },
        accessor: "color",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["color"] }),
        filterAll: true,
      },
      {
        Header: "Cantidad PCP",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidad",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cantidad"] }),
        filterAll: true,
      },
      {
        Header: "Estado",
        headerStyle: { fontWeight: "bold" },
        accessor: "estado",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <div className="text-center">
            {row.value[0][0] === "Nuevo" ? (
              <Link
                to={{
                  pathname: "/Corte/RegistroDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#03A9F4",
                    width: "40%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : row.value[0][0] === "Falta" ? (
              <Link
                to={{
                  pathname: "/Corte/RegistroActDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#FF7e00 ",
                    width: "50%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : (
              <span
                style={{
                  backgroundColor: "green",
                  width: "100%",
                  borderRadius: "10px",
                  padding: "6px",
                  color: "white",
                }}
              >
                {row.value[0][0]}
              </span>
            )}
          </div>
        ),
      },
    ],
  },
];

const mainCostura = [
  {
    headerStyle: { backgroundColor: "#F16035", color: "white" },
    columns: [
      {
        Header: "Fecha Corte",
        headerStyle: { fontWeight: "bold" },
        width: 120,
        accessor: "corteFechaIngreso",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["corteFechaIngreso"] }),
        filterAll: true,
      },
      {
        Header: "Orden de Producción(OP)",
        headerStyle: { fontWeight: "bold" },
        accessor: "op",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["op"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "cx",
        headerStyle: { fontWeight: "bold" },
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Ficha Tecnica",
        width: 150,
        headerStyle: { fontWeight: "bold" },
        accessor: "ft",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["ft"] }),
        filterAll: true,
      },
      {
        Header: "",
        headerStyle: { fontWeight: "bold" },
        accessor: "b",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Color",
        headerStyle: { fontWeight: "bold" },
        width: 120,
        accessor: "color",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["color"] }),
        filterAll: true,
      },
      {
        Header: "Cantidad",
        headerStyle: { fontWeight: "bold" },
        width: 100,
        accessor: "cantCorte",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cantCorte"] }),
        filterAll: true,
      },
      {
        Header: "Estilo",
        headerStyle: { fontWeight: "bold" },
        accessor: "estilo",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estilo"] }),
        filterAll: true,
      },
      {
        Header: "Estado",
        headerStyle: { fontWeight: "bold" },
        accessor: "estado",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <div className="text-center">
            {row.value[0][0] === "Nuevo" ? (
              <Link
                to={{
                  pathname: "/Costura/RegistroDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#F16035",
                    width: "40%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : row.value[0][0] === "En Curso" ? (
              <Link
                to={{
                  pathname: "/Costura/RegistroActDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#FF7e00",
                    width: "50%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : (
              <span
                style={{
                  backgroundColor: "green",
                  width: "100%",
                  borderRadius: "10px",
                  padding: "6px",
                  color: "white",
                }}
              >
                {row.value[0][0]}
              </span>
            )}
          </div>
        ),
      },
    ],
  },
];

const mainAcabado = [
  {
    headerStyle: { backgroundColor: "#6DC31F", color: "white" },
    columns: [
      {
        Header: "Fecha Costura",
        headerStyle: { fontWeight: "bold" },
        width: 120,
        accessor: "costuraFechaIngreso",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["costuraFechaIngreso"] }),
        filterAll: true,
      },
      {
        Header: "Orden de Produccion(OP)",
        headerStyle: { fontWeight: "bold" },
        accessor: "op",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["op"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "cx",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Ficha Tecnica",
        headerStyle: { fontWeight: "bold" },
        width: 130,
        accessor: "ft",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["ft"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "b",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Estilo",
        headerStyle: { fontWeight: "bold" },
        width: 130,
        accessor: "estilo",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estilo"] }),
        filterAll: true,
      },
      {
        Header: "Color",
        headerStyle: { fontWeight: "bold" },
        accessor: "color",
        width: 130,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["color"] }),
        filterAll: true,
      },
      {
        Header: "Cantidad",
        headerStyle: { fontWeight: "bold" },
        width: 100,
        accessor: "cantCostura",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cantCostura"] }),
        filterAll: true,
      },
      {
        Header: "Estado",
        headerStyle: { fontWeight: "bold" },
        width: 132,
        accessor: "estado",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <div className="text-center">
            {row.value[0][0] === "Nuevo" ? (
              <Link
                to={{
                  pathname: "/Acabado/RegistroDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#F16035",
                    width: "50%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : row.value[0][0] === "En Curso" ? (
              <Link
                to={{
                  pathname: "/Acabado/RegistroActDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#FF7e00",
                    width: "60%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : (
              <span
                style={{
                  backgroundColor: "green",
                  width: "100%",
                  borderRadius: "10px",
                  padding: "6px",
                  color: "white",
                }}
              >
                {row.value[0][0]}
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "Comercial Despacho",
        headerStyle: { fontWeight: "bold" },
        width: 100,
        accessor: "fechaDespacho",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["fechaDespacho"] }),
        filterAll: true,
      },
      {
        Header: "Despachado",
        headerStyle: { fontWeight: "bold" },
        width: 120,
        accessor: "despacho",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["despacho"] }),
        filterAll: true,
        Cell: (row) => (
          <span
            style={{
              backgroundColor:
                row.value === "Completo"
                  ? "#29a00b"
                  : row.value === "Incompleto"
                  ? "#a00c01"
                  : "white",
              borderRadius: "10px",
              padding: "6px",
              color: "white",
            }}
          >
            {row.value}
          </span>
        ),
      },
    ],
  },
];

const mainAlmacen = [
  {
    headerStyle: { backgroundColor: "#008030", color: "white" },
    columns: [
      {
        Header: "Fecha",
        headerStyle: { fontWeight: "bold" },
        accessor: "acabadoFecha",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["acabadoFecha"] }),
        filterAll: true,
      },
      {
        Header: "Orden de Pedido",
        headerStyle: { fontWeight: "bold" },
        accessor: "op",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["op"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "cx",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Ficha Tecnica",
        headerStyle: { fontWeight: "bold" },
        accessor: "ft",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["ft"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "b",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Cliente",
        headerStyle: { fontWeight: "bold" },
        accessor: "cliente",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cliente"] }),
        filterAll: true,
      },
      {
        Header: "Estilo",
        headerStyle: { fontWeight: "bold" },
        accessor: "estilo",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estilo"] }),
        filterAll: true,
      },
      {
        Header: "Color",
        headerStyle: { fontWeight: "bold" },
        accessor: "color",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["color"] }),
        filterAll: true,
      },
      {
        Header: "Estado",
        headerStyle: { fontWeight: "bold" },
        accessor: "estado",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <div className="text-center">
            {row.value[0][0] === "Nuevo" ? (
              <Link
                to={{
                  pathname: "/Almacen/RegistroDirecto",
                  state: {
                    ordenPedido: row.value[0][1],
                    colorState: row.value[0][2],
                  },
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#F16035",
                    width: "50%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    margin: "0px",
                    padding: "5px",
                  }}
                >
                  {row.value[0][0]}
                </Button>
              </Link>
            ) : (
              <span
                style={{
                  backgroundColor: "green",
                  width: "100%",
                  borderRadius: "10px",
                  padding: "6px",
                  color: "white",
                }}
              >
                {row.value[0][0]}
              </span>
            )}
          </div>
        ),
      },
    ],
  },
];

function borrar(value) {
  firebase
    .firestore()
    .collection("pcp")
    .doc(value)
    .delete();
}

const columns = [
  {
    columns: [
      {
        Header: "Fecha de Ingreso",
        headerStyle: { fontWeight: "bold" },
        accessor: "cFechaIngreso",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cFechaIngreso"] }),
        filterAll: true,
      },
      {
        Header: "Factura Proforma",
        headerStyle: { fontWeight: "bold" },
        accessor: "prof",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["prof"] }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "a",
        width: 30,
        Cell: (row) => (
          <span
            style={{
              width: "10px",
              height: "100%",
            }}
          >
            <a href={row.value} target="_blank" rel="noopener noreferrer">
              <img
                src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                style={{ width: "16px" }}
                alt=""
              />
            </a>
          </span>
        ),
      },

      {
        Header: "Cliente",
        headerStyle: { fontWeight: "bold" },
        accessor: "cliente",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cliente"] }),
        filterAll: true,
      },
      {
        Header: "Fecha de Despacho",
        headerStyle: { fontWeight: "bold" },
        accessor: "cFechaEntrega",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["cFechaEntrega"] }),
        filterAll: true,
      },
      {
        Header: "Borrar",
        headerStyle: { fontWeight: "bold" },
        accessor: "value",
        Cell: (row) => (
          <div className="text-center">
            <Button
              style={{
                backgroundColor: "red",
                width: "30%",
                textAlign: "center",
                borderRadius: "10px",
                color: "white",
                margin: "0px",
                padding: "5px",
              }}
              onClick={(e) => borrar(row.value)}
              type="button"
            >
              Borrar
            </Button>
          </div>
        ),
      },
    ],
  },
];

const admin = [
  {
    columns: [
      {
        Header: "Fecha Inicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "cFechaIngreso",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [
              {
                threshold: matchSorter.rankings.CONTAINS,
                key: "cFechaIngreso",
              },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Factura Proforma",
        headerStyle: { fontWeight: "bold" },
        accessor: "prof",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "prof" }],
          }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "a",
        width: 30,
        Cell: (row) => (
          <span
            style={{
              width: "10px",
              height: "100%",
            }}
          >
            <a href={row.value} target="_blank" rel="noopener noreferrer">
              <img
                src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                style={{ width: "20px" }}
                alt=""
              />
            </a>
          </span>
        ),
      },
      {
        Header: "Cliente",
        headerStyle: { fontWeight: "bold" },
        accessor: "cliente",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: "cliente" },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Orden de Producción(OP)",
        headerStyle: { fontWeight: "bold" },
        width: 230,
        accessor: "op",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
          }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "cx",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "20px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Ficha Tecnica",
        headerStyle: { fontWeight: "bold" },
        accessor: "fichaTecnica",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: "fichaTecnica" },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "",
        accessor: "b",
        width: 30,
        Cell: (row) => (
          <div>
            {row.value && Array.isArray(row.value) && row.value.length ? (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "20px" }}
                    alt=""
                  />
                </a>
              </span>
            ) : null}
          </div>
        ),
      },

      {
        Header: "Fecha Despacho",
        headerStyle: { fontWeight: "bold" },
        accessor: "cFechaEntrega",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [
              {
                threshold: matchSorter.rankings.CONTAINS,
                key: "cFechaEntrega",
              },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Estado",
        accessor: "estado",
        width: 100,
        headerStyle: { fontWeight: "bold" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["estado"] }),
        filterAll: true,
        Cell: (row) => (
          <span
            style={{
              width: "10px",
              height: "100%",
              fontWeight: "bold",
              color: "white",
              borderRadius: "10px",
              padding: "6px",
              backgroundColor:
                row.value === "Nuevo"
                  ? "#03A9F4"
                  : row.value === "Pendiente"
                  ? "#ee6f05"
                  : row.value === "Completo"
                  ? "#6DC31F"
                  : "#004c00",
            }}
          >
            {row.value}
          </span>
        ),
      },
    ],
  },
];

const adminpcp = [
  {
    Header: "PCP",
    headerStyle: {
      backgroundColor: "#03A9F4",
      color: "white",
      fontWeight: "bold",
    },
    columns: [
      {
        Header: "Color",
        headerStyle: { fontWeight: "bold" },
        accessor: "color",
      },

      {
        Header: "Descripción",
        headerStyle: { fontWeight: "bold" },
        accessor: "descripcion",
      },
      {
        Header: "Cantidad",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidad",
      },
      {
        Header: "Estado",
        headerStyle: { fontWeight: "bold" },
        accessor: "estadoColor",
        Cell: (row) => (
          <span
            style={{
              backgroundColor:
                row.value === "PCP"
                  ? "#03A9F4"
                  : row.value === "Despachado"
                  ? `green`
                  : row.value === "Corte"
                  ? "#9C9A96"
                  : row.value === "Costura"
                  ? "#F16035"
                  : row.value === "Acabado"
                  ? "#6DC31F"
                  : row.value === "Almacen"
                  ? "#FF8C00"
                  : row.value === "Tejeduria"
                  ? "teal"
                  : row.value === "Tintoreria"
                  ? "purple"
                  : row.value === "Compras"
                  ? "blue"
                  : row.value === "Rotulado"
                  ? "#F5BC66"
                  : "#004c00",
              fontWeight: "bold",
              width: "100%",
              borderRadius: "10px",
              padding: "6px",
              color: "white",
            }}
          >
            {row.value}
          </span>
        ),
      },
    ],
  },
];

const telas = [
  {
    Header: "TELAS",
    headerStyle: {
      backgroundColor: "#F5BC66",
      color: "white",
      fontWeight: "bold",
    },
    columns: [
      {
        Header: "Fecha Inicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "tFechaIngreso",
      },
      {
        Header: "Estado",
        headerStyle: { fontWeight: "bold" },
        accessor: "tEstado",
        Cell: (row) => (
          <span
            style={{
              backgroundColor:
                row.value === "Tejeduria"
                  ? "teal"
                  : row.value === "Tintoreria"
                  ? "purple"
                  : row.value === "Compras"
                  ? "blue"
                  : row.value === "Rotulado"
                  ? "yellow"
                  : row.value === "Entregado"
                  ? "green"
                  : "white",
              width: "100%",
              borderRadius: "10px",
              padding: "6px",
              color: row.value === "Rotulado" ? "black" : "white",
            }}
          >
            {row.value}
          </span>
        ),
      },
    ],
  },
];

const cortes = [
  {
    Header: "CORTE",
    headerStyle: {
      backgroundColor: "#9C9A96",
      color: "white",
      fontWeight: "bold",
    },
    columns: [
      {
        Header: "Fecha Corte 1",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaIngreso",
      },
      {
        Header: "Servicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "servicio",
      },
      {
        Header: "N° Guia TexCross",
        headerStyle: { fontWeight: "bold" },
        accessor: "numGuiaTex",
      },
      {
        Header: "N° Guia Servicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "mumGuiaSer",
      },
      {
        Header: "Cantidad Corte 1",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidad",
      },
      {
        Header: "Fecha Corte 2",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaIngreso2",
      },
      {
        Header: "Cantidad Corte 2",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidad2",
      },
      {
        Header: "Total",
        headerStyle: { fontWeight: "bold" },
        accessor: "total",
      },
    ],
  },
];

const costura = [
  {
    Header: "COSTURA",
    headerStyle: {
      backgroundColor: "#F16035",
      color: "white",
      fontWeight: "bold",
    },
    columns: [
      {
        Header: "Fecha Inicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaIngreso",
      },
      {
        Header: "Servicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "servicio",
      },
      {
        Header: "N° Guia TexCross",
        headerStyle: { fontWeight: "bold" },
        accessor: "numGuiaTex",
      },
      {
        Header: "N° Guia Servicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "numGuiaSer",
      },
      {
        Header: "Cantidad Inicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidadIngresada",
      },
      {
        Header: "Fecha Salida",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaSalida",
      },
      {
        Header: "Cantidad Salida",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidadSalida",
      },
    ],
  },
];

const acabado = [
  {
    Header: "ACABADO",
    headerStyle: {
      backgroundColor: "#6DC31F",
      color: "white",
      fontWeight: "bold",
    },

    columns: [
      {
        Header: " Fecha Inicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaIngreso",
      },
      {
        Header: "Cantidad",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidadIngresada",
      },
      {
        Header: "Fecha Salida",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaSalida",
      },
      {
        Header: "Cantidad",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidadSalida",
      },
      {
        Header: "Fecha Liquidacion",
        headerStyle: { fontWeight: "bold" },
        accessor: "fechaSalidaLiq",
      },
      {
        Header: "Cantidad",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantidadSalidaLiq",
      },
    ],
  },
];

const almacen = [
  {
    Header: "Almacen",
    headerStyle: {
      backgroundColor: "#008030",
      color: "white",
      fontWeight: "bold",
    },
    columns: [
      {
        Header: "Fecha Inicio",
        headerStyle: { fontWeight: "bold" },
        accessor: "almacenFecha",
      },
      {
        Header: "Sobrante",
        headerStyle: { fontWeight: "bold" },
        accessor: "saldo",
      },
      {
        Header: "Recibido",
        headerStyle: { fontWeight: "bold" },
        accessor: "cantAlmacen",
      },
      {
        Header: "Caja",
        headerStyle: { fontWeight: "bold" },
        accessor: "caja",
      },
    ],
  },
];

export {
  columns,
  telas,
  almacen,
  cortes,
  costura,
  acabado,
  mainTelas,
  mainCorte,
  mainCostura,
  mainAcabado,
  mainAlmacen,
  admin,
  adminpcp,
};
