import Dashboard from "views/Dashboard/Dashboard.jsx";
import Consulta from "views/TableList/ConsultaTelas.jsx";
//import PrimaryNewTelaRecord from "views/UserPage/telas/PrimaryNewTelaRecord";
import NewTelaRecord from "views/UserPage/telas/NewTelaRecord";
import EditTelaRecord from "views/UserPage/telas/EditTelaRecord";
import SubRegistroEditarTela from "views/UserPage/telas/SubRegistroEditarTelas";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Telas/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  
  /*{
    path: "/Telas/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: PrimaryNewTelaRecord,
  },
  */
  {
    path: "/Telas/RegistroDirecto",
    name: "RegistroDirecto",
    component: NewTelaRecord,
    invisible: true,
  },
  {
    path: "/Telas/RegistroActDirecto",
    name: "RegistroActDirecto",
    component: EditTelaRecord,
    invisible: true,
  },
  {
    path: "/Telas/EditTelas",
    name: "RegistroEditar",
    component: SubRegistroEditarTela,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Telas/Procesos",
    name: "Registro",
  },
];
export default dashRoutes;
