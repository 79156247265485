import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, message, Button, Input } from "antd";
import "antd/dist/antd.css";

class UpdateCorteRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("corte");
    this.state = {
      op: null,
      color: null,
      label: null,
      value: null,
      fechaIngreso: new Date(),
      cantidad: "",
      lastCosturaId: null,
      cliente: "",
      comercialId: null,
      comercialIndex: null,
      isActive: false,
      cantCorte1: null,
      cantidadPcp: null,
    };
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const { colorState } = this.props.location.state;
    const { cantidadPCP, cantidad1 } = this.props.location.state;
    this.setState({ cantidadPcp: cantidadPCP, cantCorte1: cantidad1 });

    this.ref
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            value: doc.data().order,
            color: colorState,
            op: ordenPedido,
          });
        });
      });

    firebase
      .firestore()
      .collection("costura")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            lastCosturaId: doc.data().index,
          });
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().op === ordenPedido) {
            this.setState({
              cliente: doc.data().cliente,
            });
          }
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();
    let {
      fechaIngreso,
      cantidad,
      value,
      lastCosturaId,
      cliente,
      comercialId,
      comercialIndex,
      cantidadPcp,
      cantCorte1,
    } = this.state;

    const restanteResult = Number(cantidadPcp) - Number(cantCorte1);
    if (cantidad <= restanteResult) {
      this.setState({
        isActive: false,
      });
      let id = Number(lastCosturaId) + 1;

      fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");
      message.loading("Registrando...", 60);
      this.ref
        .doc(String(value))
        .get()
        .then((res) => {
          let corte = res.data();

          corte.otros[0] = {
            ...corte.otros[0],
            cantidad2: Number(cantidad),
            fechaIngreso2: fechaIngreso,
            total: Number(corte.otros[0].cantidad) + Number(cantidad),
          };

          if (corte.otros[0].total >= corte.cantidad) {
            corte.estado[0] = "Listo";
          } else {
            corte.estado[0] = "Falta";
          }

          let costura = {
            b: corte.b ? corte.b : "",
            cantCorte: corte.otros[0].total,
            cliente,
            color: corte.color,
            corteFechaIngreso: fechaIngreso,
            cx: corte.cx,
            descripcion: corte.descripcion,
            entrega: "",
            edit: [String(id), corte.op, corte.color, "Nuevo"],
            estado: ["Nuevo", corte.op, corte.color],
            ft: corte.ft ? corte.ft : "",
            index: String(id),
            op: corte.op,
            order: Number(id),
            otros: [],
            isEditable: true,
          };

          try {
            firebase
              .firestore()
              .collection("costura")
              .doc(String(id))
              .set(costura)
              .then(console.log)
              .catch(console.log);
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(String(comercialId))
              .get()
              .then((doc) => {
                let otros = doc.data().otros;
                otros[comercialIndex] = {
                  ...otros[comercialIndex],
                  corte: {
                    ...otros[comercialIndex].corte,
                    fechaIngreso2: corte.otros[0].fechaIngreso2,
                    cantidad2: corte.otros[0].cantidad2,
                    servicio: "TexCross",
                    total: corte.otros[0].total,
                  },
                };
                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .set({ otros }, { merge: true });
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            this.ref
              .doc(String(value))
              .update(corte)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registro agregado existosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  cantidad: "",
                  fechaIngreso: new Date(),
                });
                this.props.history.push("/Corte/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });

      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La cantidad: ${cantidad}, no debe ser mayor a la cantidad Secundaria: ${restanteResult}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const { cantidad } = this.state;

    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">Registro Corte</CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label className="font-weigth-bold">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">CANTIDAD</Label>
                    <Input
                      name="cantidad"
                      className="col-md-3 d-block"
                      value={cantidad}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto mt-3">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive || !cantidad ? true : false
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateCorteRecord;
