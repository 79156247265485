import firebase from '../../Firebase';
import {FirebaseCollections} from "enums";

const firestoreApi = firebase.firestore();

const getNewDdpId = async () =>{
    const [lastDdpRecord] = await firestoreApi
    .collection(FirebaseCollections.Ddp)
    .orderBy("order", "desc")
    .limit(1)
    .get()
    .then((querySnapshot) =>{
        let data = [];

        querySnapshot.forEach((doc) =>{
            data = [
                ...data,
                {
                    id: doc.id,
                    order: doc.data().order,
                },
            ];
        });
        return data;
    });
    return Object.keys(lastDdpRecord).length 
    ? lastDdpRecord.order + 1 
    : 1
}

const createDdpRecord = async(ddpData) =>{
    const docId = await getNewDdpId();

    try{
        const data = await firestoreApi
        .collection(FirebaseCollections.Ddp)
        .doc(String(docId))
        .set({...ddpData, index:String(docId), order:Number(docId)});

        return data;
    }catch (e){
        console.log(e.message);
    }
};

export {getNewDdpId, createDdpRecord};