import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "../../../Firebase";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

class AddClients extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("clientes");
    this.state = {
      isOpen2: false,
      selectedOption: null,
      clientes: [],
      add: "",
    };
  }

  componentDidMount() {
    this.ref.get().then((querySnapshot) => {
      var clientes = [];
      querySnapshot.forEach(function(doc) {
        clientes.push({
          label: doc.data().name,
          value: doc.id,
        });
      });
      this.setState({ clientes: clientes });
    });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let { add } = this.state;

    this.ref
      .add({
        name: add,
      })
      .then(() => {
        this.setState({
          add: "",
        });

        this.componentDidMount();
      })
      .catch((error) => {
        console.error("Error adding document: ", error.message);
      });
  };

  onSubmit2 = (e) => {
    e.preventDefault();
    let { selectedOption } = this.state;

    this.ref
      .doc(selectedOption.value)
      .delete()
      .then(() => {
        this.setState({
          selectedOption: "",
        });
        this.componentDidMount();
      });
  };

  render() {
    let { selectedOption, add } = this.state;
    const isInvalid = add === "";
    const isInvalidDelete = selectedOption === null;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0px auto" }}>
                <CardTitle>CLIENTES</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <form onSubmit={this.onSubmit2}>
                    <Label
                      for="clientes"
                      style={{
                        fontWeight: "bold",
                        margin: "30px 0 0px 15px",
                      }}
                    >
                      CLIENTES
                    </Label>
                    <Select
                      isSearchable={true}
                      options={this.state.clientes}
                      placeholder=""
                      className={"col-md-5"}
                      value={selectedOption}
                      onChange={this.selectHandleChange}
                    />
                    <Button
                      color="danger "
                      round
                      disabled={isInvalidDelete}
                      type="submit"
                      style={{
                        marginLeft: "60px",
                      }}
                    >
                      Borrar
                    </Button>
                  </form>
                </FormGroup>
                <form onSubmit={this.onSubmit}>
                  <FormGroup
                    style={{
                      marginLeft: "15px",
                      marginTop: "40px",
                      fontWeight: "bold",
                    }}
                  >
                    <Label>AGREGAR CLIENTE</Label>
                    <Input
                      type="text"
                      name="add"
                      id="add"
                      style={{ width: "210px" }}
                      value={add}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                    <Button
                      color="primary"
                      round
                      disabled={isInvalid}
                      type="submit"
                      style={{ marginLeft: "45px" }}
                    >
                      Agregar
                    </Button>
                  </FormGroup>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddClients;
