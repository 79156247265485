import Consulta from "views/TableList/ConsultaDdp.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import NewDdpRecord from "views/UserPage/ddp/NewDdpRecord";
import EditDdpRecord from "views/UserPage/ddp/EditDdpRecord";
import DirectNewDdpRecord from "views/UserPage/ddp/DirectNewDdpRecord";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Ddp/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  {
    path: "/Ddp/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: NewDdpRecord,
  },
  {
    path: "/Ddp/RegistroDirecto",
    name: "RegistroDirecto",
    component: DirectNewDdpRecord,
    invisible: true,
  },
  {
    path: "/Ddp/EditDdp",
    name: "EditPcp",
    component: EditDdpRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Ddp/Procesos",
    name: "Registro",
  },
];
export default dashRoutes;
