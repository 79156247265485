import firebase from "../../Firebase";
import { FirebaseCollections } from "enums";

const firestoreApi = firebase.firestore();
const firebaseStorage = firebase.storage();

const getClientList = async () => {
  const clientList = await firestoreApi
    .collection(FirebaseCollections.Clients)
    .get()
    .then((querySnapshot) => {
      let data = [];

      querySnapshot.forEach((doc) => {
        data = [
          ...data,
          {
            id: doc.id,
            client: doc.data(),
          },
        ];
      });

      return data;
    });

  return clientList;
};

const getNewComercialId = async () => {
  const [lastComercialRecord] = await firestoreApi
    .collection(FirebaseCollections.Comercial)
    .orderBy("order", "desc")
    .limit(1)
    .get()
    .then((querySnapshot) => {
      let data = [];

      querySnapshot.forEach((doc) => {
        data = [
          ...data,
          {
            id: doc.id,
            order: doc.data().order,
          },
        ];        
      });

      return data;       
    });

  return Object.keys(lastComercialRecord).length
    ? lastComercialRecord.order + 1
    : 1;
};

const uploadFile = async (file, ref) => {
  try {
    await firebaseStorage.ref(`${ref}/${file.name}`).put(file);
    const downloadUrl = await firebaseStorage
      .ref(ref)
      .child(file.name)
      .getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.error(error.message);
  }  
};

const createComercialRecord = async (comercialData) => {
  const docId = await getNewComercialId();

  try {
    const data = await firestoreApi
      .collection(FirebaseCollections.Comercial)
      .doc(String(docId))
      .set({ 
          ...comercialData,
          index: String(docId), 
          order: Number(docId), 
          value:String(docId) 
        });

    return data;
  } catch (e) {
    console.log(e.message);
  }
};

const updateComercialRecord = async (docId, comercial) =>{
  try {
    const comercialRecord = await firestoreApi
      .collection(FirebaseCollections.Comercial)
      .doc(String(docId))
      .update(comercial);
    return comercialRecord;
  } catch (error) {
    console.log(error.message)
  }
};

const updateComercialRecordFromTelas = async (
  op,
  color,
  estado,
  fechaIngreso
) =>{
  try {
    const comercialList = await firestoreApi
      .collection(FirebaseCollections.Comercial)
      .where("op","==",op)
      .get()

    comercialList.forEach(async (doc) =>{
      const colorList = doc.data().otros.map((colorItem) =>
        colorItem.color === color 
        ? {
          ...colorItem,
          telas:{
            tEstado: estado,
            tFechaIngreso: fechaIngreso,
          },
        }
        : colorItem
      );

      try{
        await updateComercialRecord(doc.id, {otros: colorList});
      }catch(e){
        console.error("Error actualizando pcp ",e.message);
      }
    });
  } catch (error) {
    
  }
};

export { 
  getClientList, 
  getNewComercialId, 
  uploadFile, 
  createComercialRecord,
  updateComercialRecordFromTelas,
  updateComercialRecord,
};
