import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Alert,
  FormGroup,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "../../../Firebase.js";
import { notification, message, DatePicker, Input as In } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "react-datepicker/dist/react-datepicker.css";

class EditCorteRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("corte");

    this.state = {
      fechaIngreso: new Date(),
      fechaIngreso2: null,
      cantidad: "",
      cantidadCorte2: null,
      isOpen: false,
      op: null,
      ft: null,
      descripcion: null,
      color: null,
      cantpcp: null,
      label: null,
      doc: null,
      total: null,
      seg: null,
      servicio: null,
      comercialIndex: null,
      comercialId: null,
      isActive: false,
      cantCorte1: null,
      cantCorte2: null,
      cantidadPcp: null,
    };
  }

  componentDidMount() {
    const {
      ordenPedido,
      colorState,
      doc,
      cantidadPCP,
      cantidad1,
      cantidad2,
    } = this.props.location.state;
    this.setState({
      cantCorte1: cantidad1,
      cantidadPcp: cantidadPCP,
      cantCorte2: cantidad2,
    });

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    this.ref
      .doc(String(doc))
      .get()
      .then((ref) => {
        const dataCorte = ref.data();
        let fechaIngreso2 = null;

        const cantidad = dataCorte.otros[0].cantidad;
        const cantidadCorte2 = dataCorte.otros[0].cantidad2;

        const newFI = dataCorte.otros[0].fechaIngreso.split("/");
        arraymove(newFI, 0, 1);
        const fechaIngreso = new Date(newFI.join("/"));
        if (dataCorte.otros[0].fechaIngreso2) {
          const newFI2 = dataCorte.otros[0].fechaIngreso2.split("/");
          arraymove(newFI2, 0, 1);
          fechaIngreso2 = new Date(newFI2.join("/"));
        }

        this.setState({
          cantidad,
          cantidadCorte2,
          fechaIngreso,
          fechaIngreso2,
          color: colorState,
          op: ordenPedido,
          doc,
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChange2 = (date) => {
    this.setState({
      fechaIngreso2: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      cantidad,
      cantidadCorte2,
      fechaIngreso,
      fechaIngreso2,
      doc,
      comercialId,
      cantidadPcp,
    } = this.state;

    const total = Number(cantidad) + Number(cantidadCorte2);
    if (total <= cantidadPcp) {
      this.setState({
        isActive: false,
      });
      message.loading("Registrando...", 60);
      const fechaIngresoFormat = moment(fechaIngreso).format("DD/MM/YYYY");
      const fechaIngresoFormat2 = moment(fechaIngreso2).format("DD/MM/YYYY");

      try {
        this.ref
          .doc(doc)
          .get()
          .then((res) => {
            const corte = res.data();

            corte.otros[0].cantidad = Number(cantidad) || "";
            corte.otros[0].cantidad2 = Number(cantidadCorte2) || "";
            corte.otros[0].fechaIngreso = fechaIngresoFormat || "";
            corte.otros[0].fechaIngreso2 = fechaIngresoFormat2 || "";
            corte.otros[0].servicio = "TexCross";
            corte.otros[0].total = total;

            try {
              firebase
                .firestore()
                .collection("comercial")
                .doc(String(comercialId))
                .get()
                .then((doc) => {
                  let otros = doc.data();

                  otros.otros.map((ids) => {
                    if (ids.color === corte.color) {
                      ids.corte = {
                        cantidad: Number(cantidad) || "",
                        cantidad2: Number(cantidadCorte2) || "",
                        fechaIngreso: fechaIngresoFormat || "",
                        fechaIngreso2: fechaIngresoFormat2 || "",
                        servicio: "TexCross",
                        total: total,
                      };
                    }
                    return ids;
                  });
                  try {
                    firebase
                      .firestore()
                      .collection("comercial")
                      .doc(comercialId)
                      .update(otros);
                  } catch (error) {
                    console.error("Error adding document: ", error.message);
                  }
                });
            } catch (error) {
              console.error("Error adding document: ", error.message);
            }

            try {
              firebase
                .firestore()
                .collection("ing")
                .where("op", "==", corte.op)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    let otros = doc.data().otros;
                    otros.forEach((item) => {
                      if (item.color === corte.color) {
                        item.status = ["Corte"];
                      }
                    });
                    firebase
                      .firestore()
                      .collection("ing")
                      .doc(doc.id)
                      .set({ otros }, { merge: true });
                  });
                });
            } catch (error) {
              console.error(error.message);
            }

            try {
              this.ref
                .doc(String(doc))
                .update(corte)
                .then((_) => {
                  this.setState({
                    cantidad2: "",
                    fechaIngreso2: new Date(),
                    selectedOption2: "",
                  });
                  message.destroy();
                  const args = {
                    message: "Registro agregado existosamente.",
                    top: 80,
                  };
                  notification.success(args);
                  this.props.history.push("/Corte/Procesos");
                });
            } catch (error) {
              console.error("Error adding document: ", error);
            }
          });
      } catch (error) {
        console.error("Error adding document: ", error);
      }
      setTimeout(() => {
        this.setState({
          isActive: false,
        });
      }, 6000);
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La suma de las cantidades es mayor a la cantidad principal: ${cantidadPcp}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const { op, color, cantidadCorte2, fechaIngreso2, cantidad } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>
                  ACTUALIZAR REGISTRO
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <In
                      className="col-md-5 d-block ml-3"
                      value={op}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <In
                      className="col-md-9 d-block ml-3"
                      value={color}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="cantidad"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      CANTIDAD
                    </Label>
                    <In
                      type="text"
                      name="cantidad"
                      id="cantidad"
                      className="col-md-3 d-block ml-3"
                      value={cantidad}
                      onChange={this.onChange}
                      required
                    />
                  </FormGroup>
                  {this.state.fechaIngreso2 ? (
                    <>
                      <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                        FECHA DE ENTREGA:
                      </Label>
                      <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                        <DatePicker
                          className="col-md-6 d-block"
                          selected={fechaIngreso2}
                          value={
                            this.state.fechaIngreso2
                              ? moment(this.state.fechaIngreso2, "DD/MM/YYYY")
                              : null
                          }
                          onChange={this.handleChange2}
                          format="DD/MM/YYYY"
                          locale={locale}
                          disabled={true}
                        />
                      </div>
                      <FormGroup>
                        <Label
                          for="cantidadCorte2"
                          style={{ marginLeft: "15px", fontWeight: "bold" }}
                        >
                          CANTIDAD CORTE 2
                        </Label>
                        <In
                          type="text"
                          name="cantidadCorte2"
                          id="cantidadCorte2"
                          className="col-md-3 d-block ml-3"
                          value={cantidadCorte2}
                          onChange={this.onChange}
                          required
                        />
                      </FormGroup>
                    </>
                  ) : null}

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        type="submit"
                        disabled={this.state.isActive}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditCorteRecord;
