import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from "../../Firebase";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "10px",
    backgroundColor: "#c8dad3",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class RegularTables extends React.Component {
  constructor() {
    super();
    this.state = {
      datos: [],
      op: [],
      ft: [],
      loading: true,
      modalIsOpen: false,
      password: "",
      ordenPedido: "",
      color: "",
      cantidad: null,
      doc: "",
      fechaIngreso: null,
      hc: [],
      costuraData: [],
    };
  }

  async loadData() {
    await firebase
      .firestore()
      .collection("corte")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        //let datos = [];
        const data = querySnapshot.docs.reduce((acc, data) => {
          const item = data.data();
          const itemIndex = acc.findIndex((accItem) => accItem.op === item.op);
          const cantidadPCP = item.cantidad;
          const {
            fechaIngreso,
            cantidad,
            fechaIngreso2,
            cantidad2,
            servicio,
            total,
          } =
            item.otros && item.otros.length
              ? item.otros[0]
              : {
                  fechaIngreso: null,
                  cantidad: null,
                  fechaIngreso2: null,
                  cantidad2: null,
                  servicio: null,
                  total: null,
                };
          const cantidad1 = cantidad;
          const fechaCorte2 = fechaIngreso2;
          const fechaCorte1 = fechaIngreso;

          let newItem = [{}];
          if (itemIndex >= 0) {
            newItem = {
              ...acc[itemIndex],
              otros: acc[itemIndex].otros.concat({
                color: item.color,
                cantidadPCP: cantidadPCP,
                estado: [item.estado.concat(cantidadPCP, cantidad1)],
                edit: [
                  item.index,
                  item.op,
                  item.color,
                  item.estado && item.estado.length ? item.estado[0] : "",
                  !item.isEditable,
                  cantidadPCP,
                  cantidad1,
                  cantidad2,
                ],
                index: item.estado.concat(item.index, !item.isEditable, [
                  item.estado.concat(cantidadPCP, cantidad1),
                ]),
                fechaCorte1: fechaCorte1,
                servicio: servicio,
                cantidad1: cantidad1,
                fechaIngreso2: fechaCorte2,
                cantidad2: cantidad2,
                total: total,
              }),
            };
            acc.splice(itemIndex, 1, newItem);
          } else {
            newItem = {
              fechaIngreso: item.fechaIngreso,
              op: item.op,
              ft: item.ft,
              b: item.b,
              hc: item.hc,
              hojaConsumo: item.hojaConsumo,
              descripcion: item.descripcion,
              otros: [
                {
                  color: item.color,
                  cantidadPCP: cantidadPCP,
                  estado: [item.estado.concat(cantidadPCP, cantidad1)],
                  edit: [
                    item.index,
                    item.op,
                    item.color,
                    item.estado && item.estado.length ? item.estado[0] : "",
                    !item.isEditable,
                    cantidadPCP,
                    cantidad1,
                    cantidad2,
                  ],
                  index: item.estado.concat(item.index, !item.isEditable, [
                    item.estado.concat(cantidadPCP, cantidad1),
                  ]),
                  fechaCorte1: fechaCorte1,
                  servicio: servicio,
                  cantidad1: cantidad1,
                  fechaIngreso2: fechaCorte2,
                  cantidad2: cantidad2,
                  total: total,
                },
              ],
            };
            acc.push(newItem);
          }

          return acc;
        }, []);
        this.setState({ data, loading: false });
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }
  componentDidMount() {
    this.loadData();
  }

  openModal(value) {
    this.setState({
      modalIsOpen: true,
      doc: value[0],
      ordenPedido: value[1],
      colorState: value[2],
      estado: value[3],
      isEditable: value[4],
      cantidadPCP: value[5],
      cantidad1: value[6],
      cantidad2: value[7],
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: false });
  }

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Corte/EditCorte",
          state: {
            doc: this.state.doc,
            ordenPedido: this.state.ordenPedido,
            colorState: this.state.colorState,
            estado: this.state.estado,
            isEditable: this.state.isEditable,
            cantidadPCP: this.state.cantidadPCP,
            cantidad1: this.state.cantidad1,
            cantidad2: this.state.cantidad2,
          },
        })
      : alert("Wrong password, try again");
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  borrar = (value) => {
    const op = value[1];
    const col = value[2];
    const id = value[3];
    const newEstado = value[value.length - 1]
      .slice(0, value[value.length - 1].length - 1)
      .concat(0)
      .map((item, idx) => (idx === 0 ? (item = "Nuevo") : item));

    const estado = value
      .slice(0, 3)
      .map((item, idx) => (idx === 0 ? (item = "Nuevo") : item));

    firebase
      .firestore()
      .collection("corte")
      .doc(id)
      .update({
        isEditable: true,
        estado,
        otros: [],
      });

    firebase
      .firestore()
      .collection("telas")
      .where("op", "==", op)
      .where("color", "==", col)
      .get()
      .then((qs) => {
        qs.forEach((doc) => {
          firebase
            .firestore()
            .collection("telas")
            .doc(doc.id)
            .update({
              isEditable: true,
            });
        });
      });

    const dataUpdated = this.state.data.map((item) => {
      if (item.op === op) {
        const otros = item.otros;
        const index = otros.findIndex((item) => item.color === col);
        otros.splice(index, 1, {
          ...otros[index],
          fechaCorte1: "",
          servicio: "",
          cantidad1: "",
          fechaIngreso2: "",
          cantidad2: "",
          total: "",
          estado: [newEstado],
          index: estado.concat(id),
          edit: [id, op, col, estado, true],
          otros: [],
        });
      }
      return item;
    });
    this.setState({ data: dataUpdated });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("comercial")
            .doc(doc.id)
            .update({
              otros: doc.data().otros.map((v) => {
                if (v.color === col) {
                  delete v.corte;
                  return v;
                }
                return v;
              }),
            });
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const status = "Rotulado";
          firebase
            .firestore()
            .collection("ing")
            .doc(doc.id)
            .update({
              otros: doc.data().otros.map((v) => {
                if (v.color === col) {
                  return {
                    ...v,
                    status: [status],
                  };
                }
                return v;
              }),
            });
        });
      });

    try {
      firebase
        .firestore()
        .collection("costura")
        .where("op", "==", op)
        .where("color", "==", col)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            firebase
              .firestore()
              .collection("costura")
              .doc(doc.id)
              .delete();
          });
        });
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  render() {
    const { data, loading } = this.state;
    const mainCorte = [
      {
        headerStyle: { backgroundColor: "#9C9A96", color: "white" },
        columns: [
          {
            Header: "Fecha Telas",
            width: 110,
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaIngreso",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "fechaIngreso",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Orden de Producción(OP)",
            width: 200,
            headerStyle: { fontWeight: "bold" },
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "cx",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Ficha Tecnica",
            headerStyle: { fontWeight: "bold" },
            width: 150,
            accessor: "ft",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "ft" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "b",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Hoja Consumo",
            headerStyle: { fontWeight: "bold" },
            width: 200,
            accessor: "hojaConsumo",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "hojaConsumo",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "hc",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Descripcion",
            headerStyle: { fontWeight: "bold" },
            width: 272,
            accessor: "descripcion",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "descripcion",
                  },
                ],
              }),
            filterAll: true,
          },
        ],
      },
    ];

    const subCorte = [
      {
        Header: "CORTE",
        headerStyle: {
          backgroundColor: "#9C9A96",
          color: "white",
          fontWeight: "bold",
        },
        columns: [
          {
            Header: "Color",
            headerStyle: { fontWeight: "bold" },
            width: 170,
            accessor: "color",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "color" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Cantidad PCP",
            headerStyle: { fontWeight: "bold" },
            width: 120,
            accessor: "cantidadPCP",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "cantidad" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Fecha Corte 1",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaCorte1",
          },
          {
            Header: "Servicio",
            headerStyle: { fontWeight: "bold" },
            accessor: "servicio",
          },
          {
            Header: "N° Guia TexCross",
            headerStyle: { fontWeight: "bold" },
            accessor: "numGuiaTex",
          },
          {
            Header: "N° Guia Servicio",
            headerStyle: { fontWeight: "bold" },
            accessor: "numGuiaSer",
          },
          {
            Header: "Cantidad Corte 1",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantidad1",
          },
          {
            Header: "Fecha Corte 2",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaIngreso2",
          },
          {
            Header: "Cantidad Corte 2",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantidad2",
          },
          {
            Header: "Total Corte",
            headerStyle: { fontWeight: "bold" },
            accessor: "total",
          },
          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold" },
            width: 120,
            accessor: "estado",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.STARTS_WITH,
                    key: "estado",
                  },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.value[0][0] === "Nuevo" ? (
                  <Link
                    to={{
                      pathname: "/Corte/RegistroDirecto",
                      state: {
                        ordenPedido: row.value[0][1],
                        colorState: row.value[0][2],
                        cantidadPCP: row.value[0][3],
                        cantidad1: row.value[0][4],
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#2D9CDB",
                        width: "80%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : row.value[0][0] === "En Curso" ? (
                  <Link
                    to={{
                      pathname: "/Corte/RegistroActDirecto",
                      state: {
                        ordenPedido: row.value[0][1],
                        colorState: row.value[0][2],
                        cantidadPCP: row.value[0][3],
                        cantidad1: row.value[0][4],
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#F2C94C",
                        width: "80%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value[0][0]}
                    </Button>
                  </Link>
                ) : row.value[0][0] === "Falta" ? (
                  <span
                    style={{
                      backgroundColor: "#EB5757",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "6px",
                      color: "white",
                    }}
                  >
                    {row.value[0][0]}
                  </span>
                ) : (
                  <span
                    style={{
                      backgroundColor: "#219653",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "6px",
                      color: "white",
                    }}
                  >
                    {row.value[0][0]}
                  </span>
                )}
              </div>
            ),
          },
          {
            Header: "Editar",
            accessor: "edit",
            width: 80,
            headerStyle: { fontWeight: "bold" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["edit"] }),
            filterAll: true,
            Cell: (row) => (
              <Button
                style={{
                  backgroundColor: "orange",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "10px",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0px",
                  padding: "5px",
                }}
                disabled={
                  row.value[3] === "Nuevo" || row.value[4] ? true : false
                }
                onClick={() => this.openModal(row.value)}
              >
                Editar
              </Button>
            ),
          },
          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold" },
            width: 80,
            accessor: "index",
            Cell: (row) => (
              <div className="text-center">
                {row.value ? (
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={
                      row.value[0] === "Nuevo" || row.value[4] ? true : false
                    }
                    onClick={(e) =>
                      window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                      this.borrar(row.value)
                    }
                    type="button"
                  >
                    Borrar
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Produccion de Procesos de Corte
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={mainCorte}
                  noDataText="Sin Registros"
                  loading={loading}
                  loadingText="POR FAVOR, ESPERE UN MOMENTO"
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  minRows={0}
                  rowsText="filas"
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.otros}
                          columns={subCorte}
                          noDataText="Ingrese un registro en Corte"
                          pageSize={row.original.otros.length}
                          showPagination={false}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0",
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{ border: "1px solid black" }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              autoFocus
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={(e) => this.closeModal(e)}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
