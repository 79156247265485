import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  admin,
  telas,
  adminpcp,
  acabado,
  almacen,
  cortes,
  costura
} from "variables/DataTable.jsx";
import firebase from "../../Firebase";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
class RegularTables extends React.Component {
  state = {
    datos: [],
    loading: true
  };

  componentDidMount() {
    let datos = [];

    firebase
      .firestore()
      .collection("comercial")
      .orderBy("order", "desc")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          let pcp = [];
          const getStatus = doc
            .data()
            .otros.every(item => item.acabado && item.acabado.fechaSalida);
          let estadoOp = doc.data().otros.length
            ? getStatus
              ? "Completo"
              : "Pendiente"
            : "Pendiente";
          doc.data().otros.forEach(v => {
            let estadoColor =
              v.acabado && v.acabado.fechaSalidaLiq
                ? "Liquidado"
                : v.acabado && v.acabado.fechaSalida
                ? "Despachado"
                : v.acabado
                ? "Acabado"
                : v.costura
                ? "Costura"
                : v.corte
                ? "Corte"
                : v.telas
                ? v.telas.tEstado
                : "PCP";

            pcp.push({
              color: v.color,
              descripcion: v.descripcion,
              cantidad: v.cant,
              estadoColor,
              telas: v.telas ? [v.telas] : [],
              corte: v.corte ? [v.corte] : [],
              costura: v.costura ? [v.costura] : [],
              acabado: v.acabado ? [v.acabado] : [],
              almacen: v.almacen ? [v.almacen] : []
            });
          });

          datos.push({
            cFechaIngreso: doc.data().cFechaIngreso,
            prof: doc.data().prof,
            a: doc.data().a,
            op: doc.data().op,
            cx: doc.data().b,
            fichaTecnica: doc.data().fichaTecnica,
            b: doc.data().f,
            cliente: doc.data().cliente,
            cFechaEntrega: doc.data().cFechaEntrega,
            pcp: pcp,
            estado: estadoOp
          });
        });
      })
      .then(_ => {
        this.setState({ datos, loading: false });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  }

  render() {
    const { datos, loading } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos General
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={datos}
                  filterable
                  noDataText="Registros Vacios"
                  loading={loading}
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  className="-highlight -striped"
                  columns={admin}
                  minRows={0}
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  rowsText="filas"
                  SubComponent={row => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.pcp}
                          columns={adminpcp}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0"
                          }}
                          defaultPageSize={row.original.pcp.length}
                          SubComponent={row => {
                            return (
                              <div style={{ padding: "10px" }}>
                                <ReactTable
                                  data={row.original.telas}
                                  columns={telas}
                                  defaultPageSize={1}
                                  showPagination={false}
                                  style={{
                                    textAlign: "center",
                                    margin: "10px 0"
                                  }}
                                />
                                <ReactTable
                                  data={row.original.corte}
                                  columns={cortes}
                                  defaultPageSize={1}
                                  showPagination={false}
                                  style={{
                                    textAlign: "center",
                                    margin: "10px 0"
                                  }}
                                />
                                <ReactTable
                                  data={row.original.costura}
                                  columns={costura}
                                  defaultPageSize={1}
                                  showPagination={false}
                                  style={{
                                    textAlign: "center",
                                    margin: "10px 0"
                                  }}
                                />
                                <ReactTable
                                  data={row.original.acabado}
                                  columns={acabado}
                                  defaultPageSize={1}
                                  showPagination={false}
                                  style={{
                                    textAlign: "center",
                                    margin: "10px 0"
                                  }}
                                />
                                <ReactTable
                                  data={row.original.almacen}
                                  columns={almacen}
                                  defaultPageSize={1}
                                  showPagination={false}
                                  style={{
                                    textAlign: "center",
                                    margin: "10px 0 40px 0"
                                  }}
                                />
                              </div>
                            );
                          }}
                          showPagination={false}
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RegularTables;
