import Dashboard from "views/Dashboard/Dashboard.jsx";
import ConsultaMain from "views/TableList/ConsultaMain.jsx";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/admin/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: ConsultaMain,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/admin/menu",
    name: "Menu",
  },
];
export default dashRoutes;
