import React from "react";
import moment from "moment";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Label,
  Alert,
  FormGroup,
} from "reactstrap";
import firebase from "Firebase.js";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButton/CustomButton.jsx";

registerLocale("es", es);

class NewCosturaRecord extends React.Component {
  constructor() {
    super();

    this.ref = firebase.firestore().collection("pcp");

    this.state = {
      fechaIngreso: new Date(),
      estilo: null,
      isOpen: false,
      color: null,
      servicio: "",
      numGuiaTex: "",
      cantIngresada: "",
      label: null,
      op: null,
      ft: null,
      cantpcp: null,
      total: null,
      value: null,
      descripcion: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {

    const { opState } = this.props.location.state;
    const { colorState } = this.props.location.state;

    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let label,
          value,
          op,
          ft,
          descripcion,
          color,
          cantpcp,
          total = null;
        querySnapshot.forEach(function(doc) {
          let corte = [];
          if (doc.data().corte) {
            for (let t = 0; t < doc.data().corte.length; t++) {
              if (doc.data().corte[t].seg === "costura") {
                corte.push({
                  value: doc.id,
                  op: doc.data().corte[t].op,
                  color: doc.data().corte[t].color,
                  descripcion: doc.data().corte[t].descripcion,
                  total: doc.data().corte[t].total,
                  cantpcp: doc.data().corte[t].cantpcp,
                  ft: doc.data().corte[t].ft,
                });
              }
            }
          }

          for (let i = 0; i < corte.length; i++) {
            if (opState === corte[i].op && colorState === corte[i].color) {
              label = corte[i].op + " " + corte[i].color;
              value = doc.id;
              descripcion = corte[i].descripcion;
              color = corte[i].color;
              op = corte[i].op;
              total = corte[i].total;
              cantpcp = corte[i].cantpcp;
              ft = corte[i].ft;
            }
          }
        });
        this.setState({
          label,
          value,
          descripcion,
          color,
          op,
          total,
          cantpcp,
          ft,
        });
      });
  }

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaIngreso,
      servicio,
      numGuiaTex,
      cantIngresada,
      op,
      ft,
      color,
      descripcion,
      total,
      cantpcp,
      value,
    } = this.state;

    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");
    this.ref
      .doc(value)
      .update({
        costura: firebase.firestore.FieldValue.arrayUnion({
          fechaIngreso,
          numGuiaTex,
          servicio,
          cantIngresada,
          color: color,
          op: op,
          descripcion,
          totalCorte: total,
          seg: "costura-update",
          cantpcp: Number(cantpcp),
          ft: ft,
        }),
      })
      .then((docRef) => {
        this.setState({
          fechaIngreso: new Date(),
          numGuiaTex: "",
          servicio: "",
          cantIngresada: "",
          selectedOption: "",
        });
        this.props.history.push("/Ingenieria/Procesos");
        this.handleClickShowAlert();
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  render() {
    const { servicio, numGuiaTex, cantIngresada } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>
                  REGISTRO COSTURA
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <Label
                    style={{ marginLeft: "15px", fontWeight: "bold" }}
                    for="fechaIngreso"
                  >
                    FECHA
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaIngreso}
                      onChange={this.handleChangeIngreso}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.op}
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.color}
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="servicio">SERVICIO</Label>
                    <Input
                      type="text"
                      name="servicio"
                      id="servicio"
                      className="col-md-4 pr-1"
                      value={servicio}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="numGuiaTex">N° GUIA TEXCROSS</Label>
                    <Input
                      type="text"
                      name="numGuiaTex"
                      id="numGuiaTex"
                      className="col-md-4 pr-1"
                      value={numGuiaTex}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantIngresada">CANTIDAD</Label>
                    <Input
                      type="text"
                      name="cantIngresada"
                      id="cantIngresada"
                      className="col-md-4 pr-1"
                      value={cantIngresada}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginnTop: "20px" }}
                        type="submit"
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewCosturaRecord;
