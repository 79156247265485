import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, Button, message, DatePicker, Input as In } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "react-datepicker/dist/react-datepicker.css";

class SubRegistroEditarTelas extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("telas");

    this.state = {
      fechaIngreso: new Date(),
      fechaSalida: new Date(),
      op: null,
      estado2: "",
      isOpen2: false,
      color: null,
      doc: null,
      lastCorteId: null,
      comercialIndex: null,
      comercialId: null,
      isActive: false,
    };
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const doc = this.props.location.state.docId;
    const colorState = this.props.location.state.color;

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    this.ref
      .doc(doc)
      .get()
      .then((ref) => {
        const dataTela = ref.data();
        const value = dataTela.order;
        const estado2 = dataTela.estado[0];

        const newFI = dataTela.fechaIngreso.split("/");
        arraymove(newFI, 0, 1);
        const fechaIngreso = new Date(newFI.join("/"));
        const newFS = dataTela.fechaSalida.split("/");
        arraymove(newFS, 0, 1);
        const fechaSalida = new Date(newFS.join("/"));
        this.setState({
          fechaIngreso,
          fechaSalida,
          value,
          estado2,
          op: ordenPedido,
          color: colorState,
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });

    firebase
      .firestore()
      .collection("corte")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            lastCorteId: doc.data().index,
            estadoCorte: doc.data().estado[0],
          });
        });
      });
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChange2 = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  selectHandleChange2 = (selectedOption2) => {
    this.setState({ selectedOption2 });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit2 = (e) => {
    e.preventDefault();
    message.loading("Registrando...", 60);
    const {
      estado2,
      fechaIngreso,
      fechaSalida,
      value,
      comercialId,
      lastCorteId,
      color: colorState,
      op: ordenPedido,
    } = this.state;

    this.setState({
      isActive: false,
    });

    let id = Number(lastCorteId) + 1;
    const fechaIngresof = moment(fechaIngreso).format("DD/MM/YYYY");
    const fechaSalidaf = moment(fechaSalida).format("DD/MM/YYYY");

    try {
      this.ref
        .doc(String(value))
        .get()
        .then((result) => {
          const tela = result.data();

          tela.fechaSalida = fechaSalidaf;
          tela.fechaIngreso = fechaIngresof;
          tela.estado[0] = estado2;

          if (estado2 === "Rotulado") {
            //modifcar en reglas
            let corte = {
              b: tela.b ? tela.b : null,
              cantidad: Number(tela.cantidad),
              color: tela.color,
              cx: tela.cx ? tela.cx : null,
              descripcion: tela.descripcion,
              edit: [String(id), tela.op, tela.color],
              estado: ["Nuevo", tela.op, tela.color],
              fechaIngreso: fechaSalidaf,
              fichaTecnica: tela.ft ? tela.ft : null,
              hc: tela.hc ? tela.hc : null,
              index: String(id),
              op: tela.op,
              order: Number(id),
              otros: [],
              isEditable: true,
            };
            firebase
              .firestore()
              .collection("corte")
              .doc(String(id))
              .set(corte)
              .then((error) => error && alert("Error de crear corte"));
          }

          if (estado2 !== "Rotulado") {
            try {
              firebase
                .firestore()
                .collection("corte")
                .where("op", "==", ordenPedido)
                .where("color", "==", colorState)
                .get()
                .then((queryS) => {
                  queryS.forEach((doc) => {
                    firebase
                      .firestore()
                      .collection("corte")
                      .doc(doc.id)
                      .delete();
                  });
                });
            } catch (error) {
              console.error("Error deleting document: ", error);
            }
          }

          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(String(comercialId))
              .get()
              .then((doc) => {
                let otros = doc.data();

                otros.otros.map((ids) => {
                  if (ids.color === tela.color) {
                    ids.telas = {
                      tEstado: estado2 || "",
                      tFechaIngreso: fechaIngresof || "",
                    };
                  }
                  return ids;
                });
                try {
                  firebase
                    .firestore()
                    .collection("comercial")
                    .doc(comercialId)
                    .update(otros);
                } catch (error) {
                  console.error("Error adding document: ", error.message);
                }
              });
          } catch (error) {
            console.error("Error adding document: ", error.message);
          }

          try {
            firebase
              .firestore()
              .collection("ing")
              .where("op", "==", tela.op)
              .get()
              .then((querySnapshot) => {
                let otros,
                  idx = null;
                querySnapshot.forEach((doc) => {
                  idx = doc.id;
                  otros = doc.data().otros.map((item) => {
                    if (item.color === tela.color) {
                      item.status = [estado2 || ""];
                      item.fecha = fechaIngresof || "";
                    }
                    return item;
                  });
                });
                return { otros, idx };
              })
              .then(({ idx, otros }) => {
                firebase
                  .firestore()
                  .collection("ing")
                  .doc(String(idx))
                  .set({ otros }, { merge: true });
              });
          } catch (error) {
            console.error(error);
          }

          try {
            this.ref
              .doc(String(value))
              .update(tela)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registro agregado exitosamente.",
                  top: 80,
                };
                notification.success(args);
                this.props.history.push("/Telas/Procesos");
                this.handleClickShowAlert2();
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    this.setState({
      isActive: true,
    });
  };

  render() {
    const { estado2, fechaSalida, op, color } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ marginLeft: "15px" }}>Actualizar</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit2}>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      Orden de Pedido
                    </Label>
                    <In
                      className="col-md-8 d-block ml-3"
                      value={op}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <In
                      className="col-md-8 d-block ml-3"
                      value={color}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="fechaSalida"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      FECHA ENTREGA:
                    </Label>
                    <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                      <DatePicker
                        className="col-md-7 d-block"
                        selected={fechaSalida}
                        value={
                          this.state.fechaSalida
                            ? moment(this.state.fechaSalida, "DD/MM/YYYY")
                            : null
                        }
                        onChange={this.handleChange2}
                        format="DD/MM/YYYY"
                        locale={locale}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                      for="estado2"
                    >
                      Estado
                    </Label>
                    <Input
                      type="select"
                      name="estado2"
                      id="estado2"
                      style={{ marginLeft: "15px" }}
                      className={"col-md-4"}
                      onChange={this.onChange}
                      value={estado2}
                      required
                    >
                      <option value="">--Seleccione--</option>
                      <option value="Tejeduria">Tejeduria</option>
                      <option value="Tintoreria">Tintoreria</option>
                      <option value="Compras">Compras</option>
                      <option value="Rotulado">Rotulado</option>
                      <option value="Entregado">Entregado</option>
                    </Input>
                  </FormGroup>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      Registro ingresado
                    </Alert>
                  </FormGroup>
                  <Row style={{ marginTop: "70px" }}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        type="primary"
                        style={{ marginnTop: "20px" }}
                        onClick={this.onSubmit2}
                        disabled={this.state.isActive}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SubRegistroEditarTelas;
