import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Alert,
  Input,
  FormGroup,
} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "Firebase.js";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class EditCorteRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("pcp");

    this.state = {
      op: null,
      color: null,
      label: null,
      value: null,
      fechaIngreso2: new Date(),
      cantidad2: "",
      isOpen2: false,
      numGuiaSer: "",
    };
  }

  componentDidMount() {

    const { opState } = this.props.location.state;
    const { colorState } = this.props.location.state;

    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let label,
          value,
          op,
          color = null;
        querySnapshot.forEach(function(doc) {
          let corte = 0;
          if (doc.data().corte) corte = doc.data().corte;

          for (let i = 0; i < corte.length; i++) {
            if (corte[i].op === opState && corte[i].color === colorState) {
              label = corte[i].op + " " + corte[i].color;
              value = doc.id;
              color = corte[i].color;
              op = corte[i].op;
            }
          }
        });

        this.setState({ label, value, color, op });
      });
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChange2 = (date) => {
    this.setState({
      fechaIngreso2: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit2 = (e) => {
    e.preventDefault();
    let { fechaIngreso2, cantidad2, op, color, numGuiaSer, value } = this.state;

    fechaIngreso2 = moment(fechaIngreso2).format("DD/MM/YYYY");

    this.ref
      .doc(value)
      .get()
      .then((res) => {
        let corte = res.data().corte;
        var nuevo = [];
        nuevo = corte.map((value) => {
          if (value.op === op && value.color === color) {
            value.cantidadCorte2 = Number(cantidad2);
            value.fechaIngreso2 = fechaIngreso2;
            value.numGuiaSer = numGuiaSer;
            value.total = Number(cantidad2);
            value.seg = "costura";
          }
          return value;
        });

        try {
          this.ref
            .doc(value)
            .update({ corte: nuevo })
            .then((docRef) => {
              this.setState({
                cantidad2: "",
                numGuiaSer: "",
                fechaIngreso2: new Date(),
                selectedOption2: "",
              });
              this.handleClickShowAlert2();
              this.props.history.push("/Ingenieria/Procesos");
            });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });
  };

  render() {
    const { cantidad2, numGuiaSer } = this.state;
    const isInvalid = cantidad2 === "" || numGuiaSer === "";
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>
                  REGISTRO LLEGADA CORTE
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit2}>
                  <FormGroup>
                    <Label
                      for="fechaIngreso2"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      FECHA:
                    </Label>
                    <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                      <DatePicker
                        selected={this.state.fechaIngreso2}
                        onChange={this.handleChange2}
                        dateFormat="d MMMM, yyyy"
                        locale="es"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="fechaIngreso"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="p2"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.op}
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="p2"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      COLOR
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.color}
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="numGuiaSer">N° GUIA SERVICIO</Label>
                    <Input
                      type="text"
                      name="numGuiaSer"
                      id="numGuiaSer"
                      className="col-md-3 pr-1"
                      value={numGuiaSer}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantidad2">CANTIDAD</Label>
                    <Input
                      type="text"
                      name="cantidad2"
                      id="cantidad2"
                      className="col-md-4 pr-1"
                      value={cantidad2}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                      autoFocus
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        type="submit"
                        disabled={isInvalid}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditCorteRecord;
