import Consulta from "views/TableList/ConsultaDdp.jsx";
import EditDdpRecord from "views/UserPage/ddp/EditDdpRecord";
import DirectNewDdpRecord from "views/UserPage/ddp/DirectNewDdpRecord";

var dashRoutes = [
  {
    path: "/Ddp/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  {
    path: "/Ddp/RegistroDirecto",
    name: "RegistroDirecto",
    component: DirectNewDdpRecord,
    invisible: true,
  },

  {
    path: "/Ddp/EditDdp",
    name: "EditPcp",
    component: EditDdpRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Ddp/Procesos",
    name: "Registro",
  },
];
export default dashRoutes;
