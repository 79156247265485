import firebase from "../../Firebase.js";
const firebaseStorage = firebase.storage();

const handleChangeSwift = (
  info,
  fileListSw,
  secondaryFileListSw,
  handleState,
  handleLoading
) => {
  handleState(fileListSw, secondaryFileListSw, info);

  if (info.file.status === "uploding") {
    handleLoading(true);
  }

  if (info.file.status === "done") {
    handleLoading(false);
  }
};

const customUploadSwift = async ({
  onError,
  onSuccess,
  file,
  folder,
  stateList,
  nameState,
  notification,
  handleUploadfile,
}) => {
  const storager = await firebaseStorage.ref();
  const pdfImgFile = storager.child(`${folder}/${file.name}`);
  const regex = /^[^\.]*(.pdf|.jpeg|.jpg|.png|.img|.tiff|.bmp)\b/gm;
  if (regex.test(file.name) && !stateList.includes(file.name.split(".")[0])) {
    try {
      const pdfImg = await pdfImgFile.put(file);
      const url = await firebaseStorage
        .ref(folder)
        .child(file.name)
        .getDownloadURL();

      handleUploadfile(file, nameState);
      onSuccess(null, { ...pdfImg, url });
    } catch (e) {
      onError(e.message);
    }
  } else {
    const msg = {
      message: `El archivo: ${
        file.name.split(".")[0]
      } no es nombre valido, No incluir punto en el nombre.`,
      top: 80,
    };
    notification.error(msg);
    onError(msg);
  }
};

export { handleChangeSwift, customUploadSwift };
