import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import {
  Upload,
  Icon,
  message,
  notification,
  Select,
  Button,
  Input as In,
} from "antd";
import firebase from "../../../Firebase.js";

const { Option } = Select;

class NewDdpRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("ing");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      fechaIngresoDdp: new Date(),
      op: [""],
      isOpen: false,
      imgHC: null,
      selectedOption: null,
      ing: [],
      imgFT: null,
      label: null,
      value: null,
      ft: null,
      hc: null,
      lastIndexTelas: null,
      isActive: false,
      selectedFile: null,
      selectedFileList: [],
      pdfUrl: "",
      filelistFT: null,
      filelistHC: null,
      uploading: false,
      urlFT: [],
      urlHC: [],
      fichTec: [],
      hojaCon: [],
    };
    this.changeHc = this.changeHc.bind(this);
    this.changeFt = this.changeFt.bind(this);
  }

  componentDidMount() {
    this.ref
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        let op_list = [];
        querySnapshot.forEach(function(doc) {
          op_list.push({ value: doc.id, label: doc.data().op });
        });
        let op = [];
        op_list.forEach((item, val) => {
          op.push({
            value: item.value,
            label: item.label,
          });
          this.setState({ op });
        });
      });

    firebase
      .firestore()
      .collection("ddp")
      .orderBy("order", "desc")
      .get()
      .then((query) => {
        const ftecnica = [];
        const hconsumo = [];
        let urlFT = null;
        let urlHC = null;
        query.forEach((doc) => {
          if (doc.data().fichaTecnica) {
            ftecnica.push(doc.data().fichaTecnica);
          }
          if (doc.data().hojaConsumo) {
            hconsumo.push(doc.data().hojaConsumo);
          }
          urlFT = doc.data().b[null];
          urlHC = doc.data().hc[null];
        });
        this.setState({
          urlFT: urlFT,
          urlHC: urlHC,
          fichTec: ftecnica,
          hojaCon: hconsumo,
        });
      });
  }

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChangeIngresoDDP = (date) => {
    this.setState({
      fechaIngresoDdp: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };
  np;

  changeFt = (e) => {
    if (e.target.files[0]) {
      const imgFT = e.target.files[0];
      this.setState(() => ({ imgFT, ft: imgFT.name.slice(0, -4) }));
    }
  };

  changeHc = (e) => {
    if (e.target.files[0]) {
      const imgHC = e.target.files[0];
      this.setState(() => ({ imgHC, hc: imgHC.name.slice(0, -4) }));
    }
  };

  handleChangeFT = (info) => {
    let fileListFT = [...info.fileList];

    fileListFT = fileListFT.slice(-1);

    fileListFT = fileListFT.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelistFT: info.fileList, fileListFT });

    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUploadFT = async ({ onError, onSuccess, file }) => {
    this.setState({ uploading: true });
    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`fichaTecnica/${file.name}`);

    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("fichaTecnica")
        .child(file.name)
        .getDownloadURL();
      this.setState(() => ({
        file,
        ft: file.name.slice(0, -4),
        uploading: false,
      }));
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  handleChangeHC = (info) => {
    let fileListHC = [...info.fileList];

    fileListHC = fileListHC.slice(-1);

    fileListHC = fileListHC.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelistHC: info.fileList, fileListHC });
    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUploadHC = async ({ onError, onSuccess, file }) => {
    this.setState({ uploading: true });
    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`hojaConsumo/${file.name}`);

    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("hojaConsumo")
        .child(file.name)
        .getDownloadURL();
      this.setState(() => ({
        file,
        hc: file.name.slice(0, -4),
        uploading: false,
      }));
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      fechaIngreso,
      fechaIngresoDdp,
      selectedOption,
      op,
      ft,
      hc,
      urlFT,
      urlHC,
    } = this.state;

    this.setState({
      isActive: true,
    });

    const fIngreso = moment(fechaIngreso).format("DD/MM/YYYY");
    const fIngresoDdp = moment(fechaIngresoDdp).format("DD/MM/YYYY");

    const fichaTecnicaUrl =
      this.state.filelistFT && this.state.filelistFT.length
        ? this.state.filelistFT[0].xhr.url
        : urlFT;

    const hojaConsumoUrl =
      this.state.filelistHC && this.state.filelistHC.length
        ? this.state.filelistHC[0].xhr.url
        : urlHC;

    const opSelected = op.filter((item) => item.value === selectedOption);
    const opName = opSelected[0].label;
    message.loading("Registrando...", 60);
    try {
      this.ref
        .doc(String(selectedOption))
        .get()
        .then((res) => {
          let ing_data = res.data();
          if (ft && hc) {
            ing_data["fechaIngresoDdp"] = fIngresoDdp || "";
            ing_data.b = [fichaTecnicaUrl || ""];
            ing_data.hc = [hojaConsumoUrl || ""];
            ing_data["fichaTecnica"] = ft || "";
            ing_data["hojaConsumo"] = hc || "";
          } else if (ft) {
            ing_data["fechaIngresoDdp"] = fIngresoDdp || "";
            ing_data.b = [fichaTecnicaUrl || ""];
            ing_data["fichaTecnica"] = ft || "";
          } else if (hc) {
            ing_data["fechaIngresoDdp"] = fIngresoDdp || "";
            ing_data.hc = [hojaConsumoUrl || ""];
            ing_data["hojaConsumo"] = hc || "";
          }

          try {
            this.ref
              .doc(String(selectedOption))
              .set(ing_data, { merge: true })
              .then((docRef) => {
                this.setState({
                  ft: "",
                  hc: "",
                  selectedOption: "",
                });
                this.handleClickShowAlert();
                this.componentDidMount();
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            firebase
              .firestore()
              .collection("comercial")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                let comercialDocId = null;
                querySnapshot.forEach((doc) => {
                  comercialDocId = doc.id;
                });
                const data = {
                  cFechaIngreso: fIngreso || "",
                  fichaTecnica: ft || "",
                  f: [fichaTecnicaUrl || ""],
                };

                try {
                  firebase
                    .firestore()
                    .collection("comercial")
                    .doc(comercialDocId)
                    .update(data);
                } catch (e) {
                  console.log(e);
                }
              });
          } catch (error) {
            console.log(error.message);
          }

          try {
            firebase
              .firestore()
              .collection("ddp")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                let ddpDocId = null;
                querySnapshot.forEach((doc) => {
                  ddpDocId = doc.id;
                });
                const data = {
                  fechaIngresoDdp: fIngresoDdp,
                  fichaTecnica: ft || "",
                  b: [fichaTecnicaUrl || ""],
                  hojaConsumo: hc || "",
                  hc: [hojaConsumoUrl || ""],
                  estado: [
                    fichaTecnicaUrl && hojaConsumoUrl ? "Listo" : "En curso",
                    opName,
                  ],
                };
                try {
                  firebase
                    .firestore()
                    .collection("ddp")
                    .doc(ddpDocId)
                    .update(data);
                } catch (e) {
                  console.log(e);
                }
              });
          } catch (e) {
            console.log(e);
          }

          try {
            firebase
              .firestore()
              .collection("telas")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const telaData = {
                    ...doc.data(),
                    ft: ft || "",
                    b: fichaTecnicaUrl ? [fichaTecnicaUrl] : [],
                    hojaConsumo: hc || "",
                    hc: hojaConsumoUrl ? [hojaConsumoUrl] : [],
                  };
                  firebase
                    .firestore()
                    .collection("telas")
                    .doc(doc.id)
                    .update(telaData);
                });
              });
          } catch (error) {
            console.log(error.message);
          }

          try {
            firebase
              .firestore()
              .collection("corte")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const corteData = {
                    ...doc.data(),
                    ft: ft || "",
                    b: fichaTecnicaUrl ? [fichaTecnicaUrl] : [],
                    hojaConsumo: hc || "",
                    hc: hojaConsumoUrl ? [hojaConsumoUrl] : [],
                  };
                  firebase
                    .firestore()
                    .collection("corte")
                    .doc(doc.id)
                    .update(corteData);
                });
              });
          } catch (error) {
            console.log(error.message);
          }

          try {
            firebase
              .firestore()
              .collection("costura")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const costuraData = {
                    ...doc.data(),
                    ft: ft || "",
                    b: fichaTecnicaUrl ? [fichaTecnicaUrl] : [],
                  };
                  firebase
                    .firestore()
                    .collection("costura")
                    .doc(doc.id)
                    .update(costuraData);
                });
              });
          } catch (error) {
            console.log(error.message);
          }

          try {
            firebase
              .firestore()
              .collection("acabados")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const acabadosData = {
                    ...doc.data(),
                    ft: ft || "",
                    b: fichaTecnicaUrl ? [fichaTecnicaUrl] : [],
                  };
                  firebase
                    .firestore()
                    .collection("acabados")
                    .doc(doc.id)
                    .update(acabadosData);
                });
              });
          } catch (error) {
            console.log(error.message);
          }

          try {
            firebase
              .firestore()
              .collection("almacen")
              .where("op", "==", opName)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const almacenData = {
                    ...doc.data(),
                    ft: ft || "",
                    b: fichaTecnicaUrl ? [fichaTecnicaUrl] : [],
                  };
                  firebase
                    .firestore()
                    .collection("almacen")
                    .doc(doc.id)
                    .update(almacenData);
                });
              });
          } catch (error) {
            console.log(error.message);
          }

          try {
            this.ref
              .doc(String(selectedOption))
              .update(ing_data)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registro agregado existosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  isActive: false,
                });
                this.props.history.push("/Ddp/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
    } catch (error) {
      alert("Sucedio un error. Ingrese nuevamente el registro", error);
      console.log(error.message);
      console.log(error);
    }
  };

  render() {
    const { ft, hc, selectedOption, loading, pdfUrl } = this.state;

    const isInvalid = !(ft || hc);

    const uploadButton = (
      <div>
        <Button
          style={{ display: "block", textAlign: "left", marginLeft: "15px" }}
        >
          <Icon type={loading ? "loading" : "plus"} />
          Seleccionar Archivo
        </Button>
      </div>
    );
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ marginLeft: "15px", textAlign: "center" }}>
                  REGISTRO DDP
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <FormGroup className="ml-3 mt-3">
                    <Label className="font-weight-bold">
                      ORDEN DE PRODUCCION
                    </Label>
                    <Select
                      showSearch={true}
                      className="col-md-3 d-block"
                      value={selectedOption}
                      onChange={this.selectHandleChange}
                      placeholder="Eliga Orden de produccion"
                    >
                      {this.state.op.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                  <Row>
                    <Label
                      style={{ marginLeft: "30px" }}
                      className="font-weight-bold"
                      for="ft"
                    >
                      FICHA TECNICA
                    </Label>
                  </Row>
                  <In
                    name="ft"
                    className="col-md-3 pr-1 ml-3"
                    value={ft}
                    onChange={this.onChange}
                    autoComplete="off"
                  />

                  <Row>
                    <Label
                      style={{ marginLeft: "30px" }}
                      className="font-weight-bold mt-3"
                      for="hc"
                    >
                      HOJA DE CONSUMO
                    </Label>
                  </Row>
                  <In
                    name="hc"
                    className="col-md-3 pr-1 ml-3"
                    value={hc}
                    onChange={this.onChange}
                    autoComplete="off"
                  />

                  <Row>
                    <Label
                      style={{ marginLeft: "30px", fontWeight: "bold" }}
                      className="mt-3"
                    >
                      FICHA TECNICA
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() => this.setState({ filelistFT: null, ft: "" })}
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangeFT}
                    customRequest={this.customUploadFT}
                    fileList={this.state.fileListFT}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  <Row>
                    <Label
                      className="mt-4"
                      style={{ marginLeft: "30px", fontWeight: "bold" }}
                    >
                      HOJA DE CONSUMO
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() => this.setState({ filelistHC: null, hc: "" })}
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangeHC}
                    customRequest={this.customUploadHC}
                    fileList={this.state.fileListHC}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={this.onSubmit}
                        type="primary"
                        style={{ marginnTop: "20px" }}
                        round
                        disabled={
                          this.state.isActive ||
                          this.state.uploading ||
                          isInvalid
                        }
                      >
                        REGISTRAR
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewDdpRecord;
