import Dashboard from "views/Dashboard/Dashboard.jsx";
import Consulta from "views/TableList/ConsultaCostura.jsx";
import {
  DirectNewCosturaRecord,
  EditCosturaRecord,
  UpdateCosturaRecord,
  //NewCosturaRecord,
} from "views/UserPage/costura";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Costura/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  /*
  {
    path: "/Costura/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: NewCosturaRecord,
  },
  */
  {
    path: "/Costura/RegistroDirecto",
    name: "Registro Directo",
    component: DirectNewCosturaRecord,
    invisible: true,
  },
  {
    path: "/Costura/RegistroActDirecto",
    name: "Registro Directo",
    component: UpdateCosturaRecord,
    invisible: true,
  },
  {
    path: "/Costura/EditCostura",
    name: "Edit Costura",
    component: EditCosturaRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Costura/Procesos",
    name: "Registro",
  },
];

export default dashRoutes;
