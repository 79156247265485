import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from "../../Firebase";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { Modal as Modals, Button as Buttons } from "antd";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class RegularTables extends React.Component {
  constructor() {
    super();
    this.state = {
      datos: [],
      page: null,
      prof: [],
      ft: [],
      op: [],
      hc: [],
      loading: true,
      modalIsOpen: false,
      descripcion: "",
      color: [],
      cantidad: [],
      fechaIngreso: "",
      ordenPedido: "",
      password: "",
      telaData: [],
      visible: false,
      modalTitleOp: "",
      modalTitleProf: "",
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async loadData() {
    await firebase
      .firestore()
      .collection("ing")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        //const datos = [];
        const data = querySnapshot.docs.reduce((acc, data) => {
          const colores = data.data().otros.map((v) => v.color);
          const item = data.data();
          const itemIndex = acc.findIndex(
            (accItem) => accItem.prof === item.prof
          );
          const estado =
            item.estado && item.estado.length ? item.estado[0] : "";
          const listaOtros = item.otros && item.otros.length ? item.otros : [];
          const detalles = item.otros.reduce((ac, v) => {
            ac.push({
              color: v.color && v.color.length ? v.color : "",
              cantidad:
                (v.cant && v.cant.length ? v.cant : "") ||
                (v.cantidad && v.cantidad.length ? v.cantidad : ""),
              status: [v.status],
            });
            return ac;
          }, []);

          const getStatus = data
            .data()
            .otros.every(
              (item) =>
                item.status[0] === "Despachado" ||
                item.status[0] === "Liquidado"
            );
          let newItem = [{}];

          if (itemIndex >= 0) {
            newItem = {
              ...acc[itemIndex],
              otros: acc[itemIndex].otros.concat({
                prof: item.prof,
                op: item.op,
                cx: item.cx,
                fichaTecnica: item.fichaTecnica,
                b: (item.b !== undefined) & (item.b !== []) ? item.b[0] : [],
                hojaConsumo: item.hojaConsumo,
                hc: item.hc,
                descripcion: item.descripcion,
                estado: estado,
                fecha:
                  listaOtros && listaOtros.length ? listaOtros[0].fecha : [],
                estadoProf: item.otros.length
                  ? getStatus
                    ? "Completo"
                    : "Pendiente"
                  : "Pendiente",
                valores: {
                  id: item.index,
                  prof: item.prof,
                  estado: [item.estado[0]],
                  color: colores,
                  op: item.op,
                  isEditable: !item.isEditable,
                  index: item.estado.concat(item.index),
                },
                edit: [
                  item.index,
                  item.prof,
                  item.cFechaIngreso,
                  item.op,
                  item.descripcion,
                  item.color,
                  item.cantidad,
                  item.estado && item.estado.length ? item.estado[0] : "",
                  !item.isEditable,
                  item.cx && item.cx.length ? item.cx[0] : "",
                ],
                detalles,
              }),
            };
            acc.splice(itemIndex, 1, newItem);
          } else {
            newItem = {
              cFechaIngreso: item.cFechaIngreso,
              prof: item.prof,
              a: item.a[0] ? item.a[0] : [],
              cliente: item.cliente,
              cFechaEntrega: item.cFechaEntrega,
              index: item.index,
              estado: estado,
              estadoProf: item.otros.length
                ? getStatus
                  ? "Completo"
                  : "Pendiente"
                : "Pendiente",

              otros: [
                {
                  prof: item.prof,
                  op: item.op,
                  cx: item.cx,
                  fichaTecnica: item.fichaTecnica,
                  b: (item.b !== undefined) & (item.b !== []) ? item.b[0] : [],
                  hojaConsumo: item.hojaConsumo,
                  hc: item.hc,
                  descripcion: item.descripcion,
                  estado: estado,
                  fecha:
                    listaOtros && listaOtros.length ? listaOtros[0].fecha : [],
                  estadoProf: item.otros.length
                    ? getStatus
                      ? "Completo"
                      : "Pendiente"
                    : "Pendiente",
                  valores: {
                    id: item.index,
                    prof: item.prof,
                    estado: [item.estado[0]],
                    color: colores,
                    op: item.op,
                    isEditable: !item.isEditable,
                    index: item.estado.concat(item.index),
                  },
                  edit: [
                    item.index,
                    item.prof,
                    item.cFechaIngreso,
                    item.op,
                    item.descripcion,
                    item.color,
                    item.cantidad,
                    item.estado && item.estado.length ? item.estado[0] : "",
                    !item.isEditable,
                    item.cx && item.cx.length ? item.cx[0] : "",
                  ],
                  detalles,
                },
              ],
            };
            acc.push(newItem);
          }

          return acc;
        }, []);
        this.setState({ data, loading: false });
      });
  }

  componentDidMount() {
    this.loadData();
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  borrar = (value) => {
    const prof = value.prof;
    const id = value.id;
    const colores = value.color;
    const op = value.op;
    const isEditable = value.isEditable;
    const estado = "Nuevo";
    firebase
      .firestore()
      .collection("ing")
      .where("prof", "==", prof)
      .get()
      .then((qs) => {
        if (qs.docs.length > 1) {
          firebase
            .firestore()
            .collection("comercial")
            .where("prof", "==", prof)
            .where("op", "==", op)
            .get()
            .then((sq) => {
              sq.forEach((doc) => {
                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(doc.id)
                  .delete();
              });
            });

          firebase
            .firestore()
            .collection("ing")
            .doc(id)
            .delete();

          firebase
            .firestore()
            .collection("ddp")
            .where("op", "==", op)
            .get()
            .then((qs) => {
              qs.forEach((doc) => {
                firebase
                  .firestore()
                  .collection("ddp")
                  .doc(doc.id)
                  .delete();
              });
            });

          colores.forEach((color) => {
            firebase
              .firestore()
              .collection("telas")
              .where("op", "==", op)
              .where("color", "==", color)
              .get()
              .then((qs) => {
                qs.forEach((doc) => {
                  firebase
                    .firestore()
                    .collection("telas")
                    .doc(doc.id)
                    .delete();
                });
              });
          });

          var contador = 0;
          var lista = this.state.data;
          lista.map((registro) => {
            const idReg = registro.valores.id;
            if (idReg === id) {
              lista.splice(contador, 1);
            }
            contador++;
            return registro;
          });
          this.setState({ data: lista });
        } else {
          firebase
            .firestore()
            .collection("ing")
            .doc(id)
            .update({
              hojaConsumo: "",
              fichaTecnica: "",
              hc: firebase.firestore.FieldValue.delete(),
              b: [],
              cantidad: firebase.firestore.FieldValue.delete(),
              color: firebase.firestore.FieldValue.delete(),
              cx: firebase.firestore.FieldValue.delete(),
              descripcion: firebase.firestore.FieldValue.delete(),
              edit: firebase.firestore.FieldValue.delete(),
              estado: [estado, prof],
              estadoProf: firebase.firestore.FieldValue.delete(),
              op: "",
              otros: [],
              isEditable: true,
            });

          firebase
            .firestore()
            .collection("comercial")
            .where("prof", "==", prof)
            .where("op", "==", op)
            .get()
            .then((qs) => {
              qs.forEach((doc) => {
                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(doc.id)
                  .update({
                    isEditable: true,
                    op: "",
                    b: [],
                    otros: [],
                    edit: firebase.firestore.FieldValue.delete(),
                  });
              });
            });

          firebase
            .firestore()
            .collection("ddp")
            .where("op", "==", op)
            .get()
            .then((qs) => {
              qs.forEach((doc) => {
                firebase
                  .firestore()
                  .collection("ddp")
                  .doc(doc.id)
                  .delete();
              });
            });

          colores.forEach((color) => {
            firebase
              .firestore()
              .collection("telas")
              .where("op", "==", op)
              .where("color", "==", color)
              .get()
              .then((qs) => {
                qs.forEach((doc) => {
                  firebase
                    .firestore()
                    .collection("telas")
                    .doc(doc.id)
                    .delete();
                });
              });
          });

          const dataUpdated = this.state.data.map((item) => {
            if (item.prof === prof) {
              const otros = item.otros;
              const index = otros.findIndex((item) => item.op === op);
              otros.splice(index, 1, {
                ...otros[index],
                op: "",
                cx: [],
                fichaTecnica: "",
                b: [],
                hojaConsumo: "",
                hc: [],
                descripcion: "",
                fecha: "",
                estado: [estado],
                index: estado.concat(id),
                otros: [],
                edit: [id, op, colores, estado, true],
                valores: {
                  estado: estado,
                },
              });
            }
            return item;
          });
          this.setState({ data: dataUpdated });
        }
      });
  };

  openModal(value) {
    this.setState({
      modalIsOpen: true,
      doc: value[0],
      prof: value[1],
      fechaIngreso: value[2],
      ordenPedido: value[3],
      descripcion: value[4],
      color: value[5],
      cantidad: value[6],
      estado: value[7],
      isEditable: value[8],
      cx: value[9],
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Ingenieria/EditIngenieria",
          state: {
            doc: this.state.doc,
            prof: this.state.prof,
            color: this.state.color,
            cantidad: this.state.cantidad,
            descripcion: this.state.descripcion,
            fechaIngreso: this.state.fechaIngreso,
            ordenPedido: this.state.ordenPedido,
            estado: this.state.estado,
            isEditable: this.state.isEditable,
            cx: this.state.cx,
          },
        })
      : alert("Wrong password, try again");
  };

  render() {
    const { data, loading, modalTitleProf, modalTitleOp } = this.state;

    const ingPrincipal = [
      {
        headerStyle: { backgroundColor: "#03A9F4", color: "white" },
        columns: [
          {
            Header: "F. Comercial",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "cFechaIngreso",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cFechaIngreso",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Factura Proforma",
            width: 150,
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "prof",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "prof" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "a",
            width: 30,
            Cell: (row) => (
              <span
                style={{
                  width: "10px",
                  height: "100%",
                }}
              >
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                    style={{ width: "18px" }}
                    alt=""
                  />
                </a>
              </span>
            ),
          },
          {
            Header: "Cliente",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "cliente",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "cliente" },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <span style={{ fontSize: "12px " }}>{row.value}</span>
            ),
          },
        ],
      },

      {
        headerStyle: { backgroundColor: "#03A9F4", color: "white" },
        columns: [
          {
            Header: "F. Despacho",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "cFechaEntrega",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "cFechaEntrega",
                  },
                ],
              }),
            filterAll: true,
          },

          {
            Header: "Estado",
            accessor: "estadoProf",
            width: 100,
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["estadoProf"] }),
            filterAll: true,
            Cell: (row) => (
              <div>
                {row.value === "Completo" ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "12px",
                      borderRadius: "10px",
                      padding: "6px",
                      backgroundColor:
                        row.value === "Nuevo"
                          ? "#03A9F4"
                          : row.value === "Pendiente"
                          ? "#ee6f05"
                          : "#6DC31F",
                    }}
                  >
                    {row.value}
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "",
            headerStyle: { fontWeight: "bold" },
            accessor: "estado",
            width: 60,
            style: { textAlign: "left", fontSize: "12px" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "estado" },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.value === "Nuevo" ? (
                  <Link
                    to={{
                      pathname: "/Ingenieria/RegistroDirecto",
                      state: {
                        prof: row.original.prof,
                        doc: row.original.index,
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          row.value === "Nuevo" ? "#03A9F4" : "#008030",
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        fontWeight: "bold",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {row.value}
                    </Button>
                  </Link>
                ) : row.value ? (
                  <span></span>
                ) : null}
              </div>
            ),
          },
        ],
      },
    ];

    const ingSub1 = [
      {
        Header: "PCP",
        headerStyle: { backgroundColor: "#03A9F4", color: "white" },
        columns: [
          {
            Header: "Orden Producción",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            width: 145,
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "op" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "cx",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Ficha Técnica",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "fichaTecnica",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "fichaTecnica",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "b",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Consumo",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "hojaConsumo",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "hojaConsumo",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "hc",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Descripción",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "descripcion",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "descripcion",
                  },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <span style={{ fontSize: "12px " }}>{row.value}</span>
            ),
          },
          {
            Header: "Fecha Inicio",
            headerStyle: { fontWeight: "bold" },
            accessor: "fecha",
          },
          {
            Header: "Listo",
            headerStyle: { fontWeight: "bold" },
            accessor: "estado",
            width: 60,
            style: { textAlign: "left", fontSize: "12px" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "estado" },
                ],
              }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.value === "Nuevo" ? null : row.value ? (
                  <span
                    style={{
                      backgroundColor: "#008030",
                      width: "40%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    {row.value}
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Estado",
            accessor: "estadoProf",
            width: 100,
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["estadoProf"] }),
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.row.op ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "12px",
                      borderRadius: "10px",
                      padding: "6px",
                      backgroundColor:
                        row.value === "Nuevo"
                          ? "#03A9F4"
                          : row.value === "Pendiente"
                          ? "#ee6f05"
                          : "#6DC31F",
                    }}
                  >
                    {row.value}
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Editar",
            headerStyle: { fontWeight: "bold" },
            accessor: "edit",
            width: 80,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["edit"] }),
            filterAll: true,
            Cell: (row) => (
              <div>
                {row.value[3] ? (
                  <Button
                    style={{
                      backgroundColor: "orange",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      fontWeight: "bold",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={
                      row.value[7] === "Nuevo" || row.value[8] ? true : false
                    }
                    onClick={() => this.openModal(row.value)}
                  >
                    Editar
                  </Button>
                ) : null}
              </div>
            ),
          },

          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold" },
            width: 80,
            accessor: "valores",
            Cell: (row) => (
              <div className="text-center">
                {row.value.op ? (
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={
                      row.value.estado === "Nuevo" || row.value.isEditable
                        ? true
                        : false
                    }
                    onClick={(e) =>
                      window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                      this.borrar(row.value)
                    }
                    type="button"
                  >
                    Borrar
                  </Button>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Detalles",
            headerStyle: { fontWeight: "bold" },
            width: 115,
            accessor: "detalles",
            filterAll: true,
            Cell: (row) => (
              <div className="text-center">
                {row.original.op ? (
                  <Buttons
                    type="primary"
                    style={{
                      width: "80%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    onClick={() => {
                      this.setState({
                        visible: true,
                        colors: row.value,
                        modalTitleOp: row.original.op,
                        modalTitleProf: row.original.prof,
                      });
                    }}
                  >
                    Ver Detalles
                  </Buttons>
                ) : null}
              </div>
            ),
          },
        ],
      },
    ];
    const ingSub2 = [
      {
        Header: "PCP",
        headerStyle: { backgroundColor: "#03A9F4", color: "white" },
        columns: [
          {
            Header: "Color",
            headerStyle: { fontWeight: "bold" },
            accessor: "color",
          },
          {
            Header: "Cantidad",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantidad",
          },
          {
            Header: "Estado",
            width: 110,
            headerStyle: { fontWeight: "bold" },
            accessor: "status",
            Cell: (row) => (
              <div className="center">
                <span
                  style={{
                    backgroundColor:
                      row.value[0][0] === "PCP"
                        ? "#03A9F4"
                        : row.value[0][0] === "Despachado"
                        ? `green`
                        : row.value[0][0] === "Acabado"
                        ? "#6DC31F"
                        : row.value[0][0] === "Almacen"
                        ? "#FF8C00"
                        : row.value[0][0] === "Tejeduria"
                        ? "teal"
                        : row.value[0][0] === "Tintoreria"
                        ? "purple"
                        : row.value[0][0] === "Compras"
                        ? "blue"
                        : row.value[0][0] === "Rotulado"
                        ? "#F5BC66"
                        : "#F5BC66",
                    fontWeight: "bold",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "6px",
                    color: "white",
                  }}
                >
                  {row.value[0][0]}
                </span>
              </div>
            ),
          },
        ],
      },
    ];
    return (
      <div className="content">
        <Modals
          data={data}
          title={`Pof: "${modalTitleProf}" | OP: "${modalTitleOp}"`}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={700}
          centered={true}
        >
          <ReactTable
            data={this.state.colors}
            columns={ingSub2}
            defaultPageSize={10}
          />
        </Modals>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos PCP
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={ingPrincipal}
                  noDataText="Sin Registros"
                  loading={loading}
                  loadingText="POR FAVOR, ESPERE UN MOMENTO"
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  rowsText="filas"
                  minRows={0}
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.otros}
                          columns={ingSub1}
                          noDataText="Ingrese Orden de Pedido"
                          pageSize={row.original.otros.length}
                          showPagination={false}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0",
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{ border: "1px solid black" }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              autoFocus
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={this.closeModal}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
