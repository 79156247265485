import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import DashboardComercial from "layouts/Dashboard/DashboardComercial.jsx";
import DashboardIngenieria from "layouts/Dashboard/DashboardIngenieria.jsx";
import DashboardTelas from "layouts/Dashboard/DashboardTelas.jsx";
import DashboardCorte from "layouts/Dashboard/DashboardCorte.jsx";
import DashboardCostura from "layouts/Dashboard/DashboardCostura.jsx";
import DashboardAcabado from "layouts/Dashboard/DashboardAcabado.jsx";
import DashboardAlmacen from "layouts/Dashboard/DashboardAlmacen.jsx";
import DashboardDdp from "../layouts/Dashboard/DashboardDdp";
import MainComercial from "../layouts/Dashboard/DashboardComercialMain";
import MainIngenieria from "../layouts/Dashboard/DashboardIngenieriaMain";
import MainTelas from "../layouts/Dashboard/DashboardTelasMain";
import MainCostura from "../layouts/Dashboard/DashboardCosturaMain";
import MainAcabado from "../layouts/Dashboard/DashboardAcabadoMain";
import MainCorte from "../layouts/Dashboard/DashboardCorteMain";
import MainAlmacen from "../layouts/Dashboard/DashboardAlmacenMain";
import MainDdp from "../layouts/Dashboard/DashboardDdpMain";

var indexRoutes = [
  { path: "/Admin", name: "Admin", component: Dashboard },
  { path: "/Comercial", name: "Comercial", component: DashboardComercial },
  { path: "/Ingenieria", name: "Comercial", component: DashboardIngenieria },
  { path: "/Telas", name: "Telas", component: DashboardTelas },
  { path: "/Corte", name: "Corte", component: DashboardCorte },
  { path: "/Costura", name: "Costura", component: DashboardCostura },
  { path: "/Acabado", name: "Acabado", component: DashboardAcabado },
  { path: "/Almacen", name: "Almacen", component: DashboardAlmacen },
  { path: "/Ddp", name: "Ddp", component: DashboardDdp }
];

var comercialRoutes = [
  { path: "/Comercial", name: "Comercial", component: MainComercial }
];

var ingenieriaRoutes = [
  { path: "/Ingenieria", name: "Ingenieria", component: MainIngenieria }
];

var telasRoutes = [{ path: "/Telas", name: "Telas", component: MainTelas }];

var corteRoutes = [{ path: "/Corte", name: "Corte", component: MainCorte }];

var costuraRoutes = [
  { path: "/Costura", name: "Costura", component: MainCostura }
];

var acabadoRoutes = [
  { path: "/Acabado", name: "Acabado", component: MainAcabado }
];

var almacenRoutes = [
  { path: "/Almacen", name: "Almacen", component: MainAlmacen }
];

var ddpRoutes = [{ path: "/Ddp", name: "Ddp", component: MainDdp }];

export {
  indexRoutes,
  comercialRoutes,
  ingenieriaRoutes,
  telasRoutes,
  corteRoutes,
  costuraRoutes,
  acabadoRoutes,
  almacenRoutes,
  ddpRoutes
};
