import ConsultaComercial from "views/TableList/ConsultaComercial.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import NewComercialRegister from "views/UserPage/comercial/NewComercialRegister";
import AddClients from "views/UserPage/comercial/AddClients";
import EditComercialRegister from "views/UserPage/comercial/EditComercialRegister";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/comercial/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: ConsultaComercial,
  },
  {
    path: "/comercial/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: NewComercialRegister,
  },
  {
    path: "/comercial/clientes",
    name: "Clientes",
    icon: "nc-icon nc-briefcase-24",
    component: AddClients,
  },
  {
    path: "/comercial/EditComercial",
    name: "Editar",
    component: EditComercialRegister,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/comercial/Procesos",
    name: "Registro",
  },
];

export default dashRoutes;
