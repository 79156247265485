import firebase from "../../Firebase";
import { FirebaseCollections } from "enums";

const firestoreApi = firebase.firestore();

const getNextCorteId = async () => {
  try {
    let recordId = null;
    const record = await firestoreApi
      .collection(FirebaseCollections.Corte)
      .orderBy("order", "desc")
      .limit(1)
      .get();

    record.forEach((doc) => {
      recordId = Number(doc.id) + 1;
    });

    return recordId;
  } catch (e) {
    console.log(e.message);
  }
};

const createCorteRecord = async (corteData) => {
  const recorId = await getNextCorteId();
  try {
    const corte = await firestoreApi
      .collection(FirebaseCollections.Corte)
      .doc(String(recorId))
      .set({
        ...corteData,
        index: String(recorId),
        order: Number(recorId),
      });
    return corte;
  } catch (e) {
    console.log(e.message);
  }
};

export { getNextCorteId, createCorteRecord };
