import React from "react";
import moment from "moment";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Input,
  FormGroup,
  Alert,
  Col,
  Label,
} from "reactstrap";
import firebase from "Firebase.js";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButton/CustomButton.jsx";
registerLocale("es", es);

class NewAlmacenRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("pcp");
    this.state = {
      fechaIngreso: new Date(),
      isOpen: false,
      caja: "",
      cantIngresada: "",
      p: [],
      selectedOption: null,
    };
  }

  componentDidMount() {
    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let p = [];
        querySnapshot.forEach(function(doc) {
          let acabado = [];
          if (doc.data().acabado) {
            for (let t = 0; t < doc.data().acabado.length; t++) {
              let cantCorte = 0;
              let corte = doc.data().corte;
              corte.forEach((e) => {
                if (
                  doc.data().acabado[t].op === e.op &&
                  doc.data().acabado[t].color === e.color
                ) {
                  cantCorte = e.total;
                }
              });

              let saldo = doc.data().acabado[t].cantSalida - cantCorte;

              if (doc.data().acabado[t].seg === "almacen") {
                acabado.push({
                  value: doc.id,
                  op: doc.data().acabado[t].op,
                  color: doc.data().acabado[t].color,
                  ft: doc.data().acabado[t].ft,
                  fecha: doc.data().acabado[t].fechaSalida,
                  saldo: saldo,
                });
              }
            }
          }

          let almacen = [];
          if (doc.data().almacen) almacen = doc.data().almacen;

          for (let i = 0; i < acabado.length; i++) {
            if (doc.data().almacen) {
              let x = 0;
              while (x < almacen.length) {
                if (acabado[i] !== undefined)
                  if (
                    acabado[i].op === almacen[x].op &&
                    acabado[i].color === almacen[x].color
                  ) {
                    acabado.splice(i, 1);
                    i--;
                  }
                x++;
              }
            } else {

            }
          }

          acabado.forEach((value) => {
            p.push({
              label: value.op + " " + value.color,
              value: doc.id,
              color: value.color,
              op: value.op,
              fecha: value.fecha,
              ft: value.ft,
              saldo: value.saldo,
            });
          });

          function removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject = {};

            for (var i in originalArray) {
              lookupObject[originalArray[i][prop]] = originalArray[i];
            }

            for (i in lookupObject) {
              newArray.push(lookupObject[i]);
            }
            return newArray;
          }
          p = removeDuplicates(p, "label");
        });
        this.setState({ p });
      });
  }

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleClickShowAlert() {
    this.setState({
      isOpen: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 1000);
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let { fechaIngreso, cantIngresada, selectedOption, caja } = this.state;
    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

    this.ref
      .doc(selectedOption.value)
      .get()
      .then((res) => {
        try {
          this.ref
            .doc(selectedOption.value)
            .update({
              almacen: firebase.firestore.FieldValue.arrayUnion({
                op: selectedOption.op,
                color: selectedOption.color,
                fechaAcabado: selectedOption.fecha,
                ft: selectedOption.ft,
                fechaIngreso,
                cantIngresada,
                caja,
                saldo: selectedOption.saldo,
                seg: "entregado",
              }),
            })
            .then((docRef) => {
              this.setState({
                fechaIngreso: new Date(),
                caja: "",
                cantIngresada: "",
                selectedOption: "",
              });
              this.props.history.push("/Almacen/Procesos");
            });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });
    e.target.reset();
  };

  render() {
    const { caja, cantIngresada, selectedOption } = this.state;

    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>Registro</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FECHA:
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaIngreso}
                      onChange={this.handleChange}
                      dateFormat="d MMMM, yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      name="fechaIngreso"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <Select
                      isSearchable={true}
                      options={this.state.p}
                      className={"col-md-5"}
                      value={selectedOption}
                      onChange={this.selectHandleChange}
                      autoFocus
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "BOLD" }}>
                    <Label for="cantIngresada">CANTIDAD</Label>
                    <Input
                      type="text"
                      name="cantIngresada"
                      id="cantIngresada"
                      className="col-md-3 pr-1"
                      value={cantIngresada}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label>NUMERO DE CAJA</Label>
                    <Input
                      type="text"
                      name="caja"
                      id="caja"
                      className="col-md-3 pr-1"
                      value={caja}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen}>
                      Registro ingresado
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        style={{ marginTop: "150px" }}
                        round
                        type="submit"
                        onClick={this.handleClickShowAlert.bind(this)}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewAlmacenRecord;
