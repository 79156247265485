import Consulta from "views/TableList/ConsultaTelas.jsx";
import NewTelaRecord from "views/UserPage/telas/NewTelaRecord";
import EditTelaRecord from "views/UserPage/telas/EditTelaRecord";

var dashRoutes = [
  {
    path: "/Telas/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  {
    path: "/Telas/RegistroDirecto",
    name: "RegistroDirecto",
    component: NewTelaRecord,
    invisible: true,
  },
  {
    path: "/Telas/RegistroActDirecto",
    name: "RegistroActDirecto",
    component: EditTelaRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Telas/Procesos",
    name: "Registro",
  },
];
export default dashRoutes;
