import Consulta from "views/TableList/ConsultaAcabado.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import {
  DirectNewAcabadosRecord,
  EditAcabadosRecord,
  UpdateAcabadosRecord,
  UpdateLiqAcabadosRecord,
  //NewAcabadosRecord,
} from "views/UserPage/acabados";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Acabado/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  /*
  {
    path: "/Acabado/IngresarDatos",
    name: "Registro",
    icon: "nc-icon nc-tile-56",
    component: NewAcabadosRecord,
  },
  */
  {
    path: "/Acabado/RegistroDirecto",
    name: "Registro Directo",
    component: DirectNewAcabadosRecord,
    invisible: true,
  },
  {
    path: "/Acabado/RegistroActDirecto",
    name: "Registro Directo",
    component: UpdateAcabadosRecord,
    invisible: true,
  },
  {
    path: "/Acabado/RegistroLiquidacion",
    name: "Registro Liquidacion",
    component: UpdateLiqAcabadosRecord,
    invisible: true,
  },
  {
    path: "/Acabado/EditAcabado",
    name: "Edit Acabado",
    component: EditAcabadosRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Acabado/Procesos",
    name: "Registro",
  },
];

export default dashRoutes;
