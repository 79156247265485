import Dashboard from "views/Dashboard/Dashboard.jsx";
import Consulta from "views/TableList/ConsultaAlmacen.jsx";
import DirectNewAlmacenRecord from "views/UserPage/almacen/DirectNewAlmacenRecord";
import EditAlmacenRecord from "views/UserPage/almacen/EditAlmacenRecord";

var dashRoutes = [
  {
    path: "/admin/menu",
    name: "Menu",
    icon: "nc-icon nc-book-bookmark",
    component: Dashboard,
  },
  {
    path: "/Almacen/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  {
    path: "/Almacen/EditAlmacen",
    name: "EditAlmacen",
    component: EditAlmacenRecord,
    invisible: true,
  },
  {
    path: "/Almacen/RegistroDirecto",
    component: DirectNewAlmacenRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Almacen/Procesos",
    name: "Registro",
  },
];
export default dashRoutes;
