import firebase from "../../Firebase";
import { FirebaseCollections } from "enums";

const firestoreApi = firebase.firestore();

const getTelaRecord = async (docId) => {
  try {
    const telaRecord = await firestoreApi
      .collection(FirebaseCollections.Telas)
      .doc(docId)
      .get();

    return {
      id: telaRecord.id,
      ...telaRecord.data(),
    };
  } catch (error) {
    console.error(error.message);
  }
};

const getAllTelas = async () => {
  try {
    const allTelasData = await firestoreApi
      .collection(FirebaseCollections.Telas)
      .orderBy("order", "desc")
      .get();

    const datos = [];

    allTelasData.forEach(function(doc) {
      const telas = doc.data();

      datos.push({
        id: doc.id,
        op: telas.op,
        cx: telas.cx ? telas.cx[0] : null,
        descripcion: telas.descripcion,
        fechaPcp: telas.fechaPcp,
        color: telas.color,
        cantidad: telas.cantidad,
        fechaDespacho: telas.fechaDespacho,
        fechaIngreso: telas.fechaIngreso,
        fechaSalida: telas.fechaSalida ? telas.fechaSalida : null,
        ft: telas.ft ? telas.ft : null,
        hojaConsumo: telas.hojaConsumo ? telas.hojaConsumo : null,
        hc: telas.hc ? telas.hc[0] : null,
        b: telas.b ? telas.b[0] : null,
        edit: [telas.edit],
        estado: {
          id: doc.id,
          op: telas.op,
          color: telas.color,
          cantidad: telas.cantidad,
          estado: telas.estado[0],
        },
        index: telas.estado.concat(telas.index),
        isEditable: true,
      });
    });
    return datos;
  } catch (e) {
    console.error(e.message);
  }
};

const updateTelaRecord = async (tela, docId) => {
  try {
    await firestoreApi
      .collection(FirebaseCollections.Telas)
      .doc(String(docId))
      .update(tela);
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

export { getAllTelas, updateTelaRecord, getTelaRecord };
