import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "Firebase.js";
import { notification, message, Button, DatePicker, Input } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "antd/dist/antd.css";

class UpdateAcabadosRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("acabados");
    this.state = {
      isOpen2: false,
      fechaSalida: new Date(),
      cantidadSalida: "",
      op: null,
      value: null,
      color: null,
      label: null,
      comercialId: null,
      comercialIndex: null,
      lastAlmacenId: null,
      cliente: null,
      isActive: false,
      cantEntradaAcabado: null,
    };
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const { colorState } = this.props.location.state;
    const { cantEntradaAcabado } = this.props.location.state;
    this.setState({ cantEntradaAcabado: cantEntradaAcabado });

    this.ref
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            value: doc.data().order,
            color: colorState,
            op: ordenPedido,
          });
        });
      });

    firebase
      .firestore()
      .collection("almacen")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            lastAlmacenId: doc.data().index,
          });
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            cliente: doc.data().cliente,
            comercialId: doc.id,
          });
        });
      });
  }

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaSalida,
      cantidadSalida,
      value,
      comercialId,
      comercialIndex,
      lastAlmacenId,
      cliente,
      cantEntradaAcabado,
    } = this.state;

    if (cantidadSalida <= cantEntradaAcabado) {
      this.setState({
        isActive: false,
      });
      message.loading("Registrando...", 60);
      let id = Number(lastAlmacenId) + 1;

      fechaSalida = moment(fechaSalida).format("DD/MM/YYYY");

      this.ref
        .doc(String(value))
        .get()
        .then((res) => {
          const acabado = res.data();

          acabado.otros[0] = {
            ...acabado.otros[0],
            fechaSalida: fechaSalida,
            cantSalida: Number(cantidadSalida),
          };

          if (Number(cantidadSalida) >= acabado.totalCorte) {
            acabado.despacho = "Completo";
          } else if (Number(cantidadSalida) < acabado.totalCorte) {
            acabado.despacho = "Incompleto";
          }

          acabado.estado[0] = "Listo";
          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(comercialId)
              .get()
              .then((doc) => {
                let otros = doc.data().otros;
                otros[comercialIndex] = {
                  ...otros[comercialIndex],
                  acabado: {
                    ...otros[comercialIndex].acabado,
                    fechaSalida,
                    cantidadSalida,
                  },
                };

                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .set({ otros, status: "Despachado" }, { merge: true });
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            firebase
              .firestore()
              .collection("ing")
              .where("op", "==", acabado.op)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let otros = doc.data().otros;
                  otros.forEach((item) => {
                    if (item.color === acabado.color) {
                      item.status = ["Despachado"];
                    }
                  });
                  firebase
                    .firestore()
                    .collection("ing")
                    .doc(doc.id)
                    .set(
                      { otros, estadoProf: acabado.despacho },
                      { merge: true }
                    );
                });
              });
          } catch (error) {
            console.error(error);
          }

          try {
            let almacen = {
              acabadoFecha: fechaSalida,
              op: acabado.op,
              cx: acabado.cx,
              ft: acabado.ft ? acabado.ft : "",
              b: acabado.b ? acabado.b : "",
              cliente,
              color: acabado.color,
              descripcion: acabado.descripcion,
              estado: ["Nuevo", acabado.op, acabado.color],
              edit: [String(id), acabado.op, acabado.color, "Nuevo"],
              order: Number(id),
              index: String(id),
              otros: [],
              isEditable: true,
            };

            firebase
              .firestore()
              .collection("almacen")
              .doc(String(id))
              .set(almacen)
              .catch(console.log);
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            this.ref
              .doc(String(value))
              .update(acabado)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registro agregado exitosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  fechaSalida: new Date(),
                  cantidadSalida: "",
                });
                this.props.history.push("/Acabado/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La cantidad de salida: ${cantidadSalida}, no debe ser mayor a la cantidad de Entrada: ${cantEntradaAcabado}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const { cantidadSalida } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">Ingresar Salida</CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <Label className="font-weight-bold">FECHA DE SALIDA:</Label>
                  <DatePicker
                    className="col-md-3 d-block"
                    defaultValue={moment(this.state.fechaSalida, "DD/MM/YYYY")}
                    onChange={this.handleChangeSalida}
                    format="DD/MM/YYYY"
                    locale={locale}
                  />
                  <FormGroup>
                    <Label className="font-weight-bold mt-3">
                      ORDEN DE PRODUCCION
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">CANTIDAD SALIDA</Label>
                    <Input
                      name="cantidadSalida"
                      className="col-md-3 d-block"
                      value={cantidadSalida}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto mt-3">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive || !cantidadSalida ? true : false
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateAcabadosRecord;
