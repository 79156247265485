import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { Upload, Icon, notification, message, Button, Input as In } from "antd";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

class EditPcpRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("ing");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      fechaEntrega: new Date(),
      prof: "",
      estado2: "",
      isOpen2: false,
      doc: null,
      color: [],
      cantidad: [],
      descripcion: "",
      ordenPedido: "",
      img: "",
      colores: [],
      value: null,
      lastIndexTelas: null,
      isActive: false,
      loading: false,
      selectedFile: null,
      selectedFileList: [],
      pdfUrl: "",
      filelist: null,
      fileList: null,
      ordenProd: [],
    };
  }

  componentDidMount() {
    const {
      doc,
      prof,
      color,
      cantidad,
      descripcion,
      fechaIngreso,
      ordenPedido,
      cx,
    } = this.props.location.state;

    const pdfUrls = cx;
    const opAnterior = ordenPedido;
    const colorInicial = color;
    const colorAnterior = color;

    this.ref
      .orderBy("order", "asc")
      .get()
      .then((querySnap) => {
        const op = [];
        querySnap.forEach((doc) => {
          op.push(doc.data().op);
        });
        this.setState({ ordenProd: op });
      });

    this.ref
      .doc(String(doc))
      .get()
      .then((ref) => {
        let dataPCP = ref.data();
        const newFD = dataPCP.cFechaEntrega.split("/");
        arraymove(newFD, 0, 1);
        const fechaEntrega = new Date(newFD.join("/"));
        this.setState({ fechaEntrega });
      });

    firebase
      .firestore()
      .collection("pcpclientes")
      .get()
      .then((querySnapshot) => {
        var clientes = [];
        querySnapshot.forEach(function(doc) {
          clientes.push({
            label: doc.data().name,
            value: doc.id,
          });
        });
        this.setState({ colores: clientes });
      });

    let newFI = fechaIngreso.split("/");

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    arraymove(newFI, 0, 1);

    this.setState({
      doc: doc,
      prof: prof,
      color: color,
      cantidad: cantidad,
      descripcion: descripcion,
      ordenPedido: ordenPedido,
      fechaIngreso: new Date(newFI.join("/")),
      opAnterior,
      colorAnterior,
      colorInicial,
      pdfUrls,
      fileList: [
        {
          uid: doc,
          name: ordenPedido,
          status: "done",
          url: pdfUrls,
        },
      ],
    });

    firebase
      .firestore()
      .collection("telas")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let lastIndexTelas = null;
        querySnapshot.forEach((doc) => {
          lastIndexTelas = doc.data().index;
        });
        this.setState({ lastIndexTelas });
      });
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  addCantidad() {
    this.setState({ cantidad: [...this.state.cantidad, ""] });
  }

  addColor() {
    this.setState({ color: [...this.state.color, ""] });
  }

  delete() {
    let color = this.state.color;
    let cantidad = this.state.cantidad;
    if (color.length > 1) {
      color.pop();
    }
    if (cantidad.length > 1) {
      cantidad.pop();
    }

    this.setState({ color: color, cantidad: cantidad });
  }

  add() {
    this.addCantidad();
    this.addColor();
  }

  handleChangeCantidad(e, index) {
    const cantidad = this.state.cantidad;
    cantidad[index] = e.target.value;
    this.setState({ cantidad });
  }

  changeImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      this.setState(() => ({ img, ordenPedido: img.name.slice(0, -4) }));
    }
  };

  handleChangeColor(e, index) {
    const color = this.state.color;
    color[index] = e.label;
    this.setState({ color });
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleChangePDF = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelist: info.fileList, fileList });

    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    this.setState(() => ({ file, ordenPedido: file.name.slice(0, -4) }));

    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`ordenPedido/${file.name}`);
    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("ordenPedido")
        .child(file.name)
        .getDownloadURL();
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      fechaIngreso,
      ordenPedido,
      cantidad,
      descripcion,
      color,
      doc,
      pdfUrls,
      prof,
      opAnterior,
      fechaEntrega,
      ordenProd,
    } = this.state;

    this.setState({
      isActive: true,
    });

    if (opAnterior === ordenPedido || !ordenProd.includes(ordenPedido)) {
      const msg = {
        message: `La Orden de Produccion "${opAnterior}" se actualizó a "${ordenPedido}" con exito.`,
        top: 80,
      };
      notification.success(msg);

      message.loading("Registrando...", 80);

      const fechaIngresoFormat = moment(fechaIngreso).format("DD/MM/YYYY");
      const fechaEntregaForma = moment(fechaEntrega).format("DD/MM/YYYY");

      const OPFile =
        this.state.filelist && this.state.filelist.length
          ? this.state.filelist[0].xhr.url
          : pdfUrls;

      const data = {
        op: ordenPedido || "",
        descripcion: descripcion || "",
        cFechaIngreso: fechaIngresoFormat || "",
        cx: [OPFile || ""],
        otros: color.reduce((ac, value, i) => {
          ac.push({
            cant: cantidad[i] || "",
            color: value || "",
            fecha: fechaIngresoFormat || "",
            status: ["PCP"],
          });
          return ac;
        }, []),
        edit: [doc, prof, fechaIngresoFormat, ordenPedido, descripcion],
        color: color || "",
        cantidad: cantidad || "",
      };
      try {
        this.ref
          .doc(String(doc))
          .update(data)
          .then((_) => {
            message.destroy();
            const args = {
              message: "Registro actualizado existosamente.",
              top: 80,
            };
            notification.success(args);
            this.props.history.push("/Ingenieria/Procesos");
          });
      } catch (error) {
        console.error(error.message);
      }

      const otrosComercial = color.reduce((ac, value, i) => {
        ac.push({
          cant: cantidad[i] || "",
          color: value || "",
          descripcion: descripcion || "",
          estado: "PCP",
        });
        return ac;
      }, []);

      try {
        firebase
          .firestore()
          .collection("comercial")
          .where("prof", "==", prof)
          .where("op", "==", opAnterior)
          .get()
          .then((querySnapshot) => {
            let comercialId = null;
            querySnapshot.forEach((doc) => {
              comercialId = doc.id;
              const comercialData = {
                ...doc.data(),
                op: ordenPedido || "",
                b: [OPFile || ""],
                otros: otrosComercial || "",
              };
              try {
                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .update(comercialData);
              } catch (error) {
                console.error(error.message);
              }
            });
          });
      } catch (error) {
        console.error(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("ddp")
          .where("op", "==", opAnterior)
          .get()
          .then((querySnapshot) => {
            let ddpId = null;
            querySnapshot.forEach((doc) => {
              ddpId = doc.id;
              const ddpData = {
                op: ordenPedido || "",
                a: [OPFile || ""],
                cFechaIngreso: fechaIngresoFormat || "",
                cx: [OPFile || ""],
                descripcion: descripcion || "",
                color: color || "",
                cantidad: cantidad || "",
                edit: [
                  ddpId,
                  prof,
                  fechaIngresoFormat,
                  ordenPedido,
                  descripcion,
                ],
              };
              try {
                firebase
                  .firestore()
                  .collection("ddp")
                  .doc(ddpId)
                  .update(ddpData);
              } catch (error) {
                console.error(error.message);
              }
            });
          });
      } catch (error) {
        console.error(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("telas")
          .where("op", "==", opAnterior)
          .get()
          .then((snap) => {
            snap.docs.forEach((doc, i) => {
              let telas = {};

              telas.op = ordenPedido;
              telas.cx = [OPFile || ""];
              telas.descripcion = descripcion;
              telas.fechaPcp = fechaIngresoFormat;
              telas.color = color[i];
              telas.fechaDespacho = fechaEntregaForma;
              telas.cantidad = Number(cantidad[i]);
              telas.edit = [String(doc.id), ordenPedido, color[i], "Nuevo"];
              telas.estado = [doc.data().estado[0], ordenPedido, color[i]];

              firebase
                .firestore()
                .collection("telas")
                .doc(doc.id)
                .update(telas);
            });
          });
      } catch (error) {
        console.error(error.message);
      }
    } else {
      const msg = {
        message: `La FACTURA PROFORMA "${prof}" ya existe.`,
        top: 80,
      };
      notification.success(msg);
      this.setState({
        isActive: false,
      });
    }
    setTimeout(() => {
      this.setState({
        isActive: false,
      });
    }, 6000);
  };

  render() {
    const { prof, ordenPedido, descripcion, loading, pdfUrl } = this.state;
    const isInvalid =
      ordenPedido !== "" ||
      descripcion !== "" ||
      !(
        this.state.filelist &&
        this.state.fileList.every((item) => item.status === "done")
      );

    const uploadButton = (
      <div>
        <Button
          className="mt-2 ml-3"
          style={{ display: "block", textAlign: "left" }}
        >
          <Icon type={loading ? "loading" : "plus"} />
          Seleccionar Archivo
        </Button>
      </div>
    );
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle style={{ marginLeft: "15px" }}>
                  ACTUALIZAR REGISTRO
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label>FACTURA PROFORMA</Label>
                    <In
                      className="col-md-3 d-block"
                      value={prof}
                      onChange={this.onChange}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="op">ORDEN DE PRODUCCIÓN</Label>
                    <In
                      type="text"
                      name="ordenPedido"
                      id="op"
                      className="col-md-3 d-block"
                      value={ordenPedido}
                      onChange={this.onChange}
                      required
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="estilo">DESCRIPCIÓN</Label>
                    <In
                      name="descripcion"
                      className="col-md-3 d-block"
                      value={descripcion}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>

                  <Row>
                    <Col md={6} xs={6}>
                      {this.state.color.map((color, index) => {
                        return (
                          <FormGroup key={index}>
                            <Label
                              style={{ marginLeft: "15px", fontWeight: "bold" }}
                            >
                              COLOR
                            </Label>
                            <Select
                              showSearch={true}
                              className="col-md-9 d-block"
                              onChange={(e) => this.handleChangeColor(e, index)}
                              defaultValue={{ label: color, value: index }}
                              options={this.state.colores}
                              placeholder="Eliga Color"
                              required
                            />
                          </FormGroup>
                        );
                      })}
                      {/*
                      <Button
                        type="primary"
                        size="small"
                        onClick={(e) => this.add(e)}
                        style={{ marginLeft: "15px" }}                        
                      >
                        Agregar
                      </Button>
                      <Button
                        type="danger"
                        size="small"
                        onClick={(e) => this.delete(e)}
                        style={{ marginLeft: "15px"}}
                        className="ml-3"
                      >
                        Borrar
                      </Button>
                      */}
                    </Col>
                    <Col md={6} xs={6}>
                      {this.state.cantidad.map((cantidad, index) => {
                        return (
                          <FormGroup key={index} style={{ fontWeight: "bold" }}>
                            <Label>CANTIDAD</Label>
                            <Input
                              type="text"
                              name="cantidad"
                              id="cantidad"
                              className="col-md-4"
                              value={cantidad}
                              autoComplete="off"
                              onChange={(e) =>
                                this.handleChangeCantidad(e, index)
                              }
                              required
                            />
                          </FormGroup>
                        );
                      })}
                    </Col>
                  </Row>

                  <Row>
                    <Label className="font-weight-bold mt-2 ml-3 col-md-3">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() =>
                      this.setState({ filelist: null, ordenPedido: "" })
                    }
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangePDF}
                    customRequest={this.customUpload}
                    fileList={this.state.fileList}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      INGRESADO
                    </Alert>
                  </FormGroup>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        type="primary"
                        style={{ marginnTop: "20px" }}
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive || !isInvalid ? true : false
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditPcpRecord;
