import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import firebase from "./Firebase";
import Login from "views/Login/Login.jsx";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import {
  indexRoutes,
  ddpRoutes,
  comercialRoutes,
  ingenieriaRoutes,
  telasRoutes,
  corteRoutes,
  costuraRoutes,
  acabadoRoutes,
  almacenRoutes
} from "routes/index.jsx";

const hist = createBrowserHistory();

class App extends Component {
  state = { authenticated: false, user: null, emailUser: "", ruta: [] };

  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          emailUser: user.email
        });
      } else {
        this.setState({
          authenticated: false,
          currentUser: null
        });
      }
    });
  }

  render() {
    return (
      <Router history={hist}>
        <Switch>
          <Route exact path="/" component={Login} />
          {(() => {
            switch (this.state.emailUser) {
              case "admin@textilescross.com":
                return indexRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "ddp@textilescross.com":
                return ddpRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "comercial@textilescross.com":
                return comercialRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "telas@textilescross.com":
                return telasRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "corte@textilescross.com":
                return corteRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "costura@textilescross.com":
                return costuraRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "acabados@textilescross.com":
                return acabadoRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "pcp@textilescross.com":
                return ingenieriaRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              case "almacen@textilescross.com":
                return almacenRoutes.map((prop, key) => {
                  return (
                    <PrivateRoute
                      path={prop.path}
                      key={key}
                      component={prop.component}
                      authenticated={this.state.authenticated}
                    />
                  );
                });
              default:
                console.log("");
            }
          })()}
        </Switch>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
