import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  FormGroup,
  Col,
  Input as In,
  Label,
} from "reactstrap";
import firebase from "Firebase.js";
import { notification, message, Button, Input } from "antd";
import "antd/dist/antd.css";

class UpdateAlmacenRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("almacen");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      isOpen: false,
      caja: "",
      cantIngresada: "",
      color: null,
      op: null,
      img: null,
      value: null,
      saldo: null,
      comercialId: null,
      comercialIndex: null,
      isActive: false,
    };
    this.changeImage = this.changeImage.bind(this);
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const { colorState } = this.props.location.state;

    firebase
      .firestore()
      .collection("corte")
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const corteTotal = doc.data().otros[0].total;
          firebase
            .firestore()
            .collection("acabados")
            .where("op", "==", ordenPedido)
            .where("color", "==", colorState)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const cantSalida = doc.data().otros[0].cantSalida;
                this.setState({
                  saldo: Number(corteTotal) - Number(cantSalida),
                });
              });
            });
        });
      });

    this.ref
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            value: doc.data().index,
            color: colorState,
            op: ordenPedido,
          });
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  changeImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      this.setState(() => ({ img }));
    }
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    message.loading("Registrando...", 60);
    let {
      fechaIngreso,
      cantIngresada: cantAlmacen,
      caja,
      value,
      color,
      op,
      img,
      saldo,
      comercialId,
      comercialIndex,
    } = this.state;
    this.setState({
      isActive: false,
    });
    fechaIngreso = moment(fechaIngreso).format("DD/MM/YYYY");

    if (img == null) {
      try {
        firebase
          .firestore()
          .collection("comercial")
          .doc(comercialId)
          .get()
          .then((doc) => {
            let otros = doc.data().otros;
            otros[comercialIndex] = {
              ...otros[comercialIndex],
              almacen: {
                almacenFecha: fechaIngreso,
                cantAlmacen,
                caja,
                saldo,
              },
            };

            firebase
              .firestore()
              .collection("comercial")
              .doc(comercialId)
              .set({ otros }, { merge: true });
          });
      } catch (error) {
        console.error("Error adding document: ", error);
      }

      try {
        firebase
          .firestore()
          .collection("acabados")
          .where("op", "==", op)
          .where("color", "==", color)
          .get()
          .then((qs) => {
            qs.forEach((doc) => {
              firebase
                .firestore()
                .collection("acabados")
                .doc(doc.id)
                .update({
                  isEditable: false,
                });
            });
          });
      } catch (e) {
        console.error(e.message);
      }

      this.ref
        .doc(value)
        .set(
          {
            isEditable: false,
            otros: [
              {
                almacenFecha: fechaIngreso,
                caja: Number(caja) || "",
                cantAlmacen: Number(cantAlmacen) || "",
                color,
                saldo: Number(saldo),
                url: "",
              },
            ],
            estado: ["Listo", op, color],
          },
          { merge: true }
        )
        .then((_) => {
          message.destroy();
          const args = {
            message: "Registro agregada exitosamente.",
            top: 80,
          };
          notification.success(args);
          this.setState({
            fechaIngreso: new Date(),
            caja: "",
            cantIngresada: "",
            selectedOption: "",
          });
          this.props.history.push("/Almacen/Procesos");
        })
        .catch((error) => console.error("Error adding document: ", error));
    } else {
      const uploadTask = this.storageRef.ref(`imgprenda/${img.name}`).put(img);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          alert("Ingrese Imagen: ", error);
        },
        () => {
          this.storageRef
            .ref("imgprenda")
            .child(img.name)
            .getDownloadURL()
            .then((url) => {
              try {
                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .get()
                  .then((doc) => {
                    let otros = doc.data().otros;
                    otros[comercialIndex] = {
                      ...otros[comercialIndex],
                      almacen: {
                        almacenFecha: fechaIngreso,
                        cantAlmacen,
                        caja,
                        saldo,
                      },
                    };

                    firebase
                      .firestore()
                      .collection("comercial")
                      .doc(comercialId)
                      .set({ otros }, { merge: true });
                  });
              } catch (error) {
                console.error("Error adding document: ", error);
              }

              try {
                firebase
                  .firestore()
                  .collection("acabados")
                  .where("op", "==", op)
                  .where("color", "==", color)
                  .get()
                  .then((qs) => {
                    qs.forEach((doc) => {
                      firebase
                        .firestore()
                        .collection("acabados")
                        .doc(doc.id)
                        .update({
                          isEditable: false,
                        });
                    });
                  });
              } catch (e) {
                console.error(e.message);
              }

              this.ref
                .doc(value)
                .set(
                  {
                    isEditable: false,
                    otros: [
                      {
                        almacenFecha: fechaIngreso,
                        caja: Number(caja) || "",
                        cantAlmacen: Number(cantAlmacen) || "",
                        color,
                        saldo: Number(saldo),
                        url,
                      },
                    ],
                    estado: ["Listo", op, color],
                  },
                  { merge: true }
                )
                .then((_) => {
                  message.destroy();
                  const args = {
                    message: "Registro agregada exitosamente.",
                  };
                  notification.success(args);
                  this.setState({
                    fechaIngreso: new Date(),
                    caja: "",
                    cantIngresada: "",
                    selectedOption: "",
                  });
                  this.props.history.push("/Almacen/Procesos");
                })
                .catch((error) =>
                  console.error("Error adding document: ", error)
                );
            });
        }
      );
    }
    this.setState({
      isActive: true,
    });
  };

  render() {
    const { caja, cantIngresada } = this.state;
    const isInvalid = caja === "" || cantIngresada === "";

    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">Registro Almacen</CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label className="font-weight-bold mt-3">
                      ORDEN DE PRODUCCION
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">CANTIDAD</Label>
                    <Input
                      name="cantIngresada"
                      className="col-md-3 d-block"
                      value={cantIngresada}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">NUMERO DE CAJA</Label>
                    <Input
                      name="caja"
                      className="col-md-3 d-block"
                      value={caja}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Label className="font-weight-bold">IMAGEN</Label>
                  <In
                    type="file"
                    onChange={this.changeImage}
                    className="d-block"
                  />
                  <Row>
                    <div className="update ml-auto mr-auto mt-3">
                      <Button
                        type="primary"
                        disabled={
                          this.state.isActive || isInvalid ? true : false
                        }
                        onClick={this.onSubmit}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateAlmacenRecord;
