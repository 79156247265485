import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import {
  Upload,
  Icon,
  notification,
  message,
  Button,
  DatePicker,
  Select,
  Input,
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  getClientList,
  createComercialRecord,
  createPcpRecord,
  handleChangeSwift,
  customUploadSwift,
} from "services";
import firebase from "../../../Firebase.js";
const { Option } = Select;

class NewComercialRegister extends React.Component {
  constructor() {
    super();
    this.storageRef = firebase.storage();
    this.state = {
      loading: false,
      fechaIngreso: new Date(),
      cliente: "",
      prof: "",
      fechaEntrega: new Date(),
      imgProf: null,
      imgProfsp: null,
      clientsList: [],
      selectedOption: "",
      isActive: false,
      proforma: [],
      selectedFile: null,
      selectedFileList: [],
      pdfUrl: "",
      filelistVenta: null,
      filelistProd: null,
      swift30: "",
      swift70: "",
      swift30file: [],
      swift70file: [],
      filelistsw30: null,
      filelistsw70: null,
    };
  }

  handleClientList = async () => {
    this.setState({
      loading: true,
    });
    const data = await getClientList();
    this.setState({
      loading: false,
      clientsList: data,
    });
  };

  componentWillMount() {
    this.handleClientList();

    firebase
      .firestore()
      .collection("comercial")
      .orderBy("order", "asc")
      .get()
      .then((querySnap) => {
        const prof = [];
        const swf30 = [];
        const swf70 = [];
        querySnap.forEach((doc) => {
          if (doc.data().prof) {
            prof.push(doc.data().prof);
          }
          if (doc.data().sw30) {
            swf30.push(doc.data().sw30);
          }
          if (doc.data().sw70) {
            swf70.push(doc.data().sw70);
          }
        });
        this.setState({
          proforma: prof,
          swift30file: swf30,
          swift70file: swf70,
        });
      });
  }

  handleChangeIngreso = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChangeEntrega = (date) => {
    this.setState({
      fechaEntrega: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  changeProf = (e) => {
    if (e.target.files[0]) {
      const imgProf = e.target.files[0];
      this.setState({ imgProf, prof: imgProf.name.split(".")[0] });
    }
  };

  changeProfsp = (e) => {
    if (e.target.files[0]) {
      const imgProfsp = e.target.files[0];
      this.setState({ imgProfsp });
    }
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleChangeProd = (info) => {
    let fileListProd = [...info.fileList];

    fileListProd = fileListProd.slice(-1);

    fileListProd = fileListProd.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelistProd: info.fileList, fileListProd });

    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUploadProd = async ({ onError, onSuccess, file }) => {
    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`profProd/${file.name}`);
    if (this.state.proforma.includes(file.name.split(".")[0])) {
      const msg = {
        message: `El archivo: ${
          file.name.split(".")[0]
        } Ya existe, cargar un archivo diferente`,
        top: 80,
      };
      notification.error(msg);
      onError(msg);
    } else {
      try {
        const pdf = await pdfFile.put(file, metadata);
        const url = await this.storageRef
          .ref("profProd")
          .child(file.name)
          .getDownloadURL();
        onSuccess(null, { ...pdf, url });
      } catch (e) {
        onError(e.message);
      }
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const args = {
      message: "Registro agregado existosamente.",
      top: 80,
    };

    const {
      fechaIngreso,
      fechaEntrega,
      prof,
      selectedOption,
      swift30,
      swift70,
    } = this.state;

    this.setState({
      isActive: true,
    });

    const fechaEntregaFormat = moment(fechaEntrega).format("DD/MM/YYYY");
    const fechaIngresoFormat = moment(fechaIngreso).format("DD/MM/YYYY");

    message.loading("Registrando..", 60);

    try {
      const profVentaUrl = this.state.filelistVenta[0].xhr.url;
      const profProdUrl = this.state.filelistProd[0].xhr.url;
      const sw30furl =
        this.state.filelistsw30 && this.state.filelistsw30.length
          ? this.state.filelistsw30[0].xhr.url
          : "";
      const sw70furl =
        this.state.filelistsw70 && this.state.filelistsw70.length
          ? this.state.filelistsw70[0].xhr.url
          : "";

      await createComercialRecord({
        a: [profVentaUrl],
        b: [],
        cFechaEntrega: fechaEntregaFormat,
        cFechaIngreso: fechaIngresoFormat,
        cliente: selectedOption,
        estado: "",
        f: [],
        fichaTecnica: "",
        op: "",
        otros: [],
        prof: prof,
        profsp: [profProdUrl],
        status: "Nuevo",
        isEditable: true,
        sw30Files: [sw30furl],
        sw70Files: [sw70furl],
        sw30: swift30,
        sw70: swift70,
      });

      await createPcpRecord({
        cFechaEntrega: fechaEntregaFormat,
        cliente: selectedOption,
        op: "",
        cFechaIngreso: fechaIngresoFormat,
        prof: prof,
        a: [profProdUrl],
        fichaTecnica: "",
        estado: ["Nuevo", prof],
        b: [],
        otros: [],
        isEditable: true,
      });

      this.setState({
        fechaIngreso: new Date(),
        selectedOption: "",
        prof: "",
        profsp: "",
        fechaEntrega: new Date(),
      });

      notification.success(args);
    } catch (error) {
      alert(`Se encontro un error, vuelva a intentarlo. ${error.message}`);
    } finally {
      message.destroy();

      this.setState({
        fechaIngreso: new Date(),
        selectedOption: "",
        prof: "",
        profsp: "",
        fechaEntrega: new Date(),
        isActive: false,
      });
      this.props.history.push("/comercial/Procesos");
    }
  };

  render() {
    const { selectedOption, prof, fechaEntrega, loading, pdfUrl } = this.state;
    const isInvalid =
      selectedOption === "" ||
      prof === "" ||
      fechaEntrega === null ||
      !(
        this.state.fileListVenta ||
        this.state.fileListVenta.every((item) => item.status === "done")
      ) ||
      !(
        this.state.fileListProd &&
        this.state.fileListProd.every((item) => item.status === "done")
      );

    const uploadButton = (
      <div>
        <Button
          className="mt-2"
          style={{ display: "block", textAlign: "left" }}
        >
          <Icon type={loading ? "loading" : "plus"} />
          Seleccionar Archivo
        </Button>
      </div>
    );

    const handleState = (fileListSw, secondaryFileListSw, info) => {
      const infofileList = [...info.fileList];
      if (info.fileList.length > 1) {
        infofileList.splice(0, 1);
      }
      const fileResponse = infofileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.setState({
        [fileListSw]: info.fileList,
        [secondaryFileListSw]: fileResponse,
      });
    };

    const handleLoadingState = (loading) => {
      this.setState({
        loading: loading,
      });
    };

    const handleUploadfile = (file, nameState) => {
      this.setState({
        [nameState]: file.name.split(".")[0],
      });
    };

    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">
                  REGISTRO COMERCIAL
                </CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label for="cliente" className="font-weight-bold">
                      CLIENTE
                    </Label>
                    <Select
                      className="col-md-3 d-block"
                      placeholder="Eliga Cliente"
                      onChange={this.selectHandleChange}
                      value={this.state.selectedOption}
                      required
                      showSearch={true}
                    >
                      {this.state.clientsList.map((item, idx) => (
                        <Option key={idx} value={item.client.name}>
                          {item.client.name}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="prof" className="font-weight-bold">
                      FACTURA PROFORMA
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      name="prof"
                      value={prof}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="fechaEntrega" className="font-weight-bold">
                      FECHA DESPACHO
                    </Label>
                    <DatePicker
                      className="col-md-3 d-block"
                      value={
                        this.state.fechaEntrega
                          ? moment(this.state.fechaEntrega, "DD/MM/YYYY")
                          : null
                      }
                      onChange={this.handleChangeEntrega}
                      format={"DD/MM/YYYY"}
                      locale={locale}
                    />
                  </FormGroup>

                  <Label className="font-weight-bold">
                    FACTURA PROFORMA CON PRECIO
                  </Label>
                  <Row>
                    <Col md={3} xs={3} lg={3} xl={3}>
                      <Upload
                        onRemove={() =>
                          this.setState({ filelistVenta: null, prof: "" })
                        }
                        accept={".pdf"}
                        multiple={false}
                        onChange={(info) =>
                          handleChangeSwift(
                            info,
                            "filelistVenta",
                            "fileListVenta",
                            handleState,
                            handleLoadingState
                          )
                        }
                        customRequest={({ onError, onSuccess, file }) =>
                          customUploadSwift({
                            onError: onError,
                            onSuccess: onSuccess,
                            file: file,
                            folder: "profVenta",
                            stateList: this.state.proforma,
                            nameState: "prof",
                            handleUploadfile: handleUploadfile,
                            notification: notification,
                          })
                        }
                        fileList={this.state.fileListVenta}
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {pdfUrl ? (
                          <Button icon={<Icon type="upload" />}></Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                  </Row>
                  <Label className="font-weight-bold mt-3">
                    FACTURA PROFORMA SIN PRECIO
                  </Label>
                  <Row>
                    <Col md={3} xs={3} lg={3} xl={3}>
                      <Upload
                        onRemove={() =>
                          this.setState({ filelistProd: null, prof: "" })
                        }
                        accept={".pdf"}
                        multiple={false}
                        onChange={this.handleChangeProd}
                        customRequest={this.customUploadProd}
                        fileList={this.state.fileListProd}
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {pdfUrl ? (
                          <Button icon={<Icon type="upload" />}></Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                  </Row>

                  <Label className="font-weight-bold mt-3">
                    SWIFT DE TRANSFERENCIA 30%
                  </Label>
                  <Row>
                    <Col md={3} xs={3} lg={3} xl={3}>
                      <Upload
                        onRemove={() => this.setState({ filelistsw30: null })}
                        accept={".pdf, .jpeg, .jpg, .png, .img, tiff, .bmp"}
                        multiple={false}
                        onChange={(info) =>
                          handleChangeSwift(
                            info,
                            "filelistsw30",
                            "fileListsw30",
                            handleState,
                            handleLoadingState
                          )
                        }
                        customRequest={({ onError, onSuccess, file }) =>
                          customUploadSwift({
                            onError: onError,
                            onSuccess: onSuccess,
                            file: file,
                            folder: "swComercial",
                            stateList: this.state.swift30file,
                            nameState: "swift30",
                            handleUploadfile: handleUploadfile,
                            notification: notification,
                          })
                        }
                        fileList={this.state.fileListsw30}
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {pdfUrl ? (
                          <Button icon={<Icon type="upload" />}></Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                  </Row>

                  <Label className="font-weight-bold mt-3">
                    SWIFT DE TRANSFERENCIA 70%
                  </Label>
                  <Row>
                    <Col md={3} xs={3} lg={3} xl={3}>
                      <Upload
                        onRemove={() => this.setState({ filelistsw70: null })}
                        accept={".pdf, .jpeg, .jpg, .png, .img, tiff, .bmp"}
                        multiple={false}
                        onChange={(info) =>
                          handleChangeSwift(
                            info,
                            "filelistsw70",
                            "fileListsw70",
                            handleState,
                            handleLoadingState
                          )
                        }
                        customRequest={({ onError, onSuccess, file }) =>
                          customUploadSwift({
                            onError: onError,
                            onSuccess: onSuccess,
                            file: file,
                            folder: "swComercial",
                            stateList: this.state.swift70file,
                            nameState: "swift70",
                            handleUploadfile: handleUploadfile,
                            notification: notification,
                          })
                        }
                        fileList={this.state.fileListsw70}
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {pdfUrl ? (
                          <Button icon={<Icon type="upload" />}></Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={this.state.isActive || isInvalid}
                        style={{
                          marginTop: "40px",
                          fontSize: "15px",
                        }}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewComercialRegister;
