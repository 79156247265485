import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { Upload, Icon, notification, message, DatePicker, Button } from "antd";
import Select from "react-select";
import locale from "antd/es/date-picker/locale/es_ES";
import "react-datepicker/dist/react-datepicker.css";
import { handleChangeSwift, customUploadSwift } from "services";

class EditComercialRegister extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("comercial");
    this.storageRef = firebase.storage();
    this.state = {
      fechaIngreso: new Date(),
      fechaEntrega: new Date(),
      oldFechaIngreso: null,
      oldFechaEntrega: null,
      oldProf: null,
      oldCliente: null,
      prof: "",
      isOpen2: false,
      doc: "",
      clientes: [],
      selectedOption: null,
      imgProf: "",
      imgProfsp: "",
      data: null,
      cliente: null,
      profor: null,
      pcpvalue: "",
      isActive: false,
      fpSinPrecioURL: null,
      proforma: [],
      loading: false,
      selectedFile: null,
      selectedFileList: [],
      pdfUrl: "",
      filelistVenta: null,
      filelistProd: null,
      fileListVentas: null,
      fileListProduc: null,
      uploading: false,
      swift30: "",
      swift70: "",
      swift30file: [],
      swift70file: [],
      filelistsw30: null,
      filelistsw70: null,
      fileListsw30: null,
      fileListsw70: null,
    };
  }

  componentDidMount() {
    const {
      doc,
      prof,
      a,
      profsp,
      sw30F,
      sw70F,
      swift30,
      swift70,
    } = this.props.location.state;
    const pdfVenta = a;
    const pdfProd = profsp;
    const pfAnterior = prof;
    const siwft30f = sw30F;
    const siwft70f = sw70F;
    const swift30name = swift30;
    const swift70name = swift70;

    firebase
      .firestore()
      .collection("comercial")
      .orderBy("order", "asc")
      .get()
      .then((querySnap) => {
        const prof = [];
        const swf30 = [];
        const swf70 = [];
        querySnap.forEach((doc) => {
          if (doc.data().prof) {
            prof.push(doc.data().prof);
          }
          if (doc.data().sw30) {
            swf30.push(doc.data().sw30);
          }
          if (doc.data().sw70) {
            swf70.push(doc.data().sw70);
          }
        });
        this.setState({
          proforma: prof,
          wift30file: swf30,
          swift70file: swf70,
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .where("prof", "==", prof)
      .get()
      .then((querySnapshot) => {
        let pcpvalue = null;
        querySnapshot.forEach((doc) => {
          pcpvalue = doc.data().index;
        });
        this.setState({ pcpvalue });
      });

    firebase
      .firestore()
      .collection("clientes")
      .get()
      .then((querySnapshot) => {
        var clientes = [];
        querySnapshot.forEach(function(doc) {
          clientes.push({
            label: doc.data().name,
            value: doc.id,
          });
        });
        this.setState({ clientes: clientes });
      });

    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    }

    this.ref
      .doc(String(doc))
      .get()
      .then((doc) => {
        const dataComercial = doc.data();

        const fechaIngreso = new Date(
          arraymove(dataComercial.cFechaIngreso.split("/"), 0, 1).join("/")
        );
        const fechaEntrega = new Date(
          arraymove(dataComercial.cFechaEntrega.split("/"), 0, 1).join("/")
        );
        let selectedOption = { label: dataComercial.cliente };

        this.setState({
          data: doc.data(),
          doc,
          prof,
          selectedOption,
          fechaIngreso,
          fechaEntrega,
          oldFechaEntrega: fechaEntrega,
          oldFechaIngreso: fechaIngreso,
          oldCliente: doc.data().cliente,
          oldProf: prof,
        });
      });

    this.setState({
      pfAnterior,
      doc: doc,
      prof: prof,
      pdfVenta,
      pdfProd,
      swift30: swift30name,
      swift70: swift70name,
      siwft30f,
      siwft70f,
      fileListVentas: [
        {
          uid: doc,
          name: prof,
          status: "done",
          url: pdfVenta,
        },
      ],
      fileListProduc: [
        {
          uid: doc,
          name: prof,
          status: "done",
          url: pdfProd,
        },
      ],
      fileListsw30: siwft30f
        ? [
            {
              uid: doc,
              name: swift30name,
              status: "done",
              url: siwft30f,
            },
          ]
        : null,
      fileListsw70: siwft70f
        ? [
            {
              uid: doc,
              name: swift70name,
              status: "done",
              url: siwft70f,
            },
          ]
        : null,
    });
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChange2 = (date) => {
    this.setState({
      fechaEntrega: date,
    });
  };

  selectHandleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  changeProf = (e) => {
    if (e.target.files[0]) {
      const imgProf = e.target.files[0];
      this.setState(() => ({ imgProf }));
      this.setState({ prof: imgProf.name.split(".")[0] });
    }
  };

  changeProfsp = (e) => {
    if (e.target.files[0]) {
      const imgProfsp = e.target.files[0];
      this.setState(() => ({ imgProfsp }));
    }
  };

  handleChangeProd = (info) => {
    let fileListProduc = [...info.fileList];

    fileListProduc = fileListProduc.slice(-1);

    fileListProduc = fileListProduc.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelistProd: info.fileList, fileListProduc });

    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUploadProd = async ({ onError, onSuccess, file }) => {
    this.setState({ uploading: true });
    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`profProd/${file.name}`);
    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("profProd")
        .child(file.name)
        .getDownloadURL();
      this.setState({
        uploading: false,
      });
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      fechaIngreso,
      fechaEntrega,
      doc,
      prof,
      selectedOption,
      pcpvalue,
      proforma,
      pfAnterior,
      pdfVenta,
      pdfProd,
      siwft30f,
      siwft70f,
      swift30,
      swift70,
    } = this.state;

    this.setState({
      isActive: true,
    });

    const consultaUno = proforma.includes(prof);

    if (pfAnterior === prof || !consultaUno) {
      const msg = {
        message: `La FP "${pfAnterior}" se cambio a "${prof}" con exito.`,
        top: 80,
      };
      notification.success(msg);

      message.loading("Registrando...", 80);
      const opSelected = selectedOption;
      const opName = opSelected.label;

      const fechaEntregaFormat = moment(fechaEntrega).format("DD/MM/YYYY");
      const NfechaIngresoFormat = moment(fechaIngreso).format("DD/MM/YYYY");

      const opWithPrice =
        this.state.filelistVenta && this.state.filelistVenta.length
          ? this.state.filelistVenta[0].xhr.url
          : pdfVenta;

      const okWithoutPrice =
        this.state.filelistProd && this.state.filelistProd.length
          ? this.state.filelistProd[0].xhr.url
          : pdfProd;

      const swf30url =
        this.state.filelistsw30 && this.state.filelistsw30.length
          ? this.state.filelistsw30[0].xhr.url
          : siwft30f;

      const swf70url =
        this.state.filelistsw70 && this.state.filelistsw70.length
          ? this.state.filelistsw70[0].xhr.url
          : siwft70f;

      const data = {
        cFechaEntrega: fechaEntregaFormat || "",
        cliente: opName || "",
        prof: prof || "",
        cFechaIngreso: NfechaIngresoFormat || "",
        a: [opWithPrice || ""],
        profsp: [okWithoutPrice || ""],
        sw30Files: [swf30url || ""],
        sw70Files: [swf70url || ""],
        sw30: swift30,
        sw70: swift70,
      };

      try {
        this.ref
          .doc(doc.id)
          .update(data)
          .then((_) => {
            message.destroy();
            const args = {
              message: "Registro agregado existosamente.",
              top: 80,
            };
            notification.success(args);
            this.props.history.push("/comercial/Procesos");
          });
      } catch (error) {
        console.error(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("ing")
          .doc(String(pcpvalue))
          .get()
          .then((doc) => {
            let pcp = doc.data();
            pcp.cFechaIngreso = NfechaIngresoFormat || "";
            pcp.prof = prof || "";
            pcp.a = [okWithoutPrice || ""];
            pcp.cliente = opName || "";
            pcp.cFechaEntrega = fechaEntregaFormat || "";
            pcp.estado = [pcp.estado[0], prof];
            try {
              firebase
                .firestore()
                .collection("ing")
                .doc(String(pcpvalue))
                .update(pcp);
            } catch (error) {
              console.error(error.message);
            }
          });
      } catch (error) {
        console.error(error.message);
      }
    } else {
      const msg = {
        message: `La FACTURA PROFORMA "${prof}" ya existe.`,
        top: 80,
      };
      notification.success(msg);
      this.setState({
        isActive: false,
      });
    }
    setTimeout(() => {
      this.setState({
        isActive: false,
      });
    }, 8000);
  };

  render() {
    const { prof, selectedOption, fechaEntrega, loading, pdfUrl } = this.state;

    const isInvalid =
      selectedOption === "" ||
      prof === "" ||
      fechaEntrega === null ||
      (this.state.fileListVenta &&
        this.state.fileListVenta.every((item) => item.status === "done")) ||
      (this.state.fileListProd &&
        this.state.fileListProd.every((item) => item.status === "done"));

    const uploadButton = (
      <div>
        <Button
          className="mt-2 ml-3"
          style={{ display: "block", textAlign: "left" }}
        >
          <Icon type={loading ? "loading" : "plus"} />
          Seleccionar Archivo
        </Button>
      </div>
    );

    const handleState = (fileListSw, secondaryFileListSw, info) => {
      const infofileList = [...info.fileList];
      if (info.fileList.length > 1) {
        infofileList.splice(0, 1);
      }
      const fileResponse = infofileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.setState({
        [fileListSw]: info.fileList,
        [secondaryFileListSw]: fileResponse,
      });
    };

    const handleLoadingState = (loading) => {
      this.setState({
        loading: loading,
      });
    };

    const handleUploadfile = (file, nameState) => {
      this.setState({
        [nameState]: file.name.split(".")[0],
      });
    };

    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ marginLeft: "15px" }}>
                  ACTUALIZAR REGISTRO
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <Label
                      for="cliente"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      CLIENTE
                    </Label>
                    <Select
                      className="col-md-8 d-block"
                      placeholder="Eliga Cliente"
                      onChange={this.selectHandleChange}
                      name="cliente"
                      value={selectedOption}
                      required
                      isSearchable={true}
                      options={this.state.clientes}
                    />
                  </FormGroup>

                  <FormGroup
                    style={{
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    <Label for="prof">FACTURA PROFORMA</Label>
                    <Input
                      type="text"
                      name="prof"
                      id="prof"
                      className="col-md-8 d-block"
                      value={prof}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="fechaEntrega"
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                    >
                      FECHA DESPACHO
                    </Label>
                    <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                      <DatePicker
                        className="col-md-8 d-block"
                        value={
                          this.state.fechaEntrega
                            ? moment(this.state.fechaEntrega, "DD/MM/YYYY")
                            : null
                        }
                        onChange={this.handleChange2}
                        format="DD/MM/YYYY"
                        locale={locale}
                        selected={fechaEntrega}
                      />
                    </div>
                  </FormGroup>

                  <Row>
                    <Label
                      className="font-weight-bold mt-3"
                      style={{
                        marginLeft: "30px",
                        marginTop: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      FACTURA PROFORMA CON PRECIO
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() =>
                      this.setState({ filelistVenta: null, prof: "" })
                    }
                    accept={".pdf"}
                    multiple={false}
                    onChange={(info) =>
                      handleChangeSwift(
                        info,
                        "filelistVenta",
                        "fileListVentas",
                        handleState,
                        handleLoadingState
                      )
                    }
                    customRequest={({ onError, onSuccess, file }) =>
                      customUploadSwift({
                        onError: onError,
                        onSuccess: onSuccess,
                        file: file,
                        folder: "profVenta",
                        stateList: this.state.proforma,
                        nameState: "prof",
                        handleUploadfile: handleUploadfile,
                        notification: notification,
                      })
                    }
                    fileList={this.state.fileListVentas}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  <Row>
                    <Label
                      style={{
                        marginLeft: "30px",
                        marginTop: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      FACTURA PROFORMA SIN PRECIO
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() =>
                      this.setState({ filelistProd: null, prof: "" })
                    }
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangeProd}
                    customRequest={this.customUploadProd}
                    fileList={this.state.fileListProduc}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  <Label
                    style={{
                      marginLeft: "15px",
                      marginTop: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    SWIFT DE TRANSFERENCIA 30%
                  </Label>
                  <Row>
                    <Col md={6} xs={6} lg={6} xl={6}>
                      <Upload
                        onRemove={() => this.setState({ filelistsw30: null })}
                        accept={".pdf, .jpeg, .jpg, .png, .img, tiff, .bmp"}
                        multiple={false}
                        onChange={(info) =>
                          handleChangeSwift(
                            info,
                            "filelistsw30",
                            "fileListsw30",
                            handleState,
                            handleLoadingState
                          )
                        }
                        customRequest={({ onError, onSuccess, file }) =>
                          customUploadSwift({
                            onError: onError,
                            onSuccess: onSuccess,
                            file: file,
                            folder: "swComercial",
                            stateList: this.state.swift30file,
                            nameState: "swift30",
                            handleUploadfile: handleUploadfile,
                            notification: notification,
                          })
                        }
                        fileList={this.state.fileListsw30}
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {pdfUrl ? (
                          <Button icon={<Icon type="upload" />}></Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                  </Row>

                  <Label
                    style={{
                      marginLeft: "15px",
                      marginTop: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    SWIFT DE TRANSFERENCIA 70%
                  </Label>
                  <Row>
                    <Col md={6} xs={6} lg={6} xl={6}>
                      <Upload
                        onRemove={() => this.setState({ filelistsw70: null })}
                        accept={".pdf, .jpeg, .jpg, .png, .img, tiff, .bmp"}
                        multiple={false}
                        onChange={(info) =>
                          handleChangeSwift(
                            info,
                            "filelistsw70",
                            "fileListsw70",
                            handleState,
                            handleLoadingState
                          )
                        }
                        customRequest={({ onError, onSuccess, file }) =>
                          customUploadSwift({
                            onError: onError,
                            onSuccess: onSuccess,
                            file: file,
                            folder: "swComercial",
                            stateList: this.state.swift70file,
                            nameState: "swift70",
                            handleUploadfile: handleUploadfile,
                            notification: notification,
                          })
                        }
                        fileList={this.state.fileListsw70}
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {pdfUrl ? (
                          <Button icon={<Icon type="upload" />}></Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                  </Row>

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      Registro ingresado
                    </Alert>
                  </FormGroup>
                  <Row style={{ marginTop: "70px" }}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        type="primary"
                        style={{ marginTop: "20px" }}
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive ||
                          this.state.uploading ||
                          isInvalid
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditComercialRegister;
