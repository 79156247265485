import React from "react";
import moment from "moment";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, message, Button, DatePicker, Input } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "antd/dist/antd.css";

class UpdateLiqAcabadosRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("acabados");
    this.state = {
      isOpen2: false,
      fechaSalida: new Date(),
      cantidadSalida: "",
      op: null,
      value: null,
      color: null,
      label: null,
      comercialId: null,
      comercialIndex: null,
      isActive: false,
      cantSalidaAcabado: null,
      cantEntradaAcabado: null,
    };
  }

  componentDidMount() {
    const { ordenPedido } = this.props.location.state;
    const { colorState } = this.props.location.state;
    const { cantSalidaAcabado, cantEntradaAcabado } = this.props.location.state;
    this.setState({
      cantSalidaAcabado: cantSalidaAcabado,
      cantEntradaAcabado: cantEntradaAcabado,
    });

    this.ref
      .where("op", "==", ordenPedido)
      .where("color", "==", colorState)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            value: doc.data().order,
            color: colorState,
            op: ordenPedido,
          });
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      fechaSalida,
      cantidadSalida,
      value,
      comercialId,
      comercialIndex,
      cantSalidaAcabado,
      cantEntradaAcabado,
    } = this.state;
    const restoTotal = Number(cantEntradaAcabado) - Number(cantSalidaAcabado);
    if (cantidadSalida <= restoTotal) {
      this.setState({
        isActive: false,
      });
      message.loading("Registrando...", 60);
      fechaSalida = moment(fechaSalida).format("DD/MM/YYYY");

      this.ref
        .doc(String(value))
        .get()
        .then((res) => {
          const acabado = res.data();

          acabado.otros[0] = {
            ...acabado.otros[0],
            fechaSalidaLiq: fechaSalida,
            cantSalidaLiq: Number(cantidadSalida),
          };

          acabado.estado[0] = "Liquidado";
          try {
            firebase
              .firestore()
              .collection("comercial")
              .doc(comercialId)
              .get()
              .then((doc) => {
                let otros = doc.data().otros;
                otros[comercialIndex] = {
                  ...otros[comercialIndex],
                  acabado: {
                    ...otros[comercialIndex].acabado,
                    fechaSalidaLiq: fechaSalida,
                    cantidadSalidaLiq: cantidadSalida,
                  },
                };

                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(comercialId)
                  .set({ otros }, { merge: true });
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }

          try {
            this.ref
              .doc(String(value))
              .update(acabado)
              .then((_) => {
                message.destroy();
                const args = {
                  message: "Registrado agregado exitosamente.",
                  top: 80,
                };
                notification.success(args);
                this.setState({
                  fechaSalida: new Date(),
                  cantidadSalida: "",
                });
                this.props.history.push("/Acabado/Procesos");
              });
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        });
      this.setState({
        isActive: true,
      });
    } else {
      this.setState({
        isActive: false,
      });
      const msg = {
        message: `La cantidad liquidad: ${cantidadSalida}, no debe ser mayor a la cantidad Restante: ${restoTotal}`,
        top: 80,
      };
      notification.error(msg);
    }
  };

  render() {
    const { cantidadSalida } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle className="text-center">
                  INGRESO DE LIQUIDACION
                </CardTitle>
              </CardHeader>
              <CardBody>
                <form>
                  <Label className="font-weight-bold">
                    FECHA DE SALIDA - LIQUIDACION
                  </Label>
                  <DatePicker
                    className="col-md-3 d-block"
                    defaultValue={moment(this.state.fechaSalida, "DD/MM/YYYY")}
                    onChange={this.handleChangeSalida}
                    format="DD/MM/YYYY"
                    locale={locale}
                  />
                  <FormGroup>
                    <Label className="font-weight-bold">
                      ORDEN DE PRODUCCION
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">
                      CANTIDAD LIQUIDADA
                    </Label>
                    <Input
                      name="cantidadSalida"
                      className="col-md-3 d-block"
                      value={cantidadSalida}
                      onChange={this.onChange}
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto mt-3">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive || !cantidadSalida ? true : false
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateLiqAcabadosRecord;
