import React, { Component } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "./Login.css";
import firebase from "../../Firebase";
import { withRouter } from "react-router";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  handleSignIn = async (event) => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email.value, password.value);

      switch (email.value) {
        case "admin@textilescross.com":
          this.props.history.push("/admin/menu");
          break;
        case "comercial@textilescross.com":
          this.props.history.push("/Comercial");
          break;
        case "ddp@textilescross.com":
          this.props.history.push("/Ddp");
          break;
        case "pcp@textilescross.com":
          this.props.history.push("/Ingenieria");
          break;
        case "telas@textilescross.com":
          this.props.history.push("/Telas");
          break;
        case "corte@textilescross.com":
          this.props.history.push("/Corte");
          break;
        case "costura@textilescross.com":
          this.props.history.push("/Costura");
          break;
        case "almacen@textilescross.com":
          this.props.history.push("/Almacen");
          break;
        case "acabados@textilescross.com":
          this.props.history.push("/Acabado");
          break;
        default:
          this.props.history.push("/");
          alert("Correo y/o contraseña incorrecta");
      }
    } catch (error) {
      alert(error);
    }
  };

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 5;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSignIn} className="Login">
          <FormGroup>
            <Label style={{ fontSize: "1.2em" }}>Correo</Label>
            <Input
              autoFocus
              type="email"
              name="email"
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label style={{ fontSize: "1.2em" }}>Contraseña</Label>
            <Input
              type="password"
              name="password"
              onChange={this.handleChange}
            />
          </FormGroup>
          <Button
            block
            disabled={this.validateForm()}
            type="submit"
            style={{ fontSize: "15px" }}
          >
            Iniciar Sesión
          </Button>
        </form>
      </div>
    );
  }
}

export default withRouter(Login);
