import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import firebase from "../../../Firebase.js";
import { notification, message, Button, DatePicker, Select, Input } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "antd/dist/antd.css";
const { Option } = Select;

class EditTelaRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("telas");

    this.state = {
      fechaIngreso: new Date(),
      op: null,
      fechaSalida: new Date(),
      estado2: "",
      isOpen2: false,
      color: null,
      label: null,
      value: null,
      lastCorteId: null,
      comercialId: null,
      comercialIndex: null,
      isActive: false,
    };
  }

  componentDidMount() {
    const { docId, ordenPedido } = this.props.location.state;
    const colorState = this.props.location.state.color;

    this.ref
      .doc(docId)
      .get()
      .then((doc) => {
        this.setState({
          value: doc.data().order,
          color: doc.data().color,
          op: doc.data().op,
          estado2: doc.data().estado[0],
        });
      });

    firebase
      .firestore()
      .collection("corte")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            lastCorteId: doc.data().index,
          });
        });
      });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", ordenPedido)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({
            comercialIndex: doc
              .data()
              .otros.map((v) => v.color)
              .indexOf(colorState),
            comercialId: doc.id,
          });
        });
      });
  }

  handleChangeIngreso = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  selectHandleChange2 = (selectedOption2) => {
    this.setState({ selectedOption2 });
  };

  onChange = (e) => {
    this.setState({ estado2: e });
  };

  onSubmit2 = (e) => {
    e.preventDefault();
    message.loading("Registrando...", 60);
    const {
      fechaIngreso,
      fechaSalida,
      //estado2,
      value,
      lastCorteId,
      comercialId,
    } = this.state;

    this.setState({
      isActive: false,
    });

    const { estado2 } = this.state;
    const id = Number(lastCorteId) + 1;
    const fechaEntregaFormat = moment(fechaIngreso).format("DD/MM/YYYY");
    const fechaSalidaFormat = moment(fechaSalida).format("DD/MM/YYYY");

    this.ref
      .doc(String(value))
      .get()
      .then((res) => {
        const tela = res.data();
        tela.fechaIngreso = fechaEntregaFormat;
        tela.fechaSalida = fechaSalidaFormat;
        tela.estado[0] = estado2;

        if (estado2 === "Rotulado") {
          let corte = {
            b: tela.b ? tela.b : null,
            cantidad: Number(tela.cantidad),
            color: tela.color,
            cx: tela.cx ? tela.cx : null,
            descripcion: tela.descripcion,
            edit: [String(id), tela.op, tela.color],
            estado: ["Nuevo", tela.op, tela.color],
            fechaIngreso: fechaSalidaFormat,
            ft: tela.ft ? tela.ft : null,
            hojaConsumo: tela.hojaConsumo ? tela.hojaConsumo : null,
            hc: tela.hc ? tela.hc : null,
            index: String(id),
            op: tela.op,
            order: Number(id),
            otros: [],
            isEditable: true,
          };

          firebase
            .firestore()
            .collection("corte")
            .doc(String(id))
            .set(corte)
            .then((error) => error && alert("Error de crear corte"));
        }

        try {
          firebase
            .firestore()
            .collection("comercial")
            .doc(comercialId)
            .get()
            .then((doc) => {
              let otros = doc.data();

              otros.otros.forEach((ids) => {
                if (ids.color === tela.color) {
                  ids.telas = {
                    tEstado: estado2 || "",
                    tFechaIngreso: fechaEntregaFormat || "",
                  };
                }
              });

              firebase
                .firestore()
                .collection("comercial")
                .doc(comercialId)
                .update(otros);
              //.set({ otros }, { merge: true });
            });
        } catch (error) {
          console.error("Error adding document: ", error);
        }

        try {
          firebase
            .firestore()
            .collection("ing")
            .where("op", "==", tela.op)
            .get()
            .then((querySnapshot) => {
              let otros,
                idx = null;
              querySnapshot.forEach((doc) => {
                idx = doc.id;
                otros = doc.data().otros.map((item) => {
                  if (item.color === tela.color) {
                    item.status = [estado2];
                  }
                  return item;
                });
              });
              return { otros, idx };
            })
            .then(({ idx, otros }) => {
              firebase
                .firestore()
                .collection("ing")
                .doc(idx)
                .set({ otros }, { merge: true });
            });
        } catch (error) {
          console.error(error);
        }

        this.ref
          .doc(String(value))
          .update(tela)
          .then((_) => {
            message.destroy();
            const args = {
              message: "Registro agregado exitosamente.",
              top: 80,
            };
            notification.success(args);
            this.setState({
              fechaIngreso: new Date(),
              fechaSalida: new Date(),
              estado2: "",
            });
            this.props.history.push("/Telas/Procesos");
          })
          .catch((error) => {
            alert("Error al registrar: ", error);
          });
      });
    this.setState({
      isActive: true,
    });
  };

  render() {
    const { estado2 } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ textAlign: "center" }}>
                  Registro Telas
                </CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label className="font-weight-bold">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.op}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="font-weight-bold">COLOR</Label>
                    <Input
                      className="col-md-3 d-block"
                      value={this.state.color}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="fechaSalida" className="font-weight-bold">
                      FECHA ENTREGA
                    </Label>
                    <DatePicker
                      className="col-md-3 d-block"
                      defaultValue={
                        this.state.fechaSalida
                          ? moment(this.state.fechaSalida, "DD/MM/YYYY")
                          : null
                      }
                      format="DD/MM/YYYY"
                      locale={locale}
                      onChange={this.handleChangeSalida}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="font-weight-bold">ESTADO</Label>
                    <Select
                      className="col-md-3 d-block"
                      onChange={this.onChange}
                      value={estado2}
                      placeholder="Seleccione estado"
                      required
                      showSearch={true}
                    >
                      <Option value="Tejeduria">Tejeduria</Option>
                      <Option value="Tintoreria">Tintoreria</Option>
                      <Option value="Compras">Compras</Option>
                      <Option value="Rotulado">Rotulado</Option>
                    </Select>
                  </FormGroup>

                  <Row>
                    <div className="update ml-auto mr-auto mt-4">
                      <Button
                        type="primary"
                        onClick={this.onSubmit2}
                        disabled={this.state.isActive}
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditTelaRecord;
