import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import firebase from "../../Firebase";
import Modal from "react-modal";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "10px",
    backgroundColor: "#c8dad3",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class RegularTables extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("telas");
    this.state = {
      datos: [],
      op: [],
      loading: true,
      ft: [],
      hc: [],
      docId: "",
      password: "",
      corteData: [],
      comercialId: null,
      newItem: {},
    };
  }

  async loadData() {
    await firebase
      .firestore()
      .collection("telas")
      .orderBy("order", "desc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((acc, data) => {
          const item = data.data();
          const itemIndex = acc.findIndex((accItem) => accItem.op === item.op);

          let newItem = [{}];

          if (itemIndex >= 0) {
            newItem = {
              ...acc[itemIndex],
              otros: acc[itemIndex].otros.concat({
                color: item.color,
                cantidad: item.cantidad,
                fechaSalida: item.fechaSalida,
                fechaIngreso: item.fechaIngreso,
                estado: {
                  id: item.index,
                  op: item.op,
                  color: item.color,
                  cantidad: item.cantidad,
                  estado: item.estado[0],
                },
                edit: [
                  item.index,
                  item.op,
                  item.color,
                  item.estado && item.estado.length ? item.estado[0] : "",
                  !item.isEditable,
                ],
                index: {
                  id: item.index,
                  op: item.op,
                  color: item.color,
                  cantidad: item.cantidad,
                  estado: item.estado[0],
                  isEditable: !item.isEditable,
                },
                id: item.index,
              }),
            };

            acc.splice(itemIndex, 1, newItem);
          } else {
            newItem = {
              op: item.op,
              color: item.color,
              hc: item.hc,
              ft: item.ft,
              b: item.b,
              cx: item.cx,
              descripcion: item.descripcion,
              hojaConsumo: item.hojaConsumo,
              fechaPcp: item.fechaPcp,
              fechaDespacho: item.fechaDespacho,
              id: item.index,
              otros: [
                {
                  color: item.color,
                  cantidad: item.cantidad,
                  fechaSalida: item.fechaSalida,
                  fechaIngreso: item.fechaIngreso,
                  estado: {
                    id: item.index,
                    op: item.op,
                    color: item.color,
                    cantidad: item.cantidad,
                    estado: item.estado[0],
                  },
                  edit: [
                    item.index,
                    item.op,
                    item.color,
                    item.estado && item.estado.length ? item.estado[0] : "",
                    !item.isEditable,
                  ],
                  index: {
                    id: item.index,
                    op: item.op,
                    color: item.color,
                    cantidad: item.cantidad,
                    estado: item.estado[0],
                    isEditable: !item.isEditable,
                  },
                  id: item.index,
                },
              ],
            };
            acc.push(newItem);
          }
          return acc;
        }, []);
        this.setState({ data, loading: false });
      })
      .catch((error) => {
        console.error("Error getting documents", error);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  openModal(value) {
    this.setState({
      modalIsOpen: true,
      docId: value[0],
      ordenPedido: value[1],
      color: value[2],
      estado: value[3],
      isEditable: value[4],
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: false });
  }

  linkToEdit = (e) => {
    e.preventDefault();
    this.state.password === "pcpedit246."
      ? this.props.history.push({
          pathname: "/Telas/EditTelas",
          state: {
            docId: this.state.docId,
            ordenPedido: this.state.ordenPedido,
            color: this.state.color,
            estado: this.state.estado,
            isEditable: this.state.isEditable,
          },
        })
      : alert("Wrong password, try again");
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  borrar = (value) => {
    const id = value.id;
    const op = value.op;
    const col = value.color;
    const estadoRotulado = value.estado;
    const estado = "Nuevo";
    this.ref = firebase.firestore().collection("telas");

    this.ref.doc(id).update({
      isEditable: true,
      fechaIngreso: "",
      fechaSalida: "",
      estado: [estado, op, col],
    });

    const dataUpdated = this.state.data.map((item) => {
      if (item.op === op) {
        const otros = item.otros;
        const index = otros.findIndex((item) => item.color === col);
        otros.splice(index, 1, {
          ...otros[index],
          fechaIngreso: "",
          fechaSalida: "",
          estado: {
            estado: estado,
          },
          edit: [id, op, col, estado, true],

          index: {
            estado: estado,
          },
        });
      }
      return item;
    });

    this.setState({ data: dataUpdated });

    firebase
      .firestore()
      .collection("comercial")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("comercial")
            .doc(doc.id)
            .update({
              otros: doc.data().otros.map((v) => {
                if (v.color === col) {
                  delete v.telas;
                  return v;
                }
                return v;
              }),
            });
        });
      });

    firebase
      .firestore()
      .collection("ing")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const status = "PCP";
          const otros = doc.data().otros.map((v) => {
            if (v.color === col) {
              return {
                ...v,
                status: [status],
              };
            }
            return v;
          });
          firebase
            .firestore()
            .collection("ing")
            .doc(doc.id)
            .update({
              otros,
              isEditable: otros.every(
                (item) =>
                  (Array.isArray(item.status) && item.status[0] === "PCP") ||
                  item.status === "PCP"
              ),
            });

          firebase
            .firestore()
            .collection("ddp")
            .where("op", "==", op)
            .get()
            .then((qs) => {
              qs.forEach((doc) => {
                firebase
                  .firestore()
                  .collection("ddp")
                  .doc(doc.id)
                  .update({
                    isEditable: otros.every(
                      (item) =>
                        (Array.isArray(item.status) &&
                          item.status[0] === "PCP") ||
                        item.status === "PCP"
                    ),
                  });
              });
            });
        });
      });

    if (estadoRotulado === "Rotulado") {
      try {
        firebase
          .firestore()
          .collection("corte")
          .where("op", "==", op)
          .where("color", "==", col)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              firebase
                .firestore()
                .collection("corte")
                .doc(doc.id)
                .delete();
            });
          });
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  render() {
    const { data, loading } = this.state;
    const mainTelasColumns = [
      {
        headerStyle: {
          backgroundColor: "#F5BC66",
          color: "white",
          fontWeight: "bold",
        },
        columns: [
          {
            Header: "Orden de Producción(OP)",
            headerStyle: { fontWeight: "bold" },
            width: 200,
            accessor: "op",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "op",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "cx",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "F. PCP",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "fechaPcp",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  { threshold: matchSorter.rankings.CONTAINS, key: "fechaPcp" },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "F. Despacho",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "fechaDespacho",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "fechaDespacho",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "Ficha Técnica",
            headerStyle: { fontWeight: "bold", fontSize: "12px" },
            accessor: "ft",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [{ threshold: matchSorter.rankings.CONTAINS, key: "ft" }],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "b",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "18px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Hoja Consumo",
            headerStyle: { fontWeight: "bold" },
            width: 200,
            accessor: "hojaConsumo",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "hojaConsumo",
                  },
                ],
              }),
            filterAll: true,
          },
          {
            Header: "",
            accessor: "hc",
            width: 30,
            Cell: (row) => (
              <div>
                {row.value && Array.isArray(row.value) && row.value.length ? (
                  <span
                    style={{
                      width: "10px",
                      height: "100%",
                    }}
                  >
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-128.png"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </a>
                  </span>
                ) : null}
              </div>
            ),
          },
          {
            Header: "Descripcion",
            accessor: "descripcion",
            width: 200,
            headerStyle: { fontWeight: "bold" },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.CONTAINS,
                    key: "descripcion",
                  },
                ],
              }),
            filterAll: true,
          },
        ],
      },
    ];

    const subTelas = [
      {
        Header: "TELAS",
        headerStyle: { backgroundColor: "#03A9F4", color: "white" },
        columns: [
          {
            Header: "Color",
            headerStyle: { fontWeight: "bold" },
            accessor: "color",
          },
          {
            Header: "Cantidad",
            headerStyle: { fontWeight: "bold" },
            accessor: "cantidad",
          },
          {
            Header: "fecha De Inicio",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaIngreso",
          },
          {
            Header: "fecha De Entrega",
            headerStyle: { fontWeight: "bold" },
            accessor: "fechaSalida",
          },
          {
            Header: "Estado",
            headerStyle: { fontWeight: "bold" },
            width: 100,
            accessor: "estado",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: [
                  {
                    threshold: matchSorter.rankings.STARTS_WITH,
                    key: "estado",
                  },
                ],
              }),
            filterAll: true,
            Cell: ({ value: { op, color, estado, id } }) => (
              <div className="text-center">
                {estado === "Nuevo" ? (
                  <Link
                    to={{
                      pathname: "/Telas/RegistroDirecto",
                      state: {
                        ordenPedido: op,
                        colorState: color,
                        docId: id,
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#03A9F4",
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {estado}
                    </Button>
                  </Link>
                ) : estado === "Rotulado" ? (
                  <span
                    style={{
                      backgroundColor: "#008000",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      padding: "5px",
                      color: "white",
                    }}
                  >
                    {estado}
                  </span>
                ) : (
                  <Link
                    to={{
                      pathname: "/Telas/RegistroActDirecto",
                      state: {
                        ordenPedido: op,
                        colorState: color,
                        docId: id,
                      },
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          color === "Tejeduria"
                            ? "teal"
                            : color === "Tintoreria"
                            ? "purple"
                            : color === "Compras"
                            ? "blue"
                            : color === "Rotulado"
                            ? "#F5BC66"
                            : "",
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "10px",
                        color: "white",
                        margin: "0px",
                        padding: "5px",
                      }}
                    >
                      {estado}
                    </Button>
                  </Link>
                )}
              </div>
            ),
          },

          {
            Header: "Editar",
            accessor: "edit",
            width: 80,
            headerStyle: { fontWeight: "bold" },
            Cell: (row) => (
              <div className="text-center">
                <Button
                  style={{
                    backgroundColor: "orange",
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "10px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "0px",
                    padding: "5px",
                  }}
                  disabled={
                    row.value[3] === "Nuevo" || row.value[4] ? true : false
                  }
                  onClick={() => this.openModal(row.value)}
                >
                  Editar
                </Button>
              </div>
            ),
          },

          {
            Header: "Borrar",
            headerStyle: { fontWeight: "bold" },
            width: 80,
            accessor: "index",
            Cell: (row) => (
              <div className="text-center">
                {row.value ? (
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      margin: "0px",
                      padding: "5px",
                    }}
                    disabled={
                      row.value.estado === "Nuevo" || row.value.isEditable
                        ? true
                        : false
                    }
                    onClick={(e) =>
                      window.confirm("ESTAS SEGURO QUE QUIERES BORRAR?") &&
                      this.borrar(row.value)
                    }
                    type="button"
                  >
                    Borrar
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ),
          },
        ],
      },
    ];

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Seguimiento de Procesos Telas
                </CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={mainTelasColumns}
                  noDataText="Sin Registros"
                  loading={loading}
                  loadingText="POR FAVOR, ESPERE UN MOMENTO"
                  defaultPageSize={10}
                  style={{ textAlign: "center" }}
                  previousText="Atras"
                  nextText="Siguiente"
                  pageText="Pagina"
                  ofText="de"
                  rowsText="filas"
                  minRows={0}
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: "10px" }}>
                        <ReactTable
                          data={row.original.otros}
                          columns={subTelas}
                          noDataText="Ingrese un registro en Telas"
                          pageSize={row.original.otros.length}
                          showPagination={false}
                          style={{
                            textAlign: "center",
                            margin: "10px 0 35px 0",
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2>Enter a password</h2>
          <form>
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.onChange}
              style={{ border: "1px solid black" }}
            />

            <button
              onClick={(e) => this.linkToEdit(e)}
              autoFocus
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#58b368",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              onClick={(e) => this.closeModal(e)}
              style={{
                borderRadius: "5px",
                padding: "2px",
                margin: "10px",
                backgroundColor: "#ca3e47",
                border: "2px solid black",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default RegularTables;
