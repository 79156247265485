import Consulta from "views/TableList/ConsultaAlmacen.jsx";
import {
  EditAlmacenRecord,
  DirectNewAlmacenRecord,
} from "views/UserPage/almacen";

var dashRoutes = [
  {
    path: "/Almacen/Procesos",
    name: "Procesos",
    icon: "nc-icon nc-briefcase-24",
    component: Consulta,
  },
  {
    path: "/Almacen/RegistroDirecto",
    component: DirectNewAlmacenRecord,
    invisible: true,
  },
  {
    path: "/Almacen/EditAlmacen",
    name: "EditAlmacen",
    component: EditAlmacenRecord,
    invisible: true,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/Almacen/Procesos",
    name: "Procesos",
  },
];

export default dashRoutes;
