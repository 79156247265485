import firebase from "firebase";

var config = {
  apiKey: "AIzaSyBZk6TZRgKQeMsQHnWc0Em3aXV5atfp7k0",
  authDomain: "textiles-cross.firebaseapp.com",
  databaseURL: "https://textiles-cross.firebaseio.com",
  projectId: "textiles-cross",
  storageBucket: "textiles-cross.appspot.com",
  messagingSenderId: "618524731838",
};
const settings = { timestampsInSnapshots: true };

firebase
  .initializeApp(config)
  .firestore()
  .settings(settings);

export default firebase;
