import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Label,
  Alert,
  FormGroup,
} from "reactstrap";
import firebase from "Firebase.js";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/CustomButton/CustomButton.jsx";

registerLocale("es", es);

class EditCosturaRecord extends React.Component {
  constructor() {
    super();

    this.ref = firebase.firestore().collection("pcp");

    this.state = {
      isOpen2: false,
      color: "",
      fechaSalida: new Date(),
      cantSalida: "",
      label: null,
      value: null,
      op: null,
      numGuiaSer: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { opState } = this.props.location.state;
    const { colorState } = this.props.location.state;

    this.ref
      .orderBy("Registro", "desc")
      .get()
      .then((querySnapshot) => {
        let label,
          value,
          op,
          color = null;
        querySnapshot.forEach(function(doc) {
          let costura = [];
          if (doc.data().costura) costura = doc.data().costura;

          for (let i = 0; i < costura.length; i++) {
            if (costura[i].op === opState && costura[i].color === colorState) {
              label = costura[i].op + " " + costura[i].color;
              value = doc.id;
              color = costura[i].color;
              op = costura[i].op;
            }
          }
        });

        this.setState({ label, value, color, op });
      });
  }

  handleClickShowAlert2() {
    this.setState({
      isOpen2: true,
    });

    setTimeout(() => {
      this.setState({
        isOpen2: false,
      });
    }, 1000);
  }

  handleChangeSalida = (date) => {
    this.setState({
      fechaSalida: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit2 = (e) => {
    e.preventDefault();

    let { fechaSalida, cantSalida, value, op, color, numGuiaSer } = this.state;

    fechaSalida = moment(fechaSalida).format("DD/MM/YYYY");

    this.ref
      .doc(value)
      .get()
      .then((res) => {
        let costura = res.data().costura;
        var nuevo = [];
        nuevo = costura.map((value) => {
          if (value.op === op && value.color === color) {
            value.cantSalida = cantSalida;
            value.fechaSalida = fechaSalida;
            value.numGuiaSer = numGuiaSer;
            value.seg = "acabado";
          }
          return value;
        });

        try {
          this.ref
            .doc(value)
            .update({
              costura: nuevo,
            })
            .then((docRef) => {
              this.setState({
                fechaSalida: new Date(),
                selectedOption2: "",
                numGuiaSer: "",
                cantSalida: "",
              });
              this.props.history.push("/Ingenieria/Procesos");
              this.handleClickShowAlert2();
            });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });
  };

  render() {
    const { numGuiaSer, cantSalida } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md={6} xs={6}>
            <Card className="card-user">
              <CardHeader style={{ margin: "0 auto" }}>
                <CardTitle>REGISTRO LLEGADA COSTURA</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.onSubmit2}>
                  <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    FECHA DE ENTREGA:
                  </Label>
                  <div style={{ marginBottom: "20px", marginLeft: "15px" }}>
                    <DatePicker
                      selected={this.state.fechaSalida}
                      onChange={this.handleChangeSalida}
                      dateFormat="d/MM/yyyy"
                      locale="es"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      ORDEN DE PRODUCCION
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.op}
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ marginLeft: "15px", fontWeight: "bold" }}>
                      COLOR
                    </Label>
                    <Label
                      className={"col-md-4"}
                      style={{
                        fontSize: "1.15em",
                        border: "#B4A7C4 solid 1px",
                        color: "black",
                        borderRadius: "3px",
                        padding: "3px",
                        marginLeft: "15px",
                        display: "block",
                      }}
                    >
                      {this.state.color}
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="numGuiaSer">N° GUIA SERVICIO</Label>
                    <Input
                      type="text"
                      name="numGuiaSer"
                      id="numGuiaSer"
                      className="col-md-4 pr-1"
                      value={numGuiaSer}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </FormGroup>

                  <FormGroup style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    <Label for="cantSalida">CANTIDAD</Label>
                    <Input
                      type="text"
                      name="cantSalida"
                      id="cantSalida"
                      className="col-md-4 pr-1"
                      value={cantSalida}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                  </FormGroup>

                  <FormGroup className="col-md-6 ml-auto mr-auto">
                    <Alert color="success" isOpen={this.state.isOpen2}>
                      INGRESADO
                    </Alert>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        color="primary"
                        round
                        style={{ marginTop: "177px" }}
                        type="submit"
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditCosturaRecord;
