import React from "react";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import { Upload, Icon, notification, message, Button, Input as In } from "antd";
import firebase from "../../../Firebase.js";
import "antd/dist/antd.css";

class DirectNewDdpRecord extends React.Component {
  constructor() {
    super();
    this.ref = firebase.firestore().collection("ddp");
    this.storageRef = firebase.storage();

    this.state = {
      fechaIngreso: new Date(),
      fechaIngresoV1: new Date(),
      isOpen: false,
      prof: "",
      selectedOption: null,
      ing: [],
      imgFT: null,
      imgHC: null,
      value: null,
      fichaTecnica: "",
      hojaConsumo: "",
      doc: "",
      ordenPedido: "",
      proforma: "",
      comercialValue: "",
      ingValue: "",
      telasValue: "",
      corteValue: "",
      costuraValue: "",
      acabadosValue: "",
      update: false,
      almacenValue: "",
      isActive: false,
      selectedFile: null,
      selectedFileList: [],
      pdfUrl: "",
      filelistFT: null,
      filelistHC: null,
      fileListFT: null,
      fileListHC: null,
      uploading: false,
      fichTec: [],
      hojaCon: [],
    };
    this.changeHc = this.changeHc.bind(this);
    this.changeFt = this.changeFt.bind(this);
  }

  componentDidMount() {
    const { doc, op } = this.props.location.state;
    const update = this.props.location.state.update;

    firebase
      .firestore()
      .collection("ddp")
      .orderBy("order", "asc")
      .get()
      .then((qs) => {
        const ftecnica = [];
        const hconsumo = [];
        qs.forEach((doc) => {
          if (doc.data().fichaTecnica) {
            ftecnica.push(doc.data().fichaTecnica);
          }
          if (doc.data().hojaConsumo) {
            hconsumo.push(doc.data().hojaConsumo);
          }
        });
        this.setState({ fichTec: ftecnica, hojaCon: hconsumo });
      });

    this.ref
      .doc(String(doc))
      .get()
      .then((idx) => {
        const dataDDP = idx.data();
        const fichaT = dataDDP.fichaTecnica;
        const hojaC = dataDDP.hojaConsumo;
        const urlFichaT = dataDDP.b[0];
        const urlHojaC = dataDDP.hc[0];

        this.setState({
          fichaTecnica: fichaT,
          hojaConsumo: hojaC,
          urlFichaT,
          urlHojaC,
          fileListFT: urlFichaT
            ? [
                {
                  uid: doc,
                  name: fichaT,
                  status: "done",
                  url: urlFichaT,
                },
              ]
            : null,
          fileListHC: urlHojaC
            ? [
                {
                  uid: doc,
                  name: hojaC,
                  status: "done",
                  url: urlHojaC,
                },
              ]
            : null,
        });
      });

    this.setState({
      doc: doc,
      ordenPedido: op,
      update: update,
    });

    firebase
      .firestore()
      .collection("ing")
      .where("op", "==", op)
      .get()
      .then((querySnapshot) => {
        let ingValue;
        querySnapshot.forEach((doc) => {
          ingValue = doc.data().index;
        });
        this.setState({ ingValue });
      });
  }

  handleChange = (date) => {
    this.setState({
      fechaIngreso: date,
    });
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };
  np;

  changeFt = (e) => {
    if (e.target.files[0]) {
      const imgFT = e.target.files[0];
      this.setState(() => ({ imgFT, fichaTecnica: imgFT.name.slice(0, -4) }));
    }
  };

  changeHc = (e) => {
    if (e.target.files[0]) {
      const imgHC = e.target.files[0];
      this.setState(() => ({ imgHC, hojaConsumo: imgHC.name.slice(0, -4) }));
    }
  };

  handleChangeFT = (info) => {
    let fileListFT = [...info.fileList];

    fileListFT = fileListFT.slice(-1);

    fileListFT = fileListFT.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelistFT: info.fileList, fileListFT });

    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUploadFT = async ({ onError, onSuccess, file }) => {
    this.setState({ uploading: true });
    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`fichaTecnica/${file.name}`);

    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("fichaTecnica")
        .child(file.name)
        .getDownloadURL();
      this.setState(() => ({
        file,
        fichaTecnica: file.name.slice(0, -4),
        uploading: false,
      }));
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  handleChangeHC = (info) => {
    let fileListHC = [...info.fileList];

    fileListHC = fileListHC.slice(-1);

    fileListHC = fileListHC.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ filelistHC: info.fileList, fileListHC });

    if (info.file.status === "uploding") {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === "done") {
      this.setState({ loading: false });
    }
  };

  customUploadHC = async ({ onError, onSuccess, file }) => {
    this.setState({ uploading: true });
    const metadata = {
      contentType: ".pdf",
    };
    const storager = await this.storageRef.ref();
    const pdfFile = storager.child(`hojaConsumo/${file.name}`);

    try {
      const pdf = await pdfFile.put(file, metadata);
      const url = await this.storageRef
        .ref("hojaConsumo")
        .child(file.name)
        .getDownloadURL();
      this.setState(() => ({
        file,
        hojaConsumo: file.name.slice(0, -4),
        uploading: false,
      }));
      onSuccess(null, { ...pdf, url });
    } catch (e) {
      onError(e.message);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      urlFichaT,
      urlHojaC,
      ingValue,
      fechaIngreso: fechaIngresoInput,
      fichaTecnica: fitec,
      hojaConsumo: hojcon,
      doc,
      ordenPedido: op,
    } = this.state;

    this.setState({
      isActive: true,
    });
    const fechaIngreso = moment(fechaIngresoInput).format("DD/MM/YYYY");

    const fturl =
      this.state.filelistFT && this.state.filelistFT.length
        ? this.state.filelistFT[0].xhr.url
        : urlFichaT;

    const hcurl =
      this.state.filelistHC && this.state.filelistHC.length
        ? this.state.filelistHC[0].xhr.url
        : urlHojaC;

    message.loading("Registrando...", 60);
    try {
      try {
        firebase
          .firestore()
          .collection("comercial")
          .where("op", "==", op)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = {
                cFechaIngreso: fechaIngreso,
                fichaTecnica: fitec,
                f: [fturl],
              };
              try {
                firebase
                  .firestore()
                  .collection("comercial")
                  .doc(doc.id)
                  .update(data);
              } catch (e) {
                console.log(e.message);
              }
            });
          });
      } catch (error) {
        console.log(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("ing")
          .doc(String(ingValue))
          .get()
          .then((res) => {
            let ing = res.data();
            if (fitec && hojcon) {
              ing["fichaTecnica"] = fitec || "";
              ing["hojaConsumo"] = hojcon || "";
              ing.b = [fturl || ""];
              ing.hc = [hcurl || ""];
            } else if (fitec) {
              ing["fichaTecnica"] = fitec || "";
              ing.b = [fturl || ""];
            } else if (hojcon) {
              ing["hojaConsumo"] = hojcon || "";
              ing.hc = [hcurl || ""];
            }
            try {
              firebase
                .firestore()
                .collection("ing")
                .doc(String(ingValue))
                .set(ing, { merge: true });
            } catch (error) {
              console.log("Error adding document: ", error);
              console.log(error.message);
              console.log(error);
            }
          });
      } catch (error) {
        console.log("Sucedio un error. Ingrese nuevamente el registro", error);
        console.log(error.message);
        console.log(error);
      }

      try {
        firebase
          .firestore()
          .collection("telas")
          .where("op", "==", op)
          .get()
          .then((querySnapshot) => {
            let telasDocId = null;
            querySnapshot.forEach((doc) => {
              telasDocId = doc.id;
              const telaData = {
                ...doc.data(),
                ft: fitec || "",
                b: fturl ? [fturl] : [],
                hojaConsumo: hojcon || "",
                hc: hcurl ? [hcurl] : [],
              };

              try {
                firebase
                  .firestore()
                  .collection("telas")
                  .doc(telasDocId)
                  .update(telaData);
              } catch (error) {
                console.log(error.message);
              }
            });
          });
      } catch (error) {
        console.log(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("corte")
          .where("op", "==", op)
          .get()
          .then((querySnapshot) => {
            let corteDocId = null;
            querySnapshot.forEach((doc) => {
              corteDocId = doc.id;
              const corteData = {
                ft: fitec || "",
                b: [fturl || ""],
                hojaConsumo: hojcon || "",
                hc: [hcurl || ""],
              };
              firebase
                .firestore()
                .collection("corte")
                .doc(corteDocId)
                .update(corteData);
            });
          });
      } catch (error) {
        console.log(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("costura")
          .where("op", "==", op)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const costuraData = {
                ft: fitec || "",
                b: [fturl || ""],
              };
              firebase
                .firestore()
                .collection("costura")
                .doc(doc.id)
                .update(costuraData);
            });
          });
      } catch (error) {
        console.error(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("acabados")
          .where("op", "==", op)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const acabadosData = {
                ft: fitec || "",
                b: [fturl || ""],
              };
              firebase
                .firestore()
                .collection("acabados")
                .doc(doc.id)
                .update(acabadosData);
            });
          });
      } catch (error) {
        console.error(error.message);
      }

      try {
        firebase
          .firestore()
          .collection("almacen")
          .where("op", "==", op)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const almacenData = {
                ft: fitec || "",
                b: [fturl || ""],
              };

              firebase
                .firestore()
                .collection("almacen")
                .doc(doc.id)
                .update(almacenData);
            });
          });
      } catch (error) {
        console.error(error.message);
      }

      const data = {
        fechaIngresoDdp: fechaIngreso || "",
        fichaTecnica: fitec || "",
        b: [fturl || ""],
        hojaConsumo: hojcon || "",
        hc: [hcurl || ""],
        estado: [fturl && hcurl ? "Listo" : "En curso", op],
      };

      try {
        this.ref
          .doc(doc)
          .update(data)
          .then((_) => {
            message.destroy();
            const args = {
              message: "Registro agregado existosamente.",
              top: 80,
            };
            notification.success(args);
            this.setState({
              isActive: false,
            });
            this.props.history.push("/Ddp/Procesos");
          });
      } catch (error) {
        console.log(error.message);
      }
    } catch (error) {
      console.error(error.message);
    }
    //}
  };

  render() {
    const {
      fichaTecnica,
      hojaConsumo,
      ordenPedido,
      loading,
      pdfUrl,
    } = this.state;

    const isInvalid = !(hojaConsumo || fichaTecnica);

    const uploadButton = (
      <div>
        <Button
          style={{ display: "block", textAlign: "left", marginLeft: "1px" }}
        >
          <Icon type={loading ? "loading" : "plus"} />
          Seleccionar Archivo
        </Button>
      </div>
    );

    return (
      <div className="content">
        <Row>
          <Col md={12} xs={12}>
            <Card className="card-user">
              <CardHeader>
                <CardTitle style={{ textAlign: "center" }}>
                  REGISTRO DDP
                </CardTitle>
              </CardHeader>
              <CardBody className="ml-3">
                <form>
                  <FormGroup>
                    <Label className="font-weight-bold">
                      ORDEN DE PRODUCCIÓN
                    </Label>
                    <In
                      className="col-md-3 d-block"
                      value={ordenPedido}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="font-weight-bold">FICHA TECNICA</Label>
                    <In
                      name="ft"
                      className="col-md-3 d-block"
                      value={fichaTecnica}
                      onChange={this.onChange}
                      autoComplete="off"
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="font-weight-bold">HOJA DE CONSUMO</Label>
                    <In
                      name="hc"
                      className="col-md-3 d-block"
                      value={hojaConsumo}
                      onChange={this.onChange}
                      autoComplete="off"
                      disabled
                    />
                  </FormGroup>

                  <Row>
                    <Label
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                      className="font-weight-bold"
                    >
                      FICHA TECNICA
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() =>
                      this.setState({ filelistFT: null, fichaTecnica: "" })
                    }
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangeFT}
                    customRequest={this.customUploadFT}
                    fileList={this.state.fileListFT}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>

                  <Row>
                    <Label
                      style={{ marginLeft: "15px", fontWeight: "bold" }}
                      className="font-weight-bold mt-4"
                    >
                      HOJA DE CONSUMO
                    </Label>
                  </Row>
                  <Upload
                    onRemove={() =>
                      this.setState({ filelistHC: null, hojaConsumo: "" })
                    }
                    accept={".pdf"}
                    multiple={false}
                    onChange={this.handleChangeHC}
                    customRequest={this.customUploadHC}
                    fileList={this.state.fileListHC}
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {pdfUrl ? (
                      <Button icon={<Icon type="upload" />}></Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={
                          this.state.isActive ||
                          this.state.uploading ||
                          isInvalid
                        }
                      >
                        Registrar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DirectNewDdpRecord;
