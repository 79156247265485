import firebase from "../../Firebase";
import { FirebaseCollections } from "enums";

const firestoreApi = firebase.firestore();

const getNewPcpId = async () => {
  const [lastPcpRecord] = await firestoreApi
    .collection(FirebaseCollections.Pcp)
    .orderBy("order", "desc")
    .limit(1)
    .get()
    .then((querySnapshot) => {
      let data = [];

      querySnapshot.forEach((doc) => {
        data = [
          ...data,
          {
            id: doc.id,
            order: doc.data().order,
          },
        ];
      });

      return data;
    });

  return Object.keys(lastPcpRecord).length ? lastPcpRecord.order + 1 : 1;
};

const createPcpRecord = async (pcpData) => {
  const docId = await getNewPcpId();

  try {
    const data = await firestoreApi
      .collection(FirebaseCollections.Pcp)
      .doc(String(docId))
      .set({ ...pcpData, index: String(docId), order: Number(docId) });

    return data;
  } catch (e) {
    console.error(e.message);
  }
};

const updatePcpRecord = async (docId, pcp) => {
  try {
    const pcpRecord = await firestoreApi
      .collection(FirebaseCollections.Pcp)
      .doc(String(docId))
      .update(pcp);
    return pcpRecord;
  } catch (error) {
    console.error(error.message);
  }
};

const updatePcpRecordInTela = async (op, color, estado, isEditable = true) => {
  try {
    const pcpList = await firestoreApi
      .collection(FirebaseCollections.Pcp)
      .where("op", "==", op)
      .get();

    pcpList.forEach(async (doc) => {
      const colorList = doc.data().otros.map((colorItem) =>
        colorItem.color === color
          ? {
              ...colorItem,
              status: [estado],
            }
          : colorItem
      );

      try {
        await updatePcpRecord(doc.id, { otros: colorList, isEditable });
      } catch (e) {
        console.log("Error actualizando pcp", e.message);
      }
    });
  } catch (error) {
    console.error(error.message);
  }
};

export { getNewPcpId, createPcpRecord, updatePcpRecordInTela, updatePcpRecord };
